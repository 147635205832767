import api from '@/config/api';
import router from '@/router.js';
import store from '@/store/store';
import axios from '@/axios';
import auth0 from '@/auth0';

export default {
  SET_AUTH ( { commit }, payload) {
    commit('SET_AUTH', payload);
  },

  async set({ commit, dispatch, getters }, payload) {
    let result = new Object();
    if (payload && !getters['tenantSelected']) result = await dispatch('tenants').catch(() => ({ error: 'access_denied' }));
    commit('SET_AUTH', payload);
    return result;
  },

  async tenantResolve({ state, dispatch, rootGetters }, { id } = {}) {
    const tenant = rootGetters['accounts/tenant'];
    /* eslint no-unused-vars: 0 */
    function alreadySelectedTenant () {
      const { firstName, lastName, email } = tenant;
      const displayName = firstName && lastName
        ? `${firstName} ${lastName}`
        : firstName || email;

      const { app } = router;
      app.$vs.notify({
        text: `You're already loggedin with tenant access of ${displayName}.`,
        color: 'success',
        time: 5000
      });

      return tenant;
    }
    
    if ((tenant || {}).id == id || (state.tenant || {}).id == id) return alreadySelectedTenant();
    return await dispatch('tenant', { id });
  },

  async tenant ({ commit, state, dispatch }, { id } = {}) {
    const _id = Number(localStorage.getItem('tid') || id);
    const url = `${api.baseUrl}/session/tenant/${_id}`;
    return await axios({
      url,
      method: 'post',
    }).then(async () => {
      const tenant = await dispatch('accounts/tenant', null, { root: true });
      commit('SET_TENANT', tenant)
      return tenant;
    }).catch(() => {
      localStorage.setItem('tid', id);
      return null;
    });
  },

  async tenants ({ commit, dispatch }) {
    const url = `${api.baseUrl}/tenants`;
    return axios({
      url,
      method: 'get',
    }).then(async ({ data }) => {
      const storedTenantId = Number(localStorage.getItem('tid'));
      if (storedTenantId) {
        const tenant = data.find(item => item.id === storedTenantId);
        if (!tenant) return Promise.reject(null);
      }
      commit('SET_TENANTS', data);
      if (data.length === 1) await dispatch('tenant', { id: data[0].id });
      return data;
    });
  },

  async resolve(context, route) {
    let resolvedRoute;
    const isLoggedin = context.getters['isLoggedin'];
    const isVerifiedEmail = context.getters['isVerifiedEmail'];

    const to = route || router.currentRoute;

    if (!isLoggedin) {
      if (to.matched.some(record => record.meta.auth)) {
        const result = await auth0.isAuthenticated();
        if (!result) {
          resolvedRoute = {
            path: '/login',
            query: { redirect: to.name !== 'signup-verify' ? to.fullPath : '/' }
          };
        }
      }
    } else {
      await store.dispatch('accounts/resolve', null, { root: true });
      switch (to.name) {
        case 'login':
        case 'callback':
        case 'signup':
          resolvedRoute = to.query.redirect || '/';
          break;
        default:
          resolvedRoute = undefined;
          break;
      }
    }

    const { query } = to;
    if (query.tid) {
      const tenant = await context.dispatch('tenantResolve', { id: query.tid });
      if (!tenant) resolvedRoute = '/logout';
    }
    
    if (!route) {
      const verifyPath = '/signup/verify';
      if (isLoggedin && !isVerifiedEmail) resolvedRoute = verifyPath;
      if (resolvedRoute && (to.path !== resolvedRoute)) router.push(resolvedRoute).catch(() => {});
    }
    
    return resolvedRoute;
  },
};
