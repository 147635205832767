const getters = {
  hasScreenshots(state) {
    return (id) => {
      const item = state.item;
      try {
        return item.id === parseInt(id) && item.screenshots.length > 0
      } catch (error) {
        return false
      }
    }
  },
  hasMonitor(state) {
    try {
      return Boolean(state.item.monitor)
    } catch (error) {
      return false
    }
  }
}

export default getters
