/* eslint no-unused-vars: 0 */
import api from '@/config/api';
import axios from '@/axios';
import { encode, urlPath } from '@/plugins/helper';
import Paging from '@/entity/Paging';

var getScreenCast = function(output, viewport) {
    if (!viewport) {
      throw Error("Viewport is missing");
    }

    output.screencasts = output.screencasts || [];
    var screencast = null;
    for (var ss of output.screencasts) {
      if (!ss.viewPort) {
        console.error("Screecast has no viewport ", ss );
      }
      if (ss.viewPort.width == viewport.width && ss.viewPort.height == viewport.height) {
        screencast=ss;
        break;
      }
    }

    if (!screencast) {
      screencast = { log: [], steps: [] };
      screencast.isImage = true;
      screencast.viewPort = viewport;     
      screencast.passed = undefined;
      screencast.screencastPath = ''; // important to set this so vuejs reactivity is setup correctly   
      output.screencasts.push(screencast);
    }    

    return screencast;
};

const actions = {
  async all ({ commit, dispatch }, { params } = {}) {
    // return await Promise.all([
    //   dispatch('list'),
    //   dispatch('hosts'),
    // ])
    const list = await dispatch('list')
    const hosts = await dispatch('hosts')

    return [list, hosts];
  },

  async list ({ commit }, { params = {}, paging = {}, dontCommit = false, config = {} } = {}) {
    const query = {
      ...params,
      ...(Object.keys(paging).length > 0 ? Paging.query(paging) : {})
    }
    const url = `${api.baseUrl}/captures?${encode(query)}`;
    return await axios.get(url, config)
      .then(({ data }) => {
        if (!dontCommit) commit('SET_LIST', data);
        return data;
      });
  },

  async hosts ({ commit }, { params = {}, paging = {} } = {}) {
    const query = {
      ...params,
      ...(Object.keys(paging).length > 0 ? Paging.query(paging) : {})
    }
    const url = `${api.baseUrl}/hosts?${encode(query)}`;
    return await axios.get(url)
      .then(({ data }) => {
        commit('SET_HOSTS', data);
        return data;
      });
  },

  async tags ({ commit }, { params = {}, paging = {} } = {}) {
    const query = {
      ...params,
      ...(Object.keys(paging).length > 0 ? Paging.query(paging) : {})
    }
    const url = `${api.baseUrl}/tags/capture?${encode(query)}`;
    return await axios.get(url)
      .then(({ data }) => {
        commit('SET_TAGS', data);
        return data;
      });
  },

  async item ({ commit }, { id, comparing = false } = {}) {
    commit('SET_LOADING', true);
    const url = `${api.baseUrl}/captures/${id}`;
    return await axios.get(url, {
      disablePreload: true
    })
      .then(({ data }) => {
        commit(comparing ? 'SET_COMPARING' : 'SET_ITEM', data);
        return data;
      });
  },

  async create ({ commit, dispatch }, data = {}) {
    const url = `${api.baseUrl}/captures`;
    return await axios.post(url, {
      ...data,
      dateCaptured: undefined,
      detectedChangeFromReferencePercent: undefined,
      detectedChangePercent: undefined,
      documentDownloadTimeMs: undefined,
      entity: undefined,
      harPath: undefined,
      html: undefined,
      htmlPath: undefined,
      key: undefined,
      metaDescription: undefined,
      passed: undefined,
      screencasts: undefined,
      screenshots: undefined,
      id: undefined,
      url: typeof data.url === 'string' ? data.url : urlPath(data.url)
    })
      .then(async ({ data }) => {
        commit('SET_ITEM', data);
        return data;
      })
  },

  async capture ({ commit, dispatch }, { id = null } = {}) {
    if (!id) return null
    const url = `${api.baseUrl}/captures/${id}/capture`;
    return await axios.post(url)
      .then(async ({ data }) => {
        commit('SET_ITEM', data);
        return data;
      })
  },

  async delete ({ dispatch, state }, { id = null, fromItemView = false } = {}) {
    if (!id) return null;
    const url = `${api.baseUrl}/captures/${id}`;
    return await axios.delete(url)
      .then(async ({ data }) => {
        if (data && data.success) {
          if (state.requestParams && !fromItemView) await dispatch('list', { params: state.requestParams });
        }
        return data;
      });
  },

  requestParams ({ commit }, params) {
    commit('REQUEST_PARAMS', params);
  },

  async changes ({ commit }, { id, params = {} } = {}) {
    const url = `${api.baseUrl}/urls/${id}/changes?${encode(params)}`;
    return await axios.get(url)
      .then(({ data }) => {
        return data;
      });
  },



  async script ({ commit, dispatch }, form) {
    if (!form) return null;

      var data = {
          
        };

       // commit('SET_ITEM', data);
        commit('START_TEST');
        dispatch('startTesting', data);

        /*
    const url = `${api.baseUrl}/captures`;
    return await axios.post(url, {
      ...form,
      id: undefined,
      type: undefined,
      tenant: undefined,
      status: undefined,
      entity: undefined,
      schedule: undefined,
      timeZone: undefined,
      url: typeof form.url === 'string' ? form.url : urlPath(form.url)
    })
      .then(({ data }) => {
        commit('SET_ITEM', data);
        commit('START_TEST');
        dispatch('startTesting', data);
        return data;
      });*/
  },

  async stopTesting ({ commit }) {
    commit('STOP_TEST');
  },

  async startTesting ({ commit, dispatch, state }, form) {
    if (!form) return null;
    if (!state.testRunning) return null;

    if (((state.item || {}).screencasts || []).length > 0) {
      commit('STOP_TEST');
      return state.item
    }

    /*
    const url = `${api.baseUrl}/captures/${form.id}`;
    return await axios.get(url, {
      disablePreload: true
    })
      .then(async ({ data }) => {
        commit('SET_ITEM', data);
        if (state.testRunning) return new Promise(resolve => {
          setTimeout(async () => {
            const result = await dispatch('startTesting', data);
            resolve(result);
          }, 2000);
        })
        return data;
      });*/
  },

  async summary ({ commit }, { from, to } = {}) {
    const url = `${api.baseUrl}/captures/summary?${encode({ from, to })}`;
    return await axios.get(url)
      .then(({ data }) => {
        commit('SET_CAPTURES_SUMMARY', data);
        return data;
      });
  },

  async favorite ({ commit, dispatch }, { id = null } = {}) {
    if (!id) return null
    const url = `${api.baseUrl}/captures/${id}/favorite`;
    return await axios.post(url)
      .then(async ({ data }) => {
        commit('SET_ITEM', data);
        return data;
      })
  },




  SOCKET_ACTION_datasetItem ( { commit, state } , data) {
    
    console.log("datasetItem", data);

    //var screencast = getScreenCast(output, data.viewport);
    //screencast.steps.push (data.step);
    //commit('SET_ITEM', output);
    return data;
  },



  SOCKET_ACTION_executionStepStarted ( { commit, state } , data) {
    console.log("Test is execution_step_started");
    var output = JSON.parse(JSON.stringify(state.item)) || {};
    var screencast = getScreenCast(output, data.viewport);
    screencast.steps.push (data.step);
    commit('SET_ITEM', output);
    return output;
  },

  SOCKET_ACTION_executionStepFinished ( { commit, state }, data) {
    console.log("Test is execution_step_started");
    var output = JSON.parse(JSON.stringify(state.item)) || {};
    var screencast = getScreenCast(output, data.viewport);
    if (data.step.index < screencast.steps.length) {
      screencast.steps[data.step.index] = data.step;
    }
    else {
      screencast.steps.push (data.step);
    }
    commit('SET_ITEM', output);
    return output;
  },

  SOCKET_ACTION_screencastFinished  ( { commit, state }, data) {
    console.log('enter SOCKET_ACTION_screencastFinished');
    
    var output = JSON.parse(JSON.stringify(state.item)) || {};

    var screencast = getScreenCast(output, data.viewport);
    screencast.passed = data.screencast.passed;
    commit('SET_ITEM', output);
  },

  SOCKET_ACTION_executionLog  ( { commit, state }, data) {
    console.log("Test is SOCKET_ACTION_execution_log");
    var output = JSON.parse(JSON.stringify(state.item)) || {};
    var screencast = getScreenCast(output, data.viewport);
    screencast.log.push(data.log);
    commit('SET_ITEM', output);
    return output;
  },

  SOCKET_ACTION_screenshot  ( { commit, state }, data) {
    console.log("Test is SOCKET_MUTATION_screenshot", window.WebsocketBaseUrl); //JSON.stringify(data));
    var output = JSON.parse(JSON.stringify(state.item)) || {};
    output.screenshots =  output.screenshots || [];
    data.screenshot.viewPort = data.viewport;

    data.screenshot.image.thumbnail = window.WebsocketBaseUrl + data.screenshot.image.thumbnail;
    data.screenshot.image.large = window.WebsocketBaseUrl + data.screenshot.image.large;
    data.screenshot.image.full = window.WebsocketBaseUrl + data.screenshot.image.full;
    

    output.screenshots.push(data.screenshot);
    commit('SET_ITEM', output);
    return output;
  },




  SOCKET_ACTION_screencastFrame (  { commit, state }, data) {
      var b = new Blob([data.image]);
      var urlObject = URL.createObjectURL(b);
      var output = JSON.parse(JSON.stringify(state.item)) || {};
      var screencast = getScreenCast(output, data.viewport);
      screencast.screencastPath = urlObject;
      if (!screencast.frames) screencast.frames = [];
      screencast.frames.push ( urlObject );
      commit('SET_ITEM', output);
      return output;
      
  },

  SOCKET_ACTION_finished  (  { commit, state }, data) {
    commit('TEST_FINISHED', data);
  },


}

export default actions
