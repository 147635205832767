import uniq from 'underscore/modules/uniq'

const debounce = function (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
};

function encode (queryObj, nesting = "") {
  if (!queryObj) return '';
  const pairs = Object.entries(queryObj).map(([key, val]) => {
    // Handle the nested, recursive case, where the value to encode is an object itself
    if (typeof val === "object") {
      if (Object.prototype.hasOwnProperty.call(val, 'length')) {
        return [nesting + key, val.join(',')].map(encodeURIComponent).join("=");
      }
      return encode(val, nesting + `${key}.`);
    } else {
      // Handle base case, where the value to encode is simply a string.
      return [nesting + key, val].map(encodeURIComponent).join("=");
    }
  });
  return pairs.join("&");
}

function decode (url) {
  if (!url) url = location.href;
  var question = url.indexOf("?");
  var hash = url.indexOf("#");
  if (hash == -1 && question == -1) return {};
  if (hash == -1) hash = url.length;
  var query = question == -1 || hash == question + 1 ? url.substring(hash) :
    url.substring(question + 1, hash);
  var result = {};
  query.split("&").forEach(function (part) {
    if (!part) return;
    part = part.split("+").join(" "); // replace every + with space, regexp-free version
    var eq = part.indexOf("=");
    var key = eq > -1 ? part.substr(0, eq) : part;
    var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
    var from = key.indexOf("[");
    if (from == -1) result[decodeURIComponent(key)] = val;
    else {
      var to = key.indexOf("]", from);
      var index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
}

function urlPath (obj) {
  try {
    const url = obj.host;
    if (typeof url === 'object') return `${obj.scheme}://${url.host ? `${url.host}.` : ''}${url.domain + obj.path}`;
    return `${obj.host ? `${obj.host}.` : ''}${obj.domain}`;
  } catch (error) {
    return 'Invalid { url }'
  }
}

function protoOf (obj) {
  const s = Object.prototype.toString.call(obj)
  return s.substring(s.indexOf(' ') + 1, s.length - 1).toLowerCase()
}

export {
  debounce,
  encode,
  decode,
  urlPath,
  protoOf,
  uniq
}
