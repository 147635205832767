// "id": Number,
// "text": String,
// "data": Object,
// "children": Array,
// "state": Object
import Monitoring from '@/entity/Monitoring';
export default class TestSuite {
  constructor(o = {}) {
    
    console.log('enter TestSuite constructor', o);

    const me = o || {};
    this.id = me.id || undefined;

    const data = me.test || me.data;
    const name = me.name || me.text || (data || {}).name || (data || {}).text || 'Untitled Test';
    const items = me.items || me.children;

    this.entity = me.entity || undefined;
    this.test = data && Boolean(data.id) ? new Monitoring(data) : undefined;
    this.name = this.test ? undefined : name;
    this.syskey = me ? me.key : '';
    this.items = items && items.length > 0 ? items.map(item => new TestSuite(item)) : undefined;
    this.summary = me.summary || undefined;
  }
}