import store from '@/store/store';

import Template from '@/entity/Template';
import Tenant from '@/entity/Tenant';
export default class Monitoring {
  constructor (o = {}) {
    const Monitoring = (o || {});
    this.status = Monitoring.status || undefined;
    this.id = Monitoring.id || undefined;
    this.name = Monitoring.name || undefined;
    this.type = Monitoring.type || 'single';
    this.script = Monitoring.script || undefined;
    this.captureHar = Monitoring.captureHar || false;
    this.captureHtml = Monitoring.captureHtml || false;
    this.captureWarc = Monitoring.captureWarc || false;
    this.captureScreencast = Monitoring.captureScreencast || false;
    this.schedule = Monitoring.schedule || '0 0 0 ? * *';
    this.timeZone = Monitoring.timeZone || store.getters['accounts/userTimezone'];
    this.nextCaptureAfter = Monitoring.nextCaptureAfter || undefined;
    this.lastCaptureDate = Monitoring.lastCaptureDate || undefined;
    this.entity = Monitoring.entity || undefined;
    this.url = Monitoring.url || undefined;
    this.tenant = Monitoring.tenant ? new Tenant(Monitoring.tenant) : undefined;
    this.template = new Template(Monitoring.template || undefined);
    this.alertRules = Monitoring.alertRules || undefined;
  }
}