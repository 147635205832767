import Contact from '@/entity/Contact';
import Address from '@/entity/Address';
export default class Tenant {
  constructor(o = {}) {
    const Tenant = o || {};
    this.id = Tenant.id || undefined;
    this.companyName = Tenant.companyName || '';
    this.firstName = Tenant.firstName || '';
    this.lastName = Tenant.lastName || '';
    this.email = Tenant.email || '';
    this.phone = Tenant.phone || '';
    this.taxNumber = Tenant.taxNumber || '';
    this.addresses = Tenant.addresses
      ? (Tenant.addresses).map(item => new Address(item))
      : undefined;
    this.contacts = Tenant.contacts
      ? (Tenant.contacts).map(item => new Contact(item))
      : undefined;
    this.plan = Tenant.plan || undefined;
  }
}
