/* eslint no-unused-vars: 0 */
import api from '@/config/api';
import axios from '@/axios';
import { encode } from '@/plugins/helper';
import Paging from '@/entity/Paging';

import apiConfig from '@/config/api';
const { permissions } = apiConfig;

const actions = {

  async tenants ({ commit }, { params = {}, paging = {}, dontCommit = false } = {}) {
    const query = {
      ...params,
      ...(Object.keys(paging).length > 0 ? Paging.query(paging) : {}),
    }
    const url = `${api.baseUrl}/management/tenants?${encode(query)}`;
    return await axios.get(url, {
      permission: permissions.management.list.tenants
    })
      .then(({ data }) => {
        if (!dontCommit) commit('SET_TENANTS', data);
        return data;
      });
  },
}

export default actions
