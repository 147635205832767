/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

// Modules
import auth from './auth';
import accounts from './accounts';
import captures from './captures';
import templates from './templates';
import monitors from './monitors';
import order from './order';
import management from './management';

Vue.use(Vuex)
const store = new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    accounts,
    captures,
    templates,
    monitors,
    order,
    management
  }
});

store.watch((state, getters) => getters['auth/isLoggedin'], () => {
  if (getters['auth/tenantSelected']) store.dispatch('auth/resolve');
});


export default store;