<!-- =========================================================================================
  File Name: VxSlideInForm.vue
  Description: Sidebar slide-in component that can handle inner component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    :class="classNames"
    spacer
    v-model="active"
  >
    <!-- <transition name="zoom-fade" mode="out-in">
      <router-view />
    </transition> -->
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ config.title }}</h4>
      <feather-icon icon="XIcon" @click.stop="close" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <component ref="form" :is="config.component" v-bind="attrs" :data="data" @submitted="close" />
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData">Submit</vs-button>
      <vs-button type="border" color="danger" @click="close">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

// Forms
import TemplateForm from '@/components/forms/TemplateForm.vue';
import CaptureForm from '@/components/forms/CaptureForm.vue';
import NewTestSuiteForm from '@/views/monitoring/components/test-suite-builder/NewTestSuiteForm.vue';
export default {
  components: {
    VuePerfectScrollbar,
    TemplateForm,
    CaptureForm,
    NewTestSuiteForm
  },
  data() {
    return {
      active: false,
      classNames: '',
      attrs: {},
      config: {},
      data: {},
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  watch: {},
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    }
  },
  methods: {
    submitData() {
      const form = this.$refs.form;
      form.$validator.validateAll().then(async valid => {
        if (valid) {
          const result = await form.submit();
          if (result) this.close();
        }
      });
    },
    async open({ data = {}, config = {}, attrs = {}, classNames = '' } = {}) {
      if (!data) throw Error(`[data] is missing in your open() method`);
      this.data = data;
      this.config = config;
      this.attrs = attrs;
      this.classNames = classNames;
      this.active = true;
      setTimeout(() => {
        const form = this.$refs.form;
        if (form) form.$validator.errors.clear();
      });
    },
    close() {
      this.active = false;
      this.$nextTick(() => {
        this.config = new Object();
        this.attrs = new Object();
        this.classNames = '';
      });
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
