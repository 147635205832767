/* eslint no-unused-vars: 0 */
import api from '@/config/api';
import axios from '@/axios';
import { encode } from '@/plugins/helper';
import Paging from '@/entity/Paging';

const actions = {

  // async list ({ commit }, { params = {}, paging = {}, dontCommit = false } = {}) {
  //   const query = {
  //     ...params,
  //     ...(Object.keys(paging).length > 0 ? Paging.query(paging) : {}),
  //   }
  //   const url = `${api.baseUrl}/monitors?${encode(query)}`;
  //   return await axios.get(url)
  //     .then(({ data }) => {
  //       if (!dontCommit) commit('SET_LIST', data);
  //       return data;
  //     });
  // },

  async item({ commit, dispatch }, { id }) {
    const url = `${api.baseUrl}/order/new/${id}`;
    return await axios.get(url)
      .then(({ data }) => {
        commit('SET_ITEM', data)
        return data;
      });
  },

  async cards({ commit, dispatch }, { data } = {}) {
    const url = `${api.baseUrl}/payment-tokens`;
    const method = data ? 'post' : 'get';
    return await axios({
      url,
      method,
      data: {
        token: ((data || {}).source || {}).id
      }
    }).then(async ({ data }) => {
      if (method === 'post') return await dispatch('cards');
      commit('SET_CARDS', data);
      return data
    });
  },
  
  async setDefaultCard({ commit, dispatch }, card) {
    if (!card) return;
    const url = `${api.baseUrl}/payment-tokens/${card.id}/default`;
    return await axios.post(url)
      .then(async ({ data }) => {
        if (data.success) return await dispatch('cards');
        return data;
      });
  },
  
  async deleteCard({ commit, dispatch }, card) {
    if (!card) return;
    const url = `${api.baseUrl}/payment-tokens/${card.id}`;
    return await axios.delete(url)
      .then(async ({ data }) => {
        if (data.success) return await dispatch('cards');
        return data;
      });
  },

  async upgrade({ commit }, { data } = {}) {
    try {
      const url = `${api.baseUrl}/order/new/${data.id}`;
      return await axios.post(url, {
        billingDetails: data.billingDetails,
        token: (data.source || {}).id
      })
        .then(({ data }) => {
          commit('SET_UPGRADE', data);
          return data;
        })
        .catch(() => null); 
    } catch (error) {
      return null
    }
  },

  async invoices({ commit, dispatch }, { data } = {}) {
    const url = `${api.baseUrl}/invoices`;
    return await axios.get(url)
      .then(({ data }) => {
        commit('SET_INVOICES', data);
        return data;
      });
  },


}

export default actions
