export default {
  list: {},
  hosts: {},
  tags: [],
  item: {
    screenshots: [],
    screencasts: []
  },
  comparing: {},
  requestParams: {},
  testRunning: false,
  hasTestResults: false,
  testCompileErrors: [ ],
  summary: {},
  hasCaptures: false,
  loading: false
}