export default [
  {
		value: 'AD',
    text: 'Andorra',
    native: 'Andorra',
    phone: '376',
    continent: 'EU',
    capital: 'Andorra la Vella',
    currency: 'EUR',
    languages: ['ca']
  },
  {
		value: 'AE',
    text: 'United Arab Emirates',
    native: 'دولة الإمارات العربية المتحدة',
    phone: '971',
    continent: 'AS',
    capital: 'Abu Dhabi',
    currency: 'AED',
    languages: ['ar']
  },
  {
		value: 'AF',
    text: 'Afghanistan',
    native: 'افغانستان',
    phone: '93',
    continent: 'AS',
    capital: 'Kabul',
    currency: 'AFN',
    languages: ['ps', 'uz', 'tk']
  },
  {
		value: 'AG',
    text: 'Antigua and Barbuda',
    native: 'Antigua and Barbuda',
    phone: '1268',
    continent: 'NA',
    capital: "Saint John's",
    currency: 'XCD',
    languages: ['en']
  },
  {
		value: 'AI',
    text: 'Anguilla',
    native: 'Anguilla',
    phone: '1264',
    continent: 'NA',
    capital: 'The Valley',
    currency: 'XCD',
    languages: ['en']
  },
  {
		value: 'AL',
    text: 'Albania',
    native: 'Shqipëria',
    phone: '355',
    continent: 'EU',
    capital: 'Tirana',
    currency: 'ALL',
    languages: ['sq']
  },
  {
		value: 'AM',
    text: 'Armenia',
    native: 'Հայաստան',
    phone: '374',
    continent: 'AS',
    capital: 'Yerevan',
    currency: 'AMD',
    languages: ['hy', 'ru']
  },
  {
		value: 'AO',
    text: 'Angola',
    native: 'Angola',
    phone: '244',
    continent: 'AF',
    capital: 'Luanda',
    currency: 'AOA',
    languages: ['pt']
  },
  {
		value: 'AQ',
    text: 'Antarctica',
    native: 'Antarctica',
    phone: '672',
    continent: 'AN',
    capital: '',
    currency: '',
    languages: []
  },
  {
		value: 'AR',
    text: 'Argentina',
    native: 'Argentina',
    phone: '54',
    continent: 'SA',
    capital: 'Buenos Aires',
    currency: 'ARS',
    languages: ['es', 'gn']
  },
  {
		value: 'AS',
    text: 'American Samoa',
    native: 'American Samoa',
    phone: '1684',
    continent: 'OC',
    capital: 'Pago Pago',
    currency: 'USD',
    languages: ['en', 'sm']
  },
  {
		value: 'AT',
    text: 'Austria',
    native: 'Österreich',
    phone: '43',
    continent: 'EU',
    capital: 'Vienna',
    currency: 'EUR',
    languages: ['de']
  },
  {
		value: 'AU',
    text: 'Australia',
    native: 'Australia',
    phone: '61',
    continent: 'OC',
    capital: 'Canberra',
    currency: 'AUD',
    languages: ['en']
  },
  {
		value: 'AW',
    text: 'Aruba',
    native: 'Aruba',
    phone: '297',
    continent: 'NA',
    capital: 'Oranjestad',
    currency: 'AWG',
    languages: ['nl', 'pa']
  },
  {
		value: 'AX',
    text: 'Åland',
    native: 'Åland',
    phone: '358',
    continent: 'EU',
    capital: 'Mariehamn',
    currency: 'EUR',
    languages: ['sv']
  },
  {
		value: 'AZ',
    text: 'Azerbaijan',
    native: 'Azərbaycan',
    phone: '994',
    continent: 'AS',
    capital: 'Baku',
    currency: 'AZN',
    languages: ['az']
  },
  {
		value: 'BA',
    text: 'Bosnia and Herzegovina',
    native: 'Bosna i Hercegovina',
    phone: '387',
    continent: 'EU',
    capital: 'Sarajevo',
    currency: 'BAM',
    languages: ['bs', 'hr', 'sr']
  },
  {
		value: 'BB',
    text: 'Barbados',
    native: 'Barbados',
    phone: '1246',
    continent: 'NA',
    capital: 'Bridgetown',
    currency: 'BBD',
    languages: ['en']
  },
  {
		value: 'BD',
    text: 'Bangladesh',
    native: 'Bangladesh',
    phone: '880',
    continent: 'AS',
    capital: 'Dhaka',
    currency: 'BDT',
    languages: ['bn']
  },
  {
		value: 'BE',
    text: 'Belgium',
    native: 'België',
    phone: '32',
    continent: 'EU',
    capital: 'Brussels',
    currency: 'EUR',
    languages: ['nl', 'fr', 'de']
  },
  {
		value: 'BF',
    text: 'Burkina Faso',
    native: 'Burkina Faso',
    phone: '226',
    continent: 'AF',
    capital: 'Ouagadougou',
    currency: 'XOF',
    languages: ['fr', 'ff']
  },
  {
		value: 'BG',
    text: 'Bulgaria',
    native: 'България',
    phone: '359',
    continent: 'EU',
    capital: 'Sofia',
    currency: 'BGN',
    languages: ['bg']
  },
  {
		value: 'BH',
    text: 'Bahrain',
    native: '‏البحرين',
    phone: '973',
    continent: 'AS',
    capital: 'Manama',
    currency: 'BHD',
    languages: ['ar']
  },
  {
		value: 'BI',
    text: 'Burundi',
    native: 'Burundi',
    phone: '257',
    continent: 'AF',
    capital: 'Bujumbura',
    currency: 'BIF',
    languages: ['fr', 'rn']
  },
  {
		value: 'BJ',
    text: 'Benin',
    native: 'Bénin',
    phone: '229',
    continent: 'AF',
    capital: 'Porto-Novo',
    currency: 'XOF',
    languages: ['fr']
  },
  {
		value: 'BL',
    text: 'Saint Barthélemy',
    native: 'Saint-Barthélemy',
    phone: '590',
    continent: 'NA',
    capital: 'Gustavia',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'BM',
    text: 'Bermuda',
    native: 'Bermuda',
    phone: '1441',
    continent: 'NA',
    capital: 'Hamilton',
    currency: 'BMD',
    languages: ['en']
  },
  {
		value: 'BN',
    text: 'Brunei',
    native: 'Negara Brunei Darussalam',
    phone: '673',
    continent: 'AS',
    capital: 'Bandar Seri Begawan',
    currency: 'BND',
    languages: ['ms']
  },
  {
		value: 'BO',
    text: 'Bolivia',
    native: 'Bolivia',
    phone: '591',
    continent: 'SA',
    capital: 'Sucre',
    currency: 'BOB,BOV',
    languages: ['es', 'ay', 'qu']
  },
  {
		value: 'BQ',
    text: 'Bonaire',
    native: 'Bonaire',
    phone: '5997',
    continent: 'NA',
    capital: 'Kralendijk',
    currency: 'USD',
    languages: ['nl']
  },
  {
		value: 'BR',
    text: 'Brazil',
    native: 'Brasil',
    phone: '55',
    continent: 'SA',
    capital: 'Brasília',
    currency: 'BRL',
    languages: ['pt']
  },
  {
		value: 'BS',
    text: 'Bahamas',
    native: 'Bahamas',
    phone: '1242',
    continent: 'NA',
    capital: 'Nassau',
    currency: 'BSD',
    languages: ['en']
  },
  {
		value: 'BT',
    text: 'Bhutan',
    native: 'ʼbrug-yul',
    phone: '975',
    continent: 'AS',
    capital: 'Thimphu',
    currency: 'BTN,INR',
    languages: ['dz']
  },
  {
		value: 'BV',
    text: 'Bouvet Island',
    native: 'Bouvetøya',
    phone: '47',
    continent: 'AN',
    capital: '',
    currency: 'NOK',
    languages: ['no', 'nb', 'nn']
  },
  {
		value: 'BW',
    text: 'Botswana',
    native: 'Botswana',
    phone: '267',
    continent: 'AF',
    capital: 'Gaborone',
    currency: 'BWP',
    languages: ['en', 'tn']
  },
  {
		value: 'BY',
    text: 'Belarus',
    native: 'Белару́сь',
    phone: '375',
    continent: 'EU',
    capital: 'Minsk',
    currency: 'BYN',
    languages: ['be', 'ru']
  },
  {
		value: 'BZ',
    text: 'Belize',
    native: 'Belize',
    phone: '501',
    continent: 'NA',
    capital: 'Belmopan',
    currency: 'BZD',
    languages: ['en', 'es']
  },
  {
		value: 'CA',
    text: 'Canada',
    native: 'Canada',
    phone: '1',
    continent: 'NA',
    capital: 'Ottawa',
    currency: 'CAD',
    languages: ['en', 'fr']
  },
  {
		value: 'CC',
    text: 'Cocos [Keeling] Islands',
    native: 'Cocos (Keeling) Islands',
    phone: '61',
    continent: 'AS',
    capital: 'West Island',
    currency: 'AUD',
    languages: ['en']
  },
  {
		value: 'CD',
    text: 'Democratic Republic of the Congo',
    native: 'République démocratique du Congo',
    phone: '243',
    continent: 'AF',
    capital: 'Kinshasa',
    currency: 'CDF',
    languages: ['fr', 'ln', 'kg', 'sw', 'lu']
  },
  {
		value: 'CF',
    text: 'Central African Republic',
    native: 'Ködörösêse tî Bêafrîka',
    phone: '236',
    continent: 'AF',
    capital: 'Bangui',
    currency: 'XAF',
    languages: ['fr', 'sg']
  },
  {
		value: 'CG',
    text: 'Republic of the Congo',
    native: 'République du Congo',
    phone: '242',
    continent: 'AF',
    capital: 'Brazzaville',
    currency: 'XAF',
    languages: ['fr', 'ln']
  },
  {
		value: 'CH',
    text: 'Switzerland',
    native: 'Schweiz',
    phone: '41',
    continent: 'EU',
    capital: 'Bern',
    currency: 'CHE,CHF,CHW',
    languages: ['de', 'fr', 'it']
  },
  {
		value: 'CI',
    text: 'Ivory Coast',
    native: "Côte d'Ivoire",
    phone: '225',
    continent: 'AF',
    capital: 'Yamoussoukro',
    currency: 'XOF',
    languages: ['fr']
  },
  {
		value: 'CK',
    text: 'Cook Islands',
    native: 'Cook Islands',
    phone: '682',
    continent: 'OC',
    capital: 'Avarua',
    currency: 'NZD',
    languages: ['en']
  },
  {
		value: 'CL',
    text: 'Chile',
    native: 'Chile',
    phone: '56',
    continent: 'SA',
    capital: 'Santiago',
    currency: 'CLF,CLP',
    languages: ['es']
  },
  {
		value: 'CM',
    text: 'Cameroon',
    native: 'Cameroon',
    phone: '237',
    continent: 'AF',
    capital: 'Yaoundé',
    currency: 'XAF',
    languages: ['en', 'fr']
  },
  {
		value: 'CN',
    text: 'China',
    native: '中国',
    phone: '86',
    continent: 'AS',
    capital: 'Beijing',
    currency: 'CNY',
    languages: ['zh']
  },
  {
		value: 'CO',
    text: 'Colombia',
    native: 'Colombia',
    phone: '57',
    continent: 'SA',
    capital: 'Bogotá',
    currency: 'COP',
    languages: ['es']
  },
  {
		value: 'CR',
    text: 'Costa Rica',
    native: 'Costa Rica',
    phone: '506',
    continent: 'NA',
    capital: 'San José',
    currency: 'CRC',
    languages: ['es']
  },
  {
		value: 'CU',
    text: 'Cuba',
    native: 'Cuba',
    phone: '53',
    continent: 'NA',
    capital: 'Havana',
    currency: 'CUC,CUP',
    languages: ['es']
  },
  {
		value: 'CV',
    text: 'Cape Verde',
    native: 'Cabo Verde',
    phone: '238',
    continent: 'AF',
    capital: 'Praia',
    currency: 'CVE',
    languages: ['pt']
  },
  {
		value: 'CW',
    text: 'Curacao',
    native: 'Curaçao',
    phone: '5999',
    continent: 'NA',
    capital: 'Willemstad',
    currency: 'ANG',
    languages: ['nl', 'pa', 'en']
  },
  {
		value: 'CX',
    text: 'Christmas Island',
    native: 'Christmas Island',
    phone: '61',
    continent: 'AS',
    capital: 'Flying Fish Cove',
    currency: 'AUD',
    languages: ['en']
  },
  {
		value: 'CY',
    text: 'Cyprus',
    native: 'Κύπρος',
    phone: '357',
    continent: 'EU',
    capital: 'Nicosia',
    currency: 'EUR',
    languages: ['el', 'tr', 'hy']
  },
  {
		value: 'CZ',
    text: 'Czech Republic',
    native: 'Česká republika',
    phone: '420',
    continent: 'EU',
    capital: 'Prague',
    currency: 'CZK',
    languages: ['cs', 'sk']
  },
  {
		value: 'DE',
    text: 'Germany',
    native: 'Deutschland',
    phone: '49',
    continent: 'EU',
    capital: 'Berlin',
    currency: 'EUR',
    languages: ['de']
  },
  {
		value: 'DJ',
    text: 'Djibouti',
    native: 'Djibouti',
    phone: '253',
    continent: 'AF',
    capital: 'Djibouti',
    currency: 'DJF',
    languages: ['fr', 'ar']
  },
  {
		value: 'DK',
    text: 'Denmark',
    native: 'Danmark',
    phone: '45',
    continent: 'EU',
    capital: 'Copenhagen',
    currency: 'DKK',
    languages: ['da']
  },
  {
		value: 'DM',
    text: 'Dominica',
    native: 'Dominica',
    phone: '1767',
    continent: 'NA',
    capital: 'Roseau',
    currency: 'XCD',
    languages: ['en']
  },
  {
		value: 'DO',
    text: 'Dominican Republic',
    native: 'República Dominicana',
    phone: '1809,1829,1849',
    continent: 'NA',
    capital: 'Santo Domingo',
    currency: 'DOP',
    languages: ['es']
  },
  {
		value: 'DZ',
    text: 'Algeria',
    native: 'الجزائر',
    phone: '213',
    continent: 'AF',
    capital: 'Algiers',
    currency: 'DZD',
    languages: ['ar']
  },
  {
		value: 'EC',
    text: 'Ecuador',
    native: 'Ecuador',
    phone: '593',
    continent: 'SA',
    capital: 'Quito',
    currency: 'USD',
    languages: ['es']
  },
  {
		value: 'EE',
    text: 'Estonia',
    native: 'Eesti',
    phone: '372',
    continent: 'EU',
    capital: 'Tallinn',
    currency: 'EUR',
    languages: ['et']
  },
  {
		value: 'EG',
    text: 'Egypt',
    native: 'مصر‎',
    phone: '20',
    continent: 'AF',
    capital: 'Cairo',
    currency: 'EGP',
    languages: ['ar']
  },
  {
		value: 'EH',
    text: 'Western Sahara',
    native: 'الصحراء الغربية',
    phone: '212',
    continent: 'AF',
    capital: 'El Aaiún',
    currency: 'MAD,DZD,MRU',
    languages: ['es']
  },
  {
		value: 'ER',
    text: 'Eritrea',
    native: 'ኤርትራ',
    phone: '291',
    continent: 'AF',
    capital: 'Asmara',
    currency: 'ERN',
    languages: ['ti', 'ar', 'en']
  },
  {
		value: 'ES',
    text: 'Spain',
    native: 'España',
    phone: '34',
    continent: 'EU',
    capital: 'Madrid',
    currency: 'EUR',
    languages: ['es', 'eu', 'ca', 'gl', 'oc']
  },
  {
		value: 'ET',
    text: 'Ethiopia',
    native: 'ኢትዮጵያ',
    phone: '251',
    continent: 'AF',
    capital: 'Addis Ababa',
    currency: 'ETB',
    languages: ['am']
  },
  {
		value: 'FI',
    text: 'Finland',
    native: 'Suomi',
    phone: '358',
    continent: 'EU',
    capital: 'Helsinki',
    currency: 'EUR',
    languages: ['fi', 'sv']
  },
  {
		value: 'FJ',
    text: 'Fiji',
    native: 'Fiji',
    phone: '679',
    continent: 'OC',
    capital: 'Suva',
    currency: 'FJD',
    languages: ['en', 'fj', 'hi', 'ur']
  },
  {
		value: 'FK',
    text: 'Falkland Islands',
    native: 'Falkland Islands',
    phone: '500',
    continent: 'SA',
    capital: 'Stanley',
    currency: 'FKP',
    languages: ['en']
  },
  {
		value: 'FM',
    text: 'Micronesia',
    native: 'Micronesia',
    phone: '691',
    continent: 'OC',
    capital: 'Palikir',
    currency: 'USD',
    languages: ['en']
  },
  {
		value: 'FO',
    text: 'Faroe Islands',
    native: 'Føroyar',
    phone: '298',
    continent: 'EU',
    capital: 'Tórshavn',
    currency: 'DKK',
    languages: ['fo']
  },
  {
		value: 'FR',
    text: 'France',
    native: 'France',
    phone: '33',
    continent: 'EU',
    capital: 'Paris',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'GA',
    text: 'Gabon',
    native: 'Gabon',
    phone: '241',
    continent: 'AF',
    capital: 'Libreville',
    currency: 'XAF',
    languages: ['fr']
  },
  {
		value: 'GB',
    text: 'United Kingdom',
    native: 'United Kingdom',
    phone: '44',
    continent: 'EU',
    capital: 'London',
    currency: 'GBP',
    languages: ['en']
  },
  {
		value: 'GD',
    text: 'Grenada',
    native: 'Grenada',
    phone: '1473',
    continent: 'NA',
    capital: "St. George's",
    currency: 'XCD',
    languages: ['en']
  },
  {
		value: 'GE',
    text: 'Georgia',
    native: 'საქართველო',
    phone: '995',
    continent: 'AS',
    capital: 'Tbilisi',
    currency: 'GEL',
    languages: ['ka']
  },
  {
		value: 'GF',
    text: 'French Guiana',
    native: 'Guyane française',
    phone: '594',
    continent: 'SA',
    capital: 'Cayenne',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'GG',
    text: 'Guernsey',
    native: 'Guernsey',
    phone: '44',
    continent: 'EU',
    capital: 'St. Peter Port',
    currency: 'GBP',
    languages: ['en', 'fr']
  },
  {
		value: 'GH',
    text: 'Ghana',
    native: 'Ghana',
    phone: '233',
    continent: 'AF',
    capital: 'Accra',
    currency: 'GHS',
    languages: ['en']
  },
  {
		value: 'GI',
    text: 'Gibraltar',
    native: 'Gibraltar',
    phone: '350',
    continent: 'EU',
    capital: 'Gibraltar',
    currency: 'GIP',
    languages: ['en']
  },
  {
		value: 'GL',
    text: 'Greenland',
    native: 'Kalaallit Nunaat',
    phone: '299',
    continent: 'NA',
    capital: 'Nuuk',
    currency: 'DKK',
    languages: ['kl']
  },
  {
		value: 'GM',
    text: 'Gambia',
    native: 'Gambia',
    phone: '220',
    continent: 'AF',
    capital: 'Banjul',
    currency: 'GMD',
    languages: ['en']
  },
  {
		value: 'GN',
    text: 'Guinea',
    native: 'Guinée',
    phone: '224',
    continent: 'AF',
    capital: 'Conakry',
    currency: 'GNF',
    languages: ['fr', 'ff']
  },
  {
		value: 'GP',
    text: 'Guadeloupe',
    native: 'Guadeloupe',
    phone: '590',
    continent: 'NA',
    capital: 'Basse-Terre',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'GQ',
    text: 'Equatorial Guinea',
    native: 'Guinea Ecuatorial',
    phone: '240',
    continent: 'AF',
    capital: 'Malabo',
    currency: 'XAF',
    languages: ['es', 'fr']
  },
  {
		value: 'GR',
    text: 'Greece',
    native: 'Ελλάδα',
    phone: '30',
    continent: 'EU',
    capital: 'Athens',
    currency: 'EUR',
    languages: ['el']
  },
  {
		value: 'GS',
    text: 'South Georgia and the South Sandwich Islands',
    native: 'South Georgia',
    phone: '500',
    continent: 'AN',
    capital: 'King Edward Point',
    currency: 'GBP',
    languages: ['en']
  },
  {
		value: 'GT',
    text: 'Guatemala',
    native: 'Guatemala',
    phone: '502',
    continent: 'NA',
    capital: 'Guatemala City',
    currency: 'GTQ',
    languages: ['es']
  },
  {
		value: 'GU',
    text: 'Guam',
    native: 'Guam',
    phone: '1671',
    continent: 'OC',
    capital: 'Hagåtña',
    currency: 'USD',
    languages: ['en', 'ch', 'es']
  },
  {
		value: 'GW',
    text: 'Guinea-Bissau',
    native: 'Guiné-Bissau',
    phone: '245',
    continent: 'AF',
    capital: 'Bissau',
    currency: 'XOF',
    languages: ['pt']
  },
  {
		value: 'GY',
    text: 'Guyana',
    native: 'Guyana',
    phone: '592',
    continent: 'SA',
    capital: 'Georgetown',
    currency: 'GYD',
    languages: ['en']
  },
  {
		value: 'HK',
    text: 'Hong Kong',
    native: '香港',
    phone: '852',
    continent: 'AS',
    capital: 'City of Victoria',
    currency: 'HKD',
    languages: ['zh', 'en']
  },
  {
		value: 'HM',
    text: 'Heard Island and McDonald Islands',
    native: 'Heard Island and McDonald Islands',
    phone: '61',
    continent: 'AN',
    capital: '',
    currency: 'AUD',
    languages: ['en']
  },
  {
		value: 'HN',
    text: 'Honduras',
    native: 'Honduras',
    phone: '504',
    continent: 'NA',
    capital: 'Tegucigalpa',
    currency: 'HNL',
    languages: ['es']
  },
  {
		value: 'HR',
    text: 'Croatia',
    native: 'Hrvatska',
    phone: '385',
    continent: 'EU',
    capital: 'Zagreb',
    currency: 'HRK',
    languages: ['hr']
  },
  {
		value: 'HT',
    text: 'Haiti',
    native: 'Haïti',
    phone: '509',
    continent: 'NA',
    capital: 'Port-au-Prince',
    currency: 'HTG,USD',
    languages: ['fr', 'ht']
  },
  {
		value: 'HU',
    text: 'Hungary',
    native: 'Magyarország',
    phone: '36',
    continent: 'EU',
    capital: 'Budapest',
    currency: 'HUF',
    languages: ['hu']
  },
  {
		value: 'ID',
    text: 'Indonesia',
    native: 'Indonesia',
    phone: '62',
    continent: 'AS',
    capital: 'Jakarta',
    currency: 'IDR',
    languages: ['id']
  },
  {
		value: 'IE',
    text: 'Ireland',
    native: 'Éire',
    phone: '353',
    continent: 'EU',
    capital: 'Dublin',
    currency: 'EUR',
    languages: ['ga', 'en']
  },
  {
		value: 'IL',
    text: 'Israel',
    native: 'יִשְׂרָאֵל',
    phone: '972',
    continent: 'AS',
    capital: 'Jerusalem',
    currency: 'ILS',
    languages: ['he', 'ar']
  },
  {
		value: 'IM',
    text: 'Isle of Man',
    native: 'Isle of Man',
    phone: '44',
    continent: 'EU',
    capital: 'Douglas',
    currency: 'GBP',
    languages: ['en', 'gv']
  },
  {
		value: 'IN',
    text: 'India',
    native: 'भारत',
    phone: '91',
    continent: 'AS',
    capital: 'New Delhi',
    currency: 'INR',
    languages: ['hi', 'en']
  },
  {
		value: 'IO',
    text: 'British Indian Ocean Territory',
    native: 'British Indian Ocean Territory',
    phone: '246',
    continent: 'AS',
    capital: 'Diego Garcia',
    currency: 'USD',
    languages: ['en']
  },
  {
		value: 'IQ',
    text: 'Iraq',
    native: 'العراق',
    phone: '964',
    continent: 'AS',
    capital: 'Baghdad',
    currency: 'IQD',
    languages: ['ar', 'ku']
  },
  {
		value: 'IR',
    text: 'Iran',
    native: 'ایران',
    phone: '98',
    continent: 'AS',
    capital: 'Tehran',
    currency: 'IRR',
    languages: ['fa']
  },
  {
		value: 'IS',
    text: 'Iceland',
    native: 'Ísland',
    phone: '354',
    continent: 'EU',
    capital: 'Reykjavik',
    currency: 'ISK',
    languages: ['is']
  },
  {
		value: 'IT',
    text: 'Italy',
    native: 'Italia',
    phone: '39',
    continent: 'EU',
    capital: 'Rome',
    currency: 'EUR',
    languages: ['it']
  },
  {
		value: 'JE',
    text: 'Jersey',
    native: 'Jersey',
    phone: '44',
    continent: 'EU',
    capital: 'Saint Helier',
    currency: 'GBP',
    languages: ['en', 'fr']
  },
  {
		value: 'JM',
    text: 'Jamaica',
    native: 'Jamaica',
    phone: '1876',
    continent: 'NA',
    capital: 'Kingston',
    currency: 'JMD',
    languages: ['en']
  },
  {
		value: 'JO',
    text: 'Jordan',
    native: 'الأردن',
    phone: '962',
    continent: 'AS',
    capital: 'Amman',
    currency: 'JOD',
    languages: ['ar']
  },
  {
		value: 'JP',
    text: 'Japan',
    native: '日本',
    phone: '81',
    continent: 'AS',
    capital: 'Tokyo',
    currency: 'JPY',
    languages: ['ja']
  },
  {
		value: 'KE',
    text: 'Kenya',
    native: 'Kenya',
    phone: '254',
    continent: 'AF',
    capital: 'Nairobi',
    currency: 'KES',
    languages: ['en', 'sw']
  },
  {
		value: 'KG',
    text: 'Kyrgyzstan',
    native: 'Кыргызстан',
    phone: '996',
    continent: 'AS',
    capital: 'Bishkek',
    currency: 'KGS',
    languages: ['ky', 'ru']
  },
  {
		value: 'KH',
    text: 'Cambodia',
    native: 'Kâmpŭchéa',
    phone: '855',
    continent: 'AS',
    capital: 'Phnom Penh',
    currency: 'KHR',
    languages: ['km']
  },
  {
		value: 'KI',
    text: 'Kiribati',
    native: 'Kiribati',
    phone: '686',
    continent: 'OC',
    capital: 'South Tarawa',
    currency: 'AUD',
    languages: ['en']
  },
  {
		value: 'KM',
    text: 'Comoros',
    native: 'Komori',
    phone: '269',
    continent: 'AF',
    capital: 'Moroni',
    currency: 'KMF',
    languages: ['ar', 'fr']
  },
  {
		value: 'KN',
    text: 'Saint Kitts and Nevis',
    native: 'Saint Kitts and Nevis',
    phone: '1869',
    continent: 'NA',
    capital: 'Basseterre',
    currency: 'XCD',
    languages: ['en']
  },
  {
		value: 'KP',
    text: 'North Korea',
    native: '북한',
    phone: '850',
    continent: 'AS',
    capital: 'Pyongyang',
    currency: 'KPW',
    languages: ['ko']
  },
  {
		value: 'KR',
    text: 'South Korea',
    native: '대한민국',
    phone: '82',
    continent: 'AS',
    capital: 'Seoul',
    currency: 'KRW',
    languages: ['ko']
  },
  {
		value: 'KW',
    text: 'Kuwait',
    native: 'الكويت',
    phone: '965',
    continent: 'AS',
    capital: 'Kuwait City',
    currency: 'KWD',
    languages: ['ar']
  },
  {
		value: 'KY',
    text: 'Cayman Islands',
    native: 'Cayman Islands',
    phone: '1345',
    continent: 'NA',
    capital: 'George Town',
    currency: 'KYD',
    languages: ['en']
  },
  {
		value: 'KZ',
    text: 'Kazakhstan',
    native: 'Қазақстан',
    phone: '76,77',
    continent: 'AS',
    capital: 'Astana',
    currency: 'KZT',
    languages: ['kk', 'ru']
  },
  {
		value: 'LA',
    text: 'Laos',
    native: 'ສປປລາວ',
    phone: '856',
    continent: 'AS',
    capital: 'Vientiane',
    currency: 'LAK',
    languages: ['lo']
  },
  {
		value: 'LB',
    text: 'Lebanon',
    native: 'لبنان',
    phone: '961',
    continent: 'AS',
    capital: 'Beirut',
    currency: 'LBP',
    languages: ['ar', 'fr']
  },
  {
		value: 'LC',
    text: 'Saint Lucia',
    native: 'Saint Lucia',
    phone: '1758',
    continent: 'NA',
    capital: 'Castries',
    currency: 'XCD',
    languages: ['en']
  },
  {
		value: 'LI',
    text: 'Liechtenstein',
    native: 'Liechtenstein',
    phone: '423',
    continent: 'EU',
    capital: 'Vaduz',
    currency: 'CHF',
    languages: ['de']
  },
  {
		value: 'LK',
    text: 'Sri Lanka',
    native: 'śrī laṃkāva',
    phone: '94',
    continent: 'AS',
    capital: 'Colombo',
    currency: 'LKR',
    languages: ['si', 'ta']
  },
  {
		value: 'LR',
    text: 'Liberia',
    native: 'Liberia',
    phone: '231',
    continent: 'AF',
    capital: 'Monrovia',
    currency: 'LRD',
    languages: ['en']
  },
  {
		value: 'LS',
    text: 'Lesotho',
    native: 'Lesotho',
    phone: '266',
    continent: 'AF',
    capital: 'Maseru',
    currency: 'LSL,ZAR',
    languages: ['en', 'st']
  },
  {
		value: 'LT',
    text: 'Lithuania',
    native: 'Lietuva',
    phone: '370',
    continent: 'EU',
    capital: 'Vilnius',
    currency: 'EUR',
    languages: ['lt']
  },
  {
		value: 'LU',
    text: 'Luxembourg',
    native: 'Luxembourg',
    phone: '352',
    continent: 'EU',
    capital: 'Luxembourg',
    currency: 'EUR',
    languages: ['fr', 'de', 'lb']
  },
  {
		value: 'LV',
    text: 'Latvia',
    native: 'Latvija',
    phone: '371',
    continent: 'EU',
    capital: 'Riga',
    currency: 'EUR',
    languages: ['lv']
  },
  {
		value: 'LY',
    text: 'Libya',
    native: '‏ليبيا',
    phone: '218',
    continent: 'AF',
    capital: 'Tripoli',
    currency: 'LYD',
    languages: ['ar']
  },
  {
		value: 'MA',
    text: 'Morocco',
    native: 'المغرب',
    phone: '212',
    continent: 'AF',
    capital: 'Rabat',
    currency: 'MAD',
    languages: ['ar']
  },
  {
		value: 'MC',
    text: 'Monaco',
    native: 'Monaco',
    phone: '377',
    continent: 'EU',
    capital: 'Monaco',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'MD',
    text: 'Moldova',
    native: 'Moldova',
    phone: '373',
    continent: 'EU',
    capital: 'Chișinău',
    currency: 'MDL',
    languages: ['ro']
  },
  {
		value: 'ME',
    text: 'Montenegro',
    native: 'Црна Гора',
    phone: '382',
    continent: 'EU',
    capital: 'Podgorica',
    currency: 'EUR',
    languages: ['sr', 'bs', 'sq', 'hr']
  },
  {
		value: 'MF',
    text: 'Saint Martin',
    native: 'Saint-Martin',
    phone: '590',
    continent: 'NA',
    capital: 'Marigot',
    currency: 'EUR',
    languages: ['en', 'fr', 'nl']
  },
  {
		value: 'MG',
    text: 'Madagascar',
    native: 'Madagasikara',
    phone: '261',
    continent: 'AF',
    capital: 'Antananarivo',
    currency: 'MGA',
    languages: ['fr', 'mg']
  },
  {
		value: 'MH',
    text: 'Marshall Islands',
    native: 'M̧ajeļ',
    phone: '692',
    continent: 'OC',
    capital: 'Majuro',
    currency: 'USD',
    languages: ['en', 'mh']
  },
  {
		value: 'MK',
    text: 'North Macedonia',
    native: 'Северна Македонија',
    phone: '389',
    continent: 'EU',
    capital: 'Skopje',
    currency: 'MKD',
    languages: ['mk']
  },
  {
		value: 'ML',
    text: 'Mali',
    native: 'Mali',
    phone: '223',
    continent: 'AF',
    capital: 'Bamako',
    currency: 'XOF',
    languages: ['fr']
  },
  {
		value: 'MM',
    text: 'Myanmar [Burma]',
    native: 'မြန်မာ',
    phone: '95',
    continent: 'AS',
    capital: 'Naypyidaw',
    currency: 'MMK',
    languages: ['my']
  },
  {
		value: 'MN',
    text: 'Mongolia',
    native: 'Монгол улс',
    phone: '976',
    continent: 'AS',
    capital: 'Ulan Bator',
    currency: 'MNT',
    languages: ['mn']
  },
  {
		value: 'MO',
    text: 'Macao',
    native: '澳門',
    phone: '853',
    continent: 'AS',
    capital: '',
    currency: 'MOP',
    languages: ['zh', 'pt']
  },
  {
		value: 'MP',
    text: 'Northern Mariana Islands',
    native: 'Northern Mariana Islands',
    phone: '1670',
    continent: 'OC',
    capital: 'Saipan',
    currency: 'USD',
    languages: ['en', 'ch']
  },
  {
		value: 'MQ',
    text: 'Martinique',
    native: 'Martinique',
    phone: '596',
    continent: 'NA',
    capital: 'Fort-de-France',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'MR',
    text: 'Mauritania',
    native: 'موريتانيا',
    phone: '222',
    continent: 'AF',
    capital: 'Nouakchott',
    currency: 'MRU',
    languages: ['ar']
  },
  {
		value: 'MS',
    text: 'Montserrat',
    native: 'Montserrat',
    phone: '1664',
    continent: 'NA',
    capital: 'Plymouth',
    currency: 'XCD',
    languages: ['en']
  },
  {
		value: 'MT',
    text: 'Malta',
    native: 'Malta',
    phone: '356',
    continent: 'EU',
    capital: 'Valletta',
    currency: 'EUR',
    languages: ['mt', 'en']
  },
  {
		value: 'MU',
    text: 'Mauritius',
    native: 'Maurice',
    phone: '230',
    continent: 'AF',
    capital: 'Port Louis',
    currency: 'MUR',
    languages: ['en']
  },
  {
		value: 'MV',
    text: 'Maldives',
    native: 'Maldives',
    phone: '960',
    continent: 'AS',
    capital: 'Malé',
    currency: 'MVR',
    languages: ['dv']
  },
  {
		value: 'MW',
    text: 'Malawi',
    native: 'Malawi',
    phone: '265',
    continent: 'AF',
    capital: 'Lilongwe',
    currency: 'MWK',
    languages: ['en', 'ny']
  },
  {
		value: 'MX',
    text: 'Mexico',
    native: 'México',
    phone: '52',
    continent: 'NA',
    capital: 'Mexico City',
    currency: 'MXN',
    languages: ['es']
  },
  {
		value: 'MY',
    text: 'Malaysia',
    native: 'Malaysia',
    phone: '60',
    continent: 'AS',
    capital: 'Kuala Lumpur',
    currency: 'MYR',
    languages: ['ms']
  },
  {
		value: 'MZ',
    text: 'Mozambique',
    native: 'Moçambique',
    phone: '258',
    continent: 'AF',
    capital: 'Maputo',
    currency: 'MZN',
    languages: ['pt']
  },
  {
		value: 'NA',
    text: 'Namibia',
    native: 'Namibia',
    phone: '264',
    continent: 'AF',
    capital: 'Windhoek',
    currency: 'NAD,ZAR',
    languages: ['en', 'af']
  },
  {
		value: 'NC',
    text: 'New Caledonia',
    native: 'Nouvelle-Calédonie',
    phone: '687',
    continent: 'OC',
    capital: 'Nouméa',
    currency: 'XPF',
    languages: ['fr']
  },
  {
		value: 'NE',
    text: 'Niger',
    native: 'Niger',
    phone: '227',
    continent: 'AF',
    capital: 'Niamey',
    currency: 'XOF',
    languages: ['fr']
  },
  {
		value: 'NF',
    text: 'Norfolk Island',
    native: 'Norfolk Island',
    phone: '672',
    continent: 'OC',
    capital: 'Kingston',
    currency: 'AUD',
    languages: ['en']
  },
  {
		value: 'NG',
    text: 'Nigeria',
    native: 'Nigeria',
    phone: '234',
    continent: 'AF',
    capital: 'Abuja',
    currency: 'NGN',
    languages: ['en']
  },
  {
		value: 'NI',
    text: 'Nicaragua',
    native: 'Nicaragua',
    phone: '505',
    continent: 'NA',
    capital: 'Managua',
    currency: 'NIO',
    languages: ['es']
  },
  {
		value: 'NL',
    text: 'Netherlands',
    native: 'Nederland',
    phone: '31',
    continent: 'EU',
    capital: 'Amsterdam',
    currency: 'EUR',
    languages: ['nl']
  },
  {
		value: 'NO',
    text: 'Norway',
    native: 'Norge',
    phone: '47',
    continent: 'EU',
    capital: 'Oslo',
    currency: 'NOK',
    languages: ['no', 'nb', 'nn']
  },
  {
		value: 'NP',
    text: 'Nepal',
    native: 'नपल',
    phone: '977',
    continent: 'AS',
    capital: 'Kathmandu',
    currency: 'NPR',
    languages: ['ne']
  },
  {
		value: 'NR',
    text: 'Nauru',
    native: 'Nauru',
    phone: '674',
    continent: 'OC',
    capital: 'Yaren',
    currency: 'AUD',
    languages: ['en', 'na']
  },
  {
		value: 'NU',
    text: 'Niue',
    native: 'Niuē',
    phone: '683',
    continent: 'OC',
    capital: 'Alofi',
    currency: 'NZD',
    languages: ['en']
  },
  {
		value: 'NZ',
    text: 'New Zealand',
    native: 'New Zealand',
    phone: '64',
    continent: 'OC',
    capital: 'Wellington',
    currency: 'NZD',
    languages: ['en', 'mi']
  },
  {
		value: 'OM',
    text: 'Oman',
    native: 'عمان',
    phone: '968',
    continent: 'AS',
    capital: 'Muscat',
    currency: 'OMR',
    languages: ['ar']
  },
  {
		value: 'PA',
    text: 'Panama',
    native: 'Panamá',
    phone: '507',
    continent: 'NA',
    capital: 'Panama City',
    currency: 'PAB,USD',
    languages: ['es']
  },
  {
		value: 'PE',
    text: 'Peru',
    native: 'Perú',
    phone: '51',
    continent: 'SA',
    capital: 'Lima',
    currency: 'PEN',
    languages: ['es']
  },
  {
		value: 'PF',
    text: 'French Polynesia',
    native: 'Polynésie française',
    phone: '689',
    continent: 'OC',
    capital: 'Papeetē',
    currency: 'XPF',
    languages: ['fr']
  },
  {
		value: 'PG',
    text: 'Papua New Guinea',
    native: 'Papua Niugini',
    phone: '675',
    continent: 'OC',
    capital: 'Port Moresby',
    currency: 'PGK',
    languages: ['en']
  },
  {
		value: 'PH',
    text: 'Philippines',
    native: 'Pilipinas',
    phone: '63',
    continent: 'AS',
    capital: 'Manila',
    currency: 'PHP',
    languages: ['en']
  },
  {
		value: 'PK',
    text: 'Pakistan',
    native: 'Pakistan',
    phone: '92',
    continent: 'AS',
    capital: 'Islamabad',
    currency: 'PKR',
    languages: ['en', 'ur']
  },
  {
		value: 'PL',
    text: 'Poland',
    native: 'Polska',
    phone: '48',
    continent: 'EU',
    capital: 'Warsaw',
    currency: 'PLN',
    languages: ['pl']
  },
  {
		value: 'PM',
    text: 'Saint Pierre and Miquelon',
    native: 'Saint-Pierre-et-Miquelon',
    phone: '508',
    continent: 'NA',
    capital: 'Saint-Pierre',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'PN',
    text: 'Pitcairn Islands',
    native: 'Pitcairn Islands',
    phone: '64',
    continent: 'OC',
    capital: 'Adamstown',
    currency: 'NZD',
    languages: ['en']
  },
  {
		value: 'PR',
    text: 'Puerto Rico',
    native: 'Puerto Rico',
    phone: '1787,1939',
    continent: 'NA',
    capital: 'San Juan',
    currency: 'USD',
    languages: ['es', 'en']
  },
  {
		value: 'PS',
    text: 'Palestine',
    native: 'فلسطين',
    phone: '970',
    continent: 'AS',
    capital: 'Ramallah',
    currency: 'ILS',
    languages: ['ar']
  },
  {
		value: 'PT',
    text: 'Portugal',
    native: 'Portugal',
    phone: '351',
    continent: 'EU',
    capital: 'Lisbon',
    currency: 'EUR',
    languages: ['pt']
  },
  {
		value: 'PW',
    text: 'Palau',
    native: 'Palau',
    phone: '680',
    continent: 'OC',
    capital: 'Ngerulmud',
    currency: 'USD',
    languages: ['en']
  },
  {
		value: 'PY',
    text: 'Paraguay',
    native: 'Paraguay',
    phone: '595',
    continent: 'SA',
    capital: 'Asunción',
    currency: 'PYG',
    languages: ['es', 'gn']
  },
  {
		value: 'QA',
    text: 'Qatar',
    native: 'قطر',
    phone: '974',
    continent: 'AS',
    capital: 'Doha',
    currency: 'QAR',
    languages: ['ar']
  },
  {
		value: 'RE',
    text: 'Réunion',
    native: 'La Réunion',
    phone: '262',
    continent: 'AF',
    capital: 'Saint-Denis',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'RO',
    text: 'Romania',
    native: 'România',
    phone: '40',
    continent: 'EU',
    capital: 'Bucharest',
    currency: 'RON',
    languages: ['ro']
  },
  {
		value: 'RS',
    text: 'Serbia',
    native: 'Србија',
    phone: '381',
    continent: 'EU',
    capital: 'Belgrade',
    currency: 'RSD',
    languages: ['sr']
  },
  {
		value: 'RU',
    text: 'Russia',
    native: 'Россия',
    phone: '7',
    continent: 'EU',
    capital: 'Moscow',
    currency: 'RUB',
    languages: ['ru']
  },
  {
		value: 'RW',
    text: 'Rwanda',
    native: 'Rwanda',
    phone: '250',
    continent: 'AF',
    capital: 'Kigali',
    currency: 'RWF',
    languages: ['rw', 'en', 'fr']
  },
  {
		value: 'SA',
    text: 'Saudi Arabia',
    native: 'العربية السعودية',
    phone: '966',
    continent: 'AS',
    capital: 'Riyadh',
    currency: 'SAR',
    languages: ['ar']
  },
  {
		value: 'SB',
    text: 'Solomon Islands',
    native: 'Solomon Islands',
    phone: '677',
    continent: 'OC',
    capital: 'Honiara',
    currency: 'SBD',
    languages: ['en']
  },
  {
		value: 'SC',
    text: 'Seychelles',
    native: 'Seychelles',
    phone: '248',
    continent: 'AF',
    capital: 'Victoria',
    currency: 'SCR',
    languages: ['fr', 'en']
  },
  {
		value: 'SD',
    text: 'Sudan',
    native: 'السودان',
    phone: '249',
    continent: 'AF',
    capital: 'Khartoum',
    currency: 'SDG',
    languages: ['ar', 'en']
  },
  {
		value: 'SE',
    text: 'Sweden',
    native: 'Sverige',
    phone: '46',
    continent: 'EU',
    capital: 'Stockholm',
    currency: 'SEK',
    languages: ['sv']
  },
  {
		value: 'SG',
    text: 'Singapore',
    native: 'Singapore',
    phone: '65',
    continent: 'AS',
    capital: 'Singapore',
    currency: 'SGD',
    languages: ['en', 'ms', 'ta', 'zh']
  },
  {
		value: 'SH',
    text: 'Saint Helena',
    native: 'Saint Helena',
    phone: '290',
    continent: 'AF',
    capital: 'Jamestown',
    currency: 'SHP',
    languages: ['en']
  },
  {
		value: 'SI',
    text: 'Slovenia',
    native: 'Slovenija',
    phone: '386',
    continent: 'EU',
    capital: 'Ljubljana',
    currency: 'EUR',
    languages: ['sl']
  },
  {
		value: 'SJ',
    text: 'Svalbard and Jan Mayen',
    native: 'Svalbard og Jan Mayen',
    phone: '4779',
    continent: 'EU',
    capital: 'Longyearbyen',
    currency: 'NOK',
    languages: ['no']
  },
  {
		value: 'SK',
    text: 'Slovakia',
    native: 'Slovensko',
    phone: '421',
    continent: 'EU',
    capital: 'Bratislava',
    currency: 'EUR',
    languages: ['sk']
  },
  {
		value: 'SL',
    text: 'Sierra Leone',
    native: 'Sierra Leone',
    phone: '232',
    continent: 'AF',
    capital: 'Freetown',
    currency: 'SLL',
    languages: ['en']
  },
  {
		value: 'SM',
    text: 'San Marino',
    native: 'San Marino',
    phone: '378',
    continent: 'EU',
    capital: 'City of San Marino',
    currency: 'EUR',
    languages: ['it']
  },
  {
		value: 'SN',
    text: 'Senegal',
    native: 'Sénégal',
    phone: '221',
    continent: 'AF',
    capital: 'Dakar',
    currency: 'XOF',
    languages: ['fr']
  },
  {
		value: 'SO',
    text: 'Somalia',
    native: 'Soomaaliya',
    phone: '252',
    continent: 'AF',
    capital: 'Mogadishu',
    currency: 'SOS',
    languages: ['so', 'ar']
  },
  {
		value: 'SR',
    text: 'Suriname',
    native: 'Suriname',
    phone: '597',
    continent: 'SA',
    capital: 'Paramaribo',
    currency: 'SRD',
    languages: ['nl']
  },
  {
		value: 'SS',
    text: 'South Sudan',
    native: 'South Sudan',
    phone: '211',
    continent: 'AF',
    capital: 'Juba',
    currency: 'SSP',
    languages: ['en']
  },
  {
		value: 'ST',
    text: 'São Tomé and Príncipe',
    native: 'São Tomé e Príncipe',
    phone: '239',
    continent: 'AF',
    capital: 'São Tomé',
    currency: 'STN',
    languages: ['pt']
  },
  {
		value: 'SV',
    text: 'El Salvador',
    native: 'El Salvador',
    phone: '503',
    continent: 'NA',
    capital: 'San Salvador',
    currency: 'SVC,USD',
    languages: ['es']
  },
  {
		value: 'SX',
    text: 'Sint Maarten',
    native: 'Sint Maarten',
    phone: '1721',
    continent: 'NA',
    capital: 'Philipsburg',
    currency: 'ANG',
    languages: ['nl', 'en']
  },
  {
		value: 'SY',
    text: 'Syria',
    native: 'سوريا',
    phone: '963',
    continent: 'AS',
    capital: 'Damascus',
    currency: 'SYP',
    languages: ['ar']
  },
  {
		value: 'SZ',
    text: 'Swaziland',
    native: 'Swaziland',
    phone: '268',
    continent: 'AF',
    capital: 'Lobamba',
    currency: 'SZL',
    languages: ['en', 'ss']
  },
  {
		value: 'TC',
    text: 'Turks and Caicos Islands',
    native: 'Turks and Caicos Islands',
    phone: '1649',
    continent: 'NA',
    capital: 'Cockburn Town',
    currency: 'USD',
    languages: ['en']
  },
  {
		value: 'TD',
    text: 'Chad',
    native: 'Tchad',
    phone: '235',
    continent: 'AF',
    capital: "N'Djamena",
    currency: 'XAF',
    languages: ['fr', 'ar']
  },
  {
		value: 'TF',
    text: 'French Southern Territories',
    native: 'Territoire des Terres australes et antarctiques fr',
    phone: '262',
    continent: 'AN',
    capital: 'Port-aux-Français',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'TG',
    text: 'Togo',
    native: 'Togo',
    phone: '228',
    continent: 'AF',
    capital: 'Lomé',
    currency: 'XOF',
    languages: ['fr']
  },
  {
		value: 'TH',
    text: 'Thailand',
    native: 'ประเทศไทย',
    phone: '66',
    continent: 'AS',
    capital: 'Bangkok',
    currency: 'THB',
    languages: ['th']
  },
  {
		value: 'TJ',
    text: 'Tajikistan',
    native: 'Тоҷикистон',
    phone: '992',
    continent: 'AS',
    capital: 'Dushanbe',
    currency: 'TJS',
    languages: ['tg', 'ru']
  },
  {
		value: 'TK',
    text: 'Tokelau',
    native: 'Tokelau',
    phone: '690',
    continent: 'OC',
    capital: 'Fakaofo',
    currency: 'NZD',
    languages: ['en']
  },
  {
		value: 'TL',
    text: 'East Timor',
    native: 'Timor-Leste',
    phone: '670',
    continent: 'OC',
    capital: 'Dili',
    currency: 'USD',
    languages: ['pt']
  },
  {
		value: 'TM',
    text: 'Turkmenistan',
    native: 'Türkmenistan',
    phone: '993',
    continent: 'AS',
    capital: 'Ashgabat',
    currency: 'TMT',
    languages: ['tk', 'ru']
  },
  {
		value: 'TN',
    text: 'Tunisia',
    native: 'تونس',
    phone: '216',
    continent: 'AF',
    capital: 'Tunis',
    currency: 'TND',
    languages: ['ar']
  },
  {
		value: 'TO',
    text: 'Tonga',
    native: 'Tonga',
    phone: '676',
    continent: 'OC',
    capital: "Nuku'alofa",
    currency: 'TOP',
    languages: ['en', 'to']
  },
  {
		value: 'TR',
    text: 'Turkey',
    native: 'Türkiye',
    phone: '90',
    continent: 'AS',
    capital: 'Ankara',
    currency: 'TRY',
    languages: ['tr']
  },
  {
		value: 'TT',
    text: 'Trinidad and Tobago',
    native: 'Trinidad and Tobago',
    phone: '1868',
    continent: 'NA',
    capital: 'Port of Spain',
    currency: 'TTD',
    languages: ['en']
  },
  {
		value: 'TV',
    text: 'Tuvalu',
    native: 'Tuvalu',
    phone: '688',
    continent: 'OC',
    capital: 'Funafuti',
    currency: 'AUD',
    languages: ['en']
  },
  {
		value: 'TW',
    text: 'Taiwan',
    native: '臺灣',
    phone: '886',
    continent: 'AS',
    capital: 'Taipei',
    currency: 'TWD',
    languages: ['zh']
  },
  {
		value: 'TZ',
    text: 'Tanzania',
    native: 'Tanzania',
    phone: '255',
    continent: 'AF',
    capital: 'Dodoma',
    currency: 'TZS',
    languages: ['sw', 'en']
  },
  {
		value: 'UA',
    text: 'Ukraine',
    native: 'Україна',
    phone: '380',
    continent: 'EU',
    capital: 'Kyiv',
    currency: 'UAH',
    languages: ['uk']
  },
  {
		value: 'UG',
    text: 'Uganda',
    native: 'Uganda',
    phone: '256',
    continent: 'AF',
    capital: 'Kampala',
    currency: 'UGX',
    languages: ['en', 'sw']
  },
  {
		value: 'UM',
    text: 'U.S. Minor Outlying Islands',
    native: 'United States Minor Outlying Islands',
    phone: '1',
    continent: 'OC',
    capital: '',
    currency: 'USD',
    languages: ['en']
  },
  {
		value: 'US',
    text: 'United States',
    native: 'United States',
    phone: '1',
    continent: 'NA',
    capital: 'Washington D.C.',
    currency: 'USD,USN,USS',
    languages: ['en']
  },
  {
		value: 'UY',
    text: 'Uruguay',
    native: 'Uruguay',
    phone: '598',
    continent: 'SA',
    capital: 'Montevideo',
    currency: 'UYI,UYU',
    languages: ['es']
  },
  {
		value: 'UZ',
    text: 'Uzbekistan',
    native: 'O‘zbekiston',
    phone: '998',
    continent: 'AS',
    capital: 'Tashkent',
    currency: 'UZS',
    languages: ['uz', 'ru']
  },
  {
		value: 'VA',
    text: 'Vatican City',
    native: 'Vaticano',
    phone: '379',
    continent: 'EU',
    capital: 'Vatican City',
    currency: 'EUR',
    languages: ['it', 'la']
  },
  {
		value: 'VC',
    text: 'Saint Vincent and the Grenadines',
    native: 'Saint Vincent and the Grenadines',
    phone: '1784',
    continent: 'NA',
    capital: 'Kingstown',
    currency: 'XCD',
    languages: ['en']
  },
  {
		value: 'VE',
    text: 'Venezuela',
    native: 'Venezuela',
    phone: '58',
    continent: 'SA',
    capital: 'Caracas',
    currency: 'VES',
    languages: ['es']
  },
  {
		value: 'VG',
    text: 'British Virgin Islands',
    native: 'British Virgin Islands',
    phone: '1284',
    continent: 'NA',
    capital: 'Road Town',
    currency: 'USD',
    languages: ['en']
  },
  {
		value: 'VI',
    text: 'U.S. Virgin Islands',
    native: 'United States Virgin Islands',
    phone: '1340',
    continent: 'NA',
    capital: 'Charlotte Amalie',
    currency: 'USD',
    languages: ['en']
  },
  {
		value: 'VN',
    text: 'Vietnam',
    native: 'Việt Nam',
    phone: '84',
    continent: 'AS',
    capital: 'Hanoi',
    currency: 'VND',
    languages: ['vi']
  },
  {
		value: 'VU',
    text: 'Vanuatu',
    native: 'Vanuatu',
    phone: '678',
    continent: 'OC',
    capital: 'Port Vila',
    currency: 'VUV',
    languages: ['bi', 'en', 'fr']
  },
  {
		value: 'WF',
    text: 'Wallis and Futuna',
    native: 'Wallis et Futuna',
    phone: '681',
    continent: 'OC',
    capital: 'Mata-Utu',
    currency: 'XPF',
    languages: ['fr']
  },
  {
		value: 'WS',
    text: 'Samoa',
    native: 'Samoa',
    phone: '685',
    continent: 'OC',
    capital: 'Apia',
    currency: 'WST',
    languages: ['sm', 'en']
  },
  {
		value: 'XK',
    text: 'Kosovo',
    native: 'Republika e Kosovës',
    phone: '377,381,383,386',
    continent: 'EU',
    capital: 'Pristina',
    currency: 'EUR',
    languages: ['sq', 'sr']
  },
  {
		value: 'YE',
    text: 'Yemen',
    native: 'اليَمَن',
    phone: '967',
    continent: 'AS',
    capital: "Sana'a",
    currency: 'YER',
    languages: ['ar']
  },
  {
		value: 'YT',
    text: 'Mayotte',
    native: 'Mayotte',
    phone: '262',
    continent: 'AF',
    capital: 'Mamoudzou',
    currency: 'EUR',
    languages: ['fr']
  },
  {
		value: 'ZA',
    text: 'South Africa',
    native: 'South Africa',
    phone: '27',
    continent: 'AF',
    capital: 'Pretoria',
    currency: 'ZAR',
    languages: ['af', 'en', 'nr', 'st', 'ss', 'tn', 'ts', 've', 'xh', 'zu']
  },
  {
		value: 'ZM',
    text: 'Zambia',
    native: 'Zambia',
    phone: '260',
    continent: 'AF',
    capital: 'Lusaka',
    currency: 'ZMW',
    languages: ['en']
  },
  {
		value: 'ZW',
    text: 'Zimbabwe',
    native: 'Zimbabwe',
    phone: '263',
    continent: 'AF',
    capital: 'Harare',
    currency: 'USD,ZAR,BWP,GBP,AUD,CNY,INR,JPY',
    languages: ['en', 'sn', 'nd']
  }
];
