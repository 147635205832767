/* eslint no-unused-vars: 0 */
import api from '@/config/api';
import axios from '@/axios';
import { encode } from '@/plugins/helper';
import Paging from '@/entity/Paging';

const actions = {

  async list ({ commit }, { params = {}, paging = {}, dontCommit = false } = {}) {
    const query = {
      ...params,
      ...(Object.keys(paging).length > 0 ? Paging.query(paging) : {}),
    }
    const url = `${api.baseUrl}/templates?${encode(query)}`;
    return await axios.get(url)
      .then(({ data }) => {
        if (!dontCommit) commit('SET_LIST', data);
        return data;
      });
  },

  async item ({ commit }, { id } = {}) {
    const url = `${api.baseUrl}/templates/${id}`;
    return await axios.get(url)
      .then(({ data }) => {
        commit('SET_ITEM', data);
        return data;
      });
  },

  async update ({ commit, dispatch }, { data } = {}) {
    const url = `${api.baseUrl}/templates`;
    return await axios.post(url, data)
      .then(async ({ data }) => {
        commit('SET_ITEM', data);
        await dispatch('list');
        return data;
      });
  },

  async delete({ commit }, id = null) {
    if (!id) return null;
    const url = `${api.baseUrl}/templates/${id}`;
    return await axios.delete(url)
      .then(async ({ data }) => {
        console.log(data)
        return data;
      });
  },
  
  requestParams({ commit }, params) {
    commit('REQUEST_PARAMS', params);
  }
}

export default actions
