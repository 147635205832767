import Me from '@/entity/Me';
import Tenant from '@/entity/Tenant';
const mutations = {
  SET_ME (state, payload) {
    state.me = new Me(payload);
  },
  SET_ACCOUNT_PLAN (state, payload) {
    state.account.plan = payload
  },
  SET_TENANT (state, payload) {
    state.tenant = new Tenant(payload);
  },
  SET_TIMEZONES (state, payload) {
    state.timezones = payload
  },
  SET_PLANS (state, payload) {
    state.plans = payload
  },
  SET_TEAM (state, payload) {
    state.team = payload
  }
}

export default mutations

