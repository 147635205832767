/* eslint no-unused-vars:0 */
require('moment');
import moment from 'moment-timezone';
import timezones from './resources/timezones';
import languages from './resources/languages';
import countries from './resources/countries';
import prettyCron from '@/plugins/resources/prettycron';
import permissions from '../permissions';
import { urlPath } from './helper';

import store from '@/store/store';

import EmailAlert from '@/entity/EmailAlert';

export default {
  install (Vue) {
    Vue.prototype.$moment = moment;
    Vue.prototype.$timezones = timezones;
    Vue.prototype.$languages = languages;
    Vue.prototype.$countries = countries;
    Vue.prototype.$eventBus = new Vue();

    Array.prototype.groupBy = function (key, config = {}) {
      return this.reduce((objectsByKeyValue, obj) => {
        const value = eval(`obj.${key}`);
        objectsByKeyValue[value] = new Array((objectsByKeyValue[value] || []).concat(obj));
        if (config.inject) {
          config.inject.forEach(key => {
            if (!objectsByKeyValue[value][key]) objectsByKeyValue[value][key] = obj[key];
          });
        }
        return objectsByKeyValue;
      }, {})
    }

    /**
     * @global $randomId
     * @param {number} [preset] - The preset to be used with randomised ID
     */
    Vue.prototype.$randomId = preset => {
      let id = `_${Math.random().toString(36).substr(2, 9)}`;
      if (preset) id = preset + id;
      return id;
    };

    /**
     * @global $randomisedVsSelectAutofill
     * @param {component} [component] - The components reference where the input is belong
     */
    Vue.prototype.$randomisedVsSelectAutofill = component => {
      if (component)
        component.$refs.inputselect.setAttribute(
          'autocomplete',
          component.$randomId()
        );
    };


    /**
     * @global $debounce
     */
    Vue.prototype.$debounce = function (fn, delay) {
      var timeoutID = null
      return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
          fn.apply(that, args)
        }, delay)
      }
    };

    /**
     * @global $hasPermission
     */
    Vue.prototype.$hasPermission = function (param) {
      //console.log('enter hasPermission ' + param, permissions);
      if (!param) false;
      return permissions.has(param);
    };

    Vue.prototype.$clearTooltip = function () {
      const Stickedtooltips = document.querySelectorAll(".vs-tooltip");
      for (const tooltip of Stickedtooltips) { tooltip.remove(); }
    }

    Vue.mixin({
      data () {
        return {
          isMounted: false
        }
      },
      beforeCreate () {
        this.$todo = () => {
          this.$vs.notify({
            color: 'danger',
            title: 'Todo',
            text: "This feature hasn't been done."
          });
        };

        try {
          if (!this.$vxPopup) this.$vxPopup = this.$root.$main.$refs.vxPopup;
        } catch (error) {
          this.$vxPopup = null;
        }

        try {
          if (!this.$vxSlideIn) this.$vxSlideIn = this.$root.$main.$refs.vxSlideIn;
        } catch (error) {
          this.$vxSlideIn = null;
        }

        try {
          if (this.$store.state.accounts.me.contact.timeZone) moment.tz.setDefault(this.$store.getters['accounts/userTimezone']);
        } catch (e) {
          moment.tz.setDefault();
        }
      },
      created () { },
      destroyed () { },
      mounted () {
        this.$nextTick(() => this.isMounted = true);
      }
    });

    Vue.directive('init', {
      bind: function (el, binding, vnode) {
        eval(
          `vnode.context.${binding.expression.replace('this', 'vnode.context')}`
        );
      }
    });

    /**
     * @function currency
     * @param {number} [value] - The value to be converted to currency
     * @arg {string} [currency=NZD] - The currency type
     * @arg {number} [fraction=2] - number of decimal point
     */
    Vue.filter('currency', (value, currency, fraction) => {
      if (typeof value !== 'number') {
        return value;
      }
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'NZD',
        minimumFractionDigits: fraction || 2
      });
      return formatter.format(value);
    })

    Vue.filter('moment', (value, format, tz) => {
      const defaultFormat = 'DD MMM, YYYY HH:mm';
      const momentTz = moment.utc(value).tz(tz || store.getters['accounts/userTimezone']);
      if (!format) return momentTz.format(defaultFormat);
      if (format === 'date') return momentTz.format('DD MMM, YYYY');
      if (format === 'time') return momentTz.format('HH:mm');
      if (format === 'timestamp') return momentTz.format('HH:mm:ss');
      return momentTz.format(format || defaultFormat);
    });

    Vue.filter('fromNow', (value) => {
      return moment(value).fromNow();
    });

    Vue.filter('urlPath', (obj) => urlPath(obj));

    Vue.filter('humanDescription', (cronExp, tz) => {
      if (!cronExp) return 'Invalid CRON Expression';
      try {
        const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek] = cronExp.split(/\s/);
        const tzHours = moment.utc(hours, 'H').tz(tz || store.getters['accounts/userTimezone']).hour();
        return prettyCron(`${minutes} ${tzHours} ${dayOfMonth} ${month} ${dayOfWeek}`);
      } catch (error) {
        return 'Invalid CRON Expression'
      }
    });

    Vue.filter('alertDescription', item => {
      const { severityOptions } = EmailAlert
      let str = 'Send to';
      if ((item.contacts || []).length === 0) str += ' Everyone';
      else {
        const { contacts } = item;
        for (let i = 0; i < contacts.length; i++) {
          const member = contacts[i];
          str += `${i > 0 ? ', ' : ' '}${member.firstName}`
        }
      }
      if (item.changePercent > 0) str += ` when changed by ${item.changePercent}%${item.severity > 0 ? ' and' : ''}`;
      if (item.severity > 0) str += ` when test fails with ${(severityOptions.find(o => o.value === item.severity) || {}).text} error`;
      return str
    });

    Vue.filter('colorStatus', value => {
      if (value === 'active' || value === true) return 'success';
      if (value === 'disabled' || value === false) return 'danger';
      if (value === 'warning') return 'warning';
      return '';
    });

    Vue.filter('filter_tags', function (value) {
      return value.replace(/<\/?[^>]+(>|$)/g, '')
    })
  }
};
