<template>
  <div class="img-container">
    <vs-avatar icon-pack="feather" icon="icon-chevron-left" @click="(active > 0) ? active-- : null;" v-bind:style="{ display: (active > 0) ? 'inline' : 'none' }"  />
    <div class="inner-img-container">      
      <img :src="srcs[active]" class="max-w-full" />      
    </div>
    <vs-avatar icon-pack="feather" icon="icon-chevron-right" @click="(srcs.length - 1 !== active) ? active++ : null;" v-bind:style="{ display: (active < srcs.length-1) ? 'inline' : 'none' }" />
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    srcs: Array
  },
  data() {
    return {
      active: this.index
    }
  }
}
</script>

