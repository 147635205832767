<template>
  <vs-popup v-bind="attrs" @close="onClose" @cancel="onClose" :active="popupActive">
    <component ref="component" :is="component" v-bind="config" @close="close" />
  </vs-popup>
</template>

<script>
// Components
import CalendarHeatMap from '@/components/CalendarHeatMap.vue';
import BlockImage from '@/components/generic/Image.vue';
import ImageGallery from '@/components/generic/ImageGallery.vue';
import CookiesTable from '@/components/generic/CookiesTable.vue';

export default {
  inheritAttrs: false,
  name: 'VxPopup',
  computed: {
    component() {
      try {
        return this.config.component;
      } catch (error) {
        return null;
      }
    }
  },
  data() {
    return {
      attrs: {},
      config: {},
      popupActive: false
    };
  },
  methods: {
    async open({ attrs, config }) {
      this.config = config;
      this.attrs = attrs;
      this.popupActive = true;

      return await new Promise(resolve =>
        setTimeout(() => {
          resolve(this.$refs.component);
        }, 120)
      );
    },
    onClose() {
      //this.$refs.component.$emit('cancel');
      this.close();
    },
    close() {
      this.popupActive = false;
    }
  },
  watch: {
    popupActive(open) {
      if (!open) this.config = {};
    }
  },
  components: {
    CalendarHeatMap,
    BlockImage,
    ImageGallery,
    CookiesTable
  }
};
</script>
