// axios
import axios from 'axios'

const baseURL = ''

export const instance = axios.create({
  baseURL
  // You can add your headers here
})


const MockAdapter = require('axios-mock-adapter')
const mock = new MockAdapter(instance) // This sets the mock adapter on the default instance

export default mock
