import TestSuite from '@/entity/TestSuite';
const mutations = {
  SET_LIST ( state, payload ) {
    state.list = payload;
  },
  SET_ITEM ( state, payload ) {
    state.item = { ...state.item, ...payload };
  },
  REQUEST_PARAMS (state, payload) {
    state.requestParams = payload;
  },
  STATUS_PAUSE ( state ) {
    state.item.status = 'paused';
  },
  STATUS_ACTIVATE ( state ) {
    state.item.status = 'active';
  },
  SET_TESTS ( state, payload ) {
    state.tests = payload;
  },
  SET_TEST_ITEM ( state, payload ) {
    state.testItem = new TestSuite(payload);
  },
}

export default mutations
