export default [
  {
		value: 'ab',
    text: 'Abkhaz',
    nativeName: 'аҧсуа'
  },
  {
		value: 'aa',
    text: 'Afar',
    nativeName: 'Afaraf'
  },
  {
		value: 'af',
    text: 'Afrikaans',
    nativeName: 'Afrikaans'
  },
  {
		value: 'ak',
    text: 'Akan',
    nativeName: 'Akan'
  },
  {
		value: 'sq',
    text: 'Albanian',
    nativeName: 'Shqip'
  },
  {
		value: 'am',
    text: 'Amharic',
    nativeName: 'አማርኛ'
  },
  {
		value: 'ar',
    text: 'Arabic',
    nativeName: 'العربية'
  },
  {
		value: 'an',
    text: 'Aragonese',
    nativeName: 'Aragonés'
  },
  {
		value: 'hy',
    text: 'Armenian',
    nativeName: 'Հայերեն'
  },
  {
		value: 'as',
    text: 'Assamese',
    nativeName: 'অসমীয়া'
  },
  {
		value: 'av',
    text: 'Avaric',
    nativeName: 'авар мацӀ, магӀарул мацӀ'
  },
  {
		value: 'ae',
    text: 'Avestan',
    nativeName: 'avesta'
  },
  {
		value: 'ay',
    text: 'Aymara',
    nativeName: 'aymar aru'
  },
  {
		value: 'az',
    text: 'Azerbaijani',
    nativeName: 'azərbaycan dili'
  },
  {
		value: 'bm',
    text: 'Bambara',
    nativeName: 'bamanankan'
  },
  {
		value: 'ba',
    text: 'Bashkir',
    nativeName: 'башҡорт теле'
  },
  {
		value: 'eu',
    text: 'Basque',
    nativeName: 'euskara, euskera'
  },
  {
		value: 'be',
    text: 'Belarusian',
    nativeName: 'Беларуская'
  },
  {
		value: 'bn',
    text: 'Bengali',
    nativeName: 'বাংলা'
  },
  {
		value: 'bh',
    text: 'Bihari',
    nativeName: 'भोजपुरी'
  },
  {
		value: 'bi',
    text: 'Bislama',
    nativeName: 'Bislama'
  },
  {
		value: 'bs',
    text: 'Bosnian',
    nativeName: 'bosanski jezik'
  },
  {
		value: 'br',
    text: 'Breton',
    nativeName: 'brezhoneg'
  },
  {
		value: 'bg',
    text: 'Bulgarian',
    nativeName: 'български език'
  },
  {
		value: 'my',
    text: 'Burmese',
    nativeName: 'ဗမာစာ'
  },
  {
		value: 'ca',
    text: 'Catalan; Valencian',
    nativeName: 'Català'
  },
  {
		value: 'ch',
    text: 'Chamorro',
    nativeName: 'Chamoru'
  },
  {
		value: 'ce',
    text: 'Chechen',
    nativeName: 'нохчийн мотт'
  },
  {
		value: 'ny',
    text: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja'
  },
  {
		value: 'zh',
    text: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語'
  },
  {
		value: 'cv',
    text: 'Chuvash',
    nativeName: 'чӑваш чӗлхи'
  },
  {
		value: 'kw',
    text: 'Cornish',
    nativeName: 'Kernewek'
  },
  {
		value: 'co',
    text: 'Corsican',
    nativeName: 'corsu, lingua corsa'
  },
  {
		value: 'cr',
    text: 'Cree',
    nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ'
  },
  {
		value: 'hr',
    text: 'Croatian',
    nativeName: 'hrvatski'
  },
  {
		value: 'cs',
    text: 'Czech',
    nativeName: 'česky, čeština'
  },
  {
		value: 'da',
    text: 'Danish',
    nativeName: 'dansk'
  },
  {
		value: 'dv',
    text: 'Divehi; Dhivehi; Maldivian;',
    nativeName: 'ދިވެހި'
  },
  {
		value: 'nl',
    text: 'Dutch',
    nativeName: 'Nederlands, Vlaams'
  },
  {
		value: 'en',
    text: 'English',
    nativeName: 'English'
  },
  {
		value: 'eo',
    text: 'Esperanto',
    nativeName: 'Esperanto'
  },
  {
		value: 'et',
    text: 'Estonian',
    nativeName: 'eesti, eesti keel'
  },
  {
		value: 'ee',
    text: 'Ewe',
    nativeName: 'Eʋegbe'
  },
  {
		value: 'fo',
    text: 'Faroese',
    nativeName: 'føroyskt'
  },
  {
		value: 'fj',
    text: 'Fijian',
    nativeName: 'vosa Vakaviti'
  },
  {
		value: 'fi',
    text: 'Finnish',
    nativeName: 'suomi, suomen kieli'
  },
  {
		value: 'fr',
    text: 'French',
    nativeName: 'français, langue française'
  },
  {
		value: 'ff',
    text: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular'
  },
  {
		value: 'gl',
    text: 'Galician',
    nativeName: 'Galego'
  },
  {
		value: 'ka',
    text: 'Georgian',
    nativeName: 'ქართული'
  },
  {
		value: 'de',
    text: 'German',
    nativeName: 'Deutsch'
  },
  {
		value: 'el',
    text: 'Greek, Modern',
    nativeName: 'Ελληνικά'
  },
  {
		value: 'gn',
    text: 'Guaraní',
    nativeName: 'Avañeẽ'
  },
  {
		value: 'gu',
    text: 'Gujarati',
    nativeName: 'ગુજરાતી'
  },
  {
		value: 'ht',
    text: 'Haitian; Haitian Creole',
    nativeName: 'Kreyòl ayisyen'
  },
  {
		value: 'ha',
    text: 'Hausa',
    nativeName: 'Hausa, هَوُسَ'
  },
  {
		value: 'he',
    text: 'Hebrew (modern)',
    nativeName: 'עברית'
  },
  {
		value: 'hz',
    text: 'Herero',
    nativeName: 'Otjiherero'
  },
  {
		value: 'hi',
    text: 'Hindi',
    nativeName: 'हिन्दी, हिंदी'
  },
  {
		value: 'ho',
    text: 'Hiri Motu',
    nativeName: 'Hiri Motu'
  },
  {
		value: 'hu',
    text: 'Hungarian',
    nativeName: 'Magyar'
  },
  {
		value: 'ia',
    text: 'Interlingua',
    nativeName: 'Interlingua'
  },
  {
		value: 'id',
    text: 'Indonesian',
    nativeName: 'Bahasa Indonesia'
  },
  {
		value: 'ie',
    text: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII'
  },
  {
		value: 'ga',
    text: 'Irish',
    nativeName: 'Gaeilge'
  },
  {
		value: 'ig',
    text: 'Igbo',
    nativeName: 'Asụsụ Igbo'
  },
  {
		value: 'ik',
    text: 'Inupiaq',
    nativeName: 'Iñupiaq, Iñupiatun'
  },
  {
		value: 'io',
    text: 'Ido',
    nativeName: 'Ido'
  },
  {
		value: 'is',
    text: 'Icelandic',
    nativeName: 'Íslenska'
  },
  {
		value: 'it',
    text: 'Italian',
    nativeName: 'Italiano'
  },
  {
		value: 'iu',
    text: 'Inuktitut',
    nativeName: 'ᐃᓄᒃᑎᑐᑦ'
  },
  {
		value: 'ja',
    text: 'Japanese',
    nativeName: '日本語 (にほんご／にっぽんご)'
  },
  {
		value: 'jv',
    text: 'Javanese',
    nativeName: 'basa Jawa'
  },
  {
		value: 'kl',
    text: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii'
  },
  {
		value: 'kn',
    text: 'Kannada',
    nativeName: 'ಕನ್ನಡ'
  },
  {
		value: 'kr',
    text: 'Kanuri',
    nativeName: 'Kanuri'
  },
  {
		value: 'ks',
    text: 'Kashmiri',
    nativeName: 'कश्मीरी, كشميري‎'
  },
  {
		value: 'kk',
    text: 'Kazakh',
    nativeName: 'Қазақ тілі'
  },
  {
		value: 'km',
    text: 'Khmer',
    nativeName: 'ភាសាខ្មែរ'
  },
  {
		value: 'ki',
    text: 'Kikuyu, Gikuyu',
    nativeName: 'Gĩkũyũ'
  },
  {
		value: 'rw',
    text: 'Kinyarwanda',
    nativeName: 'Ikinyarwanda'
  },
  {
		value: 'ky',
    text: 'Kirghiz, Kyrgyz',
    nativeName: 'кыргыз тили'
  },
  {
		value: 'kv',
    text: 'Komi',
    nativeName: 'коми кыв'
  },
  {
		value: 'kg',
    text: 'Kongo',
    nativeName: 'KiKongo'
  },
  {
		value: 'ko',
    text: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)'
  },
  {
		value: 'ku',
    text: 'Kurdish',
    nativeName: 'Kurdî, كوردی‎'
  },
  {
		value: 'kj',
    text: 'Kwanyama, Kuanyama',
    nativeName: 'Kuanyama'
  },
  {
		value: 'la',
    text: 'Latin',
    nativeName: 'latine, lingua latina'
  },
  {
		value: 'lb',
    text: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch'
  },
  {
		value: 'lg',
    text: 'Luganda',
    nativeName: 'Luganda'
  },
  {
		value: 'li',
    text: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs'
  },
  {
		value: 'ln',
    text: 'Lingala',
    nativeName: 'Lingála'
  },
  {
		value: 'lo',
    text: 'Lao',
    nativeName: 'ພາສາລາວ'
  },
  {
		value: 'lt',
    text: 'Lithuanian',
    nativeName: 'lietuvių kalba'
  },
  {
		value: 'lu',
    text: 'Luba-Katanga',
    nativeName: ''
  },
  {
		value: 'lv',
    text: 'Latvian',
    nativeName: 'latviešu valoda'
  },
  {
		value: 'gv',
    text: 'Manx',
    nativeName: 'Gaelg, Gailck'
  },
  {
		value: 'mk',
    text: 'Macedonian',
    nativeName: 'македонски јазик'
  },
  {
		value: 'mg',
    text: 'Malagasy',
    nativeName: 'Malagasy fiteny'
  },
  {
		value: 'ms',
    text: 'Malay',
    nativeName: 'bahasa Melayu, بهاس ملايو‎'
  },
  {
		value: 'ml',
    text: 'Malayalam',
    nativeName: 'മലയാളം'
  },
  {
		value: 'mt',
    text: 'Maltese',
    nativeName: 'Malti'
  },
  {
		value: 'mi',
    text: 'Māori',
    nativeName: 'te reo Māori'
  },
  {
		value: 'mr',
    text: 'Marathi (Marāṭhī)',
    nativeName: 'मराठी'
  },
  {
		value: 'mh',
    text: 'Marshallese',
    nativeName: 'Kajin M̧ajeļ'
  },
  {
		value: 'mn',
    text: 'Mongolian',
    nativeName: 'монгол'
  },
  {
		value: 'na',
    text: 'Nauru',
    nativeName: 'Ekakairũ Naoero'
  },
  {
		value: 'nv',
    text: 'Navajo, Navaho',
    nativeName: 'Diné bizaad, Dinékʼehǰí'
  },
  {
		value: 'nb',
    text: 'Norwegian Bokmål',
    nativeName: 'Norsk bokmål'
  },
  {
		value: 'nd',
    text: 'North Ndebele',
    nativeName: 'isiNdebele'
  },
  {
		value: 'ne',
    text: 'Nepali',
    nativeName: 'नेपाली'
  },
  {
		value: 'ng',
    text: 'Ndonga',
    nativeName: 'Owambo'
  },
  {
		value: 'nn',
    text: 'Norwegian Nynorsk',
    nativeName: 'Norsk nynorsk'
  },
  {
		value: 'no',
    text: 'Norwegian',
    nativeName: 'Norsk'
  },
  {
		value: 'ii',
    text: 'Nuosu',
    nativeName: 'ꆈꌠ꒿ Nuosuhxop'
  },
  {
		value: 'nr',
    text: 'South Ndebele',
    nativeName: 'isiNdebele'
  },
  {
		value: 'oc',
    text: 'Occitan',
    nativeName: 'Occitan'
  },
  {
		value: 'oj',
    text: 'Ojibwe, Ojibwa',
    nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ'
  },
  {
		value: 'cu',
    text:
      'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ'
  },
  {
		value: 'om',
    text: 'Oromo',
    nativeName: 'Afaan Oromoo'
  },
  {
		value: 'or',
    text: 'Oriya',
    nativeName: 'ଓଡ଼ିଆ'
  },
  {
		value: 'os',
    text: 'Ossetian, Ossetic',
    nativeName: 'ирон æвзаг'
  },
  {
		value: 'pa',
    text: 'Panjabi, Punjabi',
    nativeName: 'ਪੰਜਾਬੀ, پنجابی‎'
  },
  {
		value: 'pi',
    text: 'Pāli',
    nativeName: 'पाऴि'
  },
  {
		value: 'fa',
    text: 'Persian',
    nativeName: 'فارسی'
  },
  {
		value: 'pl',
    text: 'Polish',
    nativeName: 'polski'
  },
  {
		value: 'ps',
    text: 'Pashto, Pushto',
    nativeName: 'پښتو'
  },
  {
		value: 'pt',
    text: 'Portuguese',
    nativeName: 'Português'
  },
  {
		value: 'qu',
    text: 'Quechua',
    nativeName: 'Runa Simi, Kichwa'
  },
  {
		value: 'rm',
    text: 'Romansh',
    nativeName: 'rumantsch grischun'
  },
  {
		value: 'rn',
    text: 'Kirundi',
    nativeName: 'kiRundi'
  },
  {
		value: 'ro',
    text: 'Romanian, Moldavian, Moldovan',
    nativeName: 'română'
  },
  {
		value: 'ru',
    text: 'Russian',
    nativeName: 'русский язык'
  },
  {
		value: 'sa',
    text: 'Sanskrit (Saṁskṛta)',
    nativeName: 'संस्कृतम्'
  },
  {
		value: 'sc',
    text: 'Sardinian',
    nativeName: 'sardu'
  },
  {
		value: 'sd',
    text: 'Sindhi',
    nativeName: 'सिन्धी, سنڌي، سندھی‎'
  },
  {
		value: 'se',
    text: 'Northern Sami',
    nativeName: 'Davvisámegiella'
  },
  {
		value: 'sm',
    text: 'Samoan',
    nativeName: 'gagana faa Samoa'
  },
  {
		value: 'sg',
    text: 'Sango',
    nativeName: 'yângâ tî sängö'
  },
  {
		value: 'sr',
    text: 'Serbian',
    nativeName: 'српски језик'
  },
  {
		value: 'gd',
    text: 'Scottish Gaelic; Gaelic',
    nativeName: 'Gàidhlig'
  },
  {
		value: 'sn',
    text: 'Shona',
    nativeName: 'chiShona'
  },
  {
		value: 'si',
    text: 'Sinhala, Sinhalese',
    nativeName: 'සිංහල'
  },
  {
		value: 'sk',
    text: 'Slovak',
    nativeName: 'slovenčina'
  },
  {
		value: 'sl',
    text: 'Slovene',
    nativeName: 'slovenščina'
  },
  {
		value: 'so',
    text: 'Somali',
    nativeName: 'Soomaaliga, af Soomaali'
  },
  {
		value: 'st',
    text: 'Southern Sotho',
    nativeName: 'Sesotho'
  },
  {
		value: 'es',
    text: 'Spanish; Castilian',
    nativeName: 'español, castellano'
  },
  {
		value: 'su',
    text: 'Sundanese',
    nativeName: 'Basa Sunda'
  },
  {
		value: 'sw',
    text: 'Swahili',
    nativeName: 'Kiswahili'
  },
  {
		value: 'ss',
    text: 'Swati',
    nativeName: 'SiSwati'
  },
  {
		value: 'sv',
    text: 'Swedish',
    nativeName: 'svenska'
  },
  {
		value: 'ta',
    text: 'Tamil',
    nativeName: 'தமிழ்'
  },
  {
		value: 'te',
    text: 'Telugu',
    nativeName: 'తెలుగు'
  },
  {
		value: 'tg',
    text: 'Tajik',
    nativeName: 'тоҷикӣ, toğikī, تاجیکی‎'
  },
  {
		value: 'th',
    text: 'Thai',
    nativeName: 'ไทย'
  },
  {
		value: 'ti',
    text: 'Tigrinya',
    nativeName: 'ትግርኛ'
  },
  {
		value: 'bo',
    text: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག'
  },
  {
		value: 'tk',
    text: 'Turkmen',
    nativeName: 'Türkmen, Түркмен'
  },
  {
		value: 'tl',
    text: 'Tagalog',
    nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔'
  },
  {
		value: 'tn',
    text: 'Tswana',
    nativeName: 'Setswana'
  },
  {
		value: 'to',
    text: 'Tonga (Tonga Islands)',
    nativeName: 'faka Tonga'
  },
  {
		value: 'tr',
    text: 'Turkish',
    nativeName: 'Türkçe'
  },
  {
		value: 'ts',
    text: 'Tsonga',
    nativeName: 'Xitsonga'
  },
  {
		value: 'tt',
    text: 'Tatar',
    nativeName: 'татарча, tatarça, تاتارچا‎'
  },
  {
		value: 'tw',
    text: 'Twi',
    nativeName: 'Twi'
  },
  {
		value: 'ty',
    text: 'Tahitian',
    nativeName: 'Reo Tahiti'
  },
  {
		value: 'ug',
    text: 'Uighur, Uyghur',
    nativeName: 'Uyƣurqə, ئۇيغۇرچە‎'
  },
  {
		value: 'uk',
    text: 'Ukrainian',
    nativeName: 'українська'
  },
  {
		value: 'ur',
    text: 'Urdu',
    nativeName: 'اردو'
  },
  {
		value: 'uz',
    text: 'Uzbek',
    nativeName: 'zbek, Ўзбек, أۇزبېك‎'
  },
  {
		value: 've',
    text: 'Venda',
    nativeName: 'Tshivenḓa'
  },
  {
		value: 'vi',
    text: 'Vietnamese',
    nativeName: 'Tiếng Việt'
  },
  {
		value: 'vo',
    text: 'Volapük',
    nativeName: 'Volapük'
  },
  {
		value: 'wa',
    text: 'Walloon',
    nativeName: 'Walon'
  },
  {
		value: 'cy',
    text: 'Welsh',
    nativeName: 'Cymraeg'
  },
  {
		value: 'wo',
    text: 'Wolof',
    nativeName: 'Wollof'
  },
  {
		value: 'fy',
    text: 'Western Frisian',
    nativeName: 'Frysk'
  },
  {
		value: 'xh',
    text: 'Xhosa',
    nativeName: 'isiXhosa'
  },
  {
		value: 'yi',
    text: 'Yiddish',
    nativeName: 'ייִדיש'
  },
  {
		value: 'yo',
    text: 'Yoruba',
    nativeName: 'Yorùbá'
  },
  {
		value: 'za',
    text: 'Zhuang, Chuang',
    nativeName: 'Saɯ cueŋƅ, Saw cuengh'
  }
];
