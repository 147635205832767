export default class EmailAlert {
  constructor(o) {
    const EmailAlert = o || {};
    this.id = EmailAlert.id || undefined;
    this.changePercent = EmailAlert.changePercent;
    this.contacts = EmailAlert.contacts || [];
    this.enabled = EmailAlert.enabled || false;
    this.entity = EmailAlert.entity || undefined;
    this.severity = EmailAlert.severity || 0;
    this.maxDownloadTimeMs = EmailAlert.maxDownloadTimeMs || 0;
    this.maxCaptureTimeMs = EmailAlert.maxCaptureTimeMs || 0;
  }

  static severityOptions = [{
    value: 0,
    text: 'N/a'
  }, {
    value: 1,
    text: 'Critical'
  }, {
    value: 2,
    text: 'Major'
  }, {
    value: 3,
    text: 'Minor'
  }, {
    value: 4,
    text: 'Trivial'
  }]
}
