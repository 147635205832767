const mutations = {
  SET_LIST ( state, payload ) {
    state.list = payload;
  },
  SET_ITEM ( state, payload ) {
    state.item = payload;
  },
  SET_CARDS ( state, payload ) {
    state.cards = payload;
  },
  SET_UPGRADE (state , payload) {
    state.upgrade = payload;
  },
  SET_INVOICES ( state, payload ) {
    state.invoices = payload;
  }

}

export default mutations

