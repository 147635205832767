<template>
  <section class="vx-data-list">
    <vs-table
      class="px-0"
      ref="table"
      :value="selected"
      :max-items="itemsPerPage"
      :data="items"
      v-bind="attrs"
      @search="handleSearch"
      :sst="true"
      v-on="listeners"
      @input="emitSelected"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ACTION - DROPDOWN -->
          <!-- <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Delete selected</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Archive</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Print</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Another Action</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown> -->

          <!-- ADD NEW -->
          <!-- <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Add New</span>
          </div> -->
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown
          v-show="!($attrs.pagination === 'false' || $attrs.pagination === false) && $attrs.pagination !== undefined && !Boolean(config.disablePager)"
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2">{{ pagingInfo }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 50">
              <span>50</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th v-for="(column, indexCol) in columns" :key="indexCol">
          <div class="w-full uppercase" :class="column.classes">
            {{ column.label }}
          </div>
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody :class="{ 'not-selectable': !config.selectable }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td v-for="(th, indexTh) in columns" :key="indexTh" :class="th.classes">
              <div v-if="hasComponent(th) || typeof getColumnRenderer(tr, th) === 'string'">
                <component :data="tr" :config="th" :is="getColumnRenderer(tr, th)" class="text-sm" />
              </div>

              <div v-else class="vx-data-list__cell">
                <div class="text-sm" v-if="typeof th.renderer === 'function'" v-html="th.renderer(tr)"></div>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <div class="vx-row justify-between">
      <div class="vx-col">
        <slot name="footer-actions" />
      </div>
      <div class="vx-col">
        <vs-pagination v-if="serverPaging" v-show="data.paging.total > 0" :total="paging.pages" :max="7" v-model="currentPage"></vs-pagination>
      </div>
    </div>
  </section>
</template>

<script>
// Components
import VxDataRendererText from './components/VxDataRendererText.vue';
import VxDataRendererBadge from './components/VxDataRendererBadge.vue';
import VxDataRendererActions from './components/VxDataRendererActions.vue';
import VxDataRendererProgress from './components/VxDataRendererProgress.vue';
import VxDataRendererImage from './components/VxDataRendererImage.vue';
import VxDataRendererIcon from './components/VxDataRendererIcon.vue';

import Paging from '@/entity/Paging';

import { debounce } from '@/plugins/helper';

export default {
  components: {
    VxDataRendererText,
    VxDataRendererBadge,
    VxDataRendererActions,
    VxDataRendererProgress,
    VxDataRendererImage,
    VxDataRendererIcon
  },
  name: 'VxDataList',
  inheritAttrs: false,
  props: {
    config: {
      type: Object,
      required: false,
      default() {
        return {
          selectable: false,
          disablePager: false
        };
      }
    },
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      selected: undefined,
      itemsPerPage: 10,
      paging: new Paging(this.data.paging),

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      sidebarConfig: {}
    };
  },
  computed: {
    hasComponent() {
      return th => {
        try {
          return Boolean(th.component && th.component.render);
        } catch (error) {
          return false;
        }
      };
    },
    items() {
      try {
        const data = this.data;
        const hasItems = Boolean(data.items);
        return hasItems ? data.items : Object.prototype.hasOwnProperty.call(data, 'length') ? data : [] || [];
      } catch (error) {
        return [];
      }
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.items.length;
    },
    getColumnRenderer() {
      return (tr, th) => {
        if (th.component) return th.component;
        if (th.actions) return 'VxDataRendererActions';
        if (!th.renderer && !th.field) {
          console.error(`Property [field] or [renderer] is missing: ${JSON.stringify(th)}`);
          return false;
        }
        if (!th.renderer) return 'VxDataRendererText';
        if (th.renderer && typeof th.renderer === 'string') return th.renderer;
        if (th.renderer && typeof th.renderer === 'function') return false;

        return console.error(`Invalid column configuration for: ${JSON.stringify(th)}`);
      };
    },
    serverPaging() {
      return Object.prototype.hasOwnProperty.call(this.data, 'paging');
    },
    attrs() {
      const attrs = this.$attrs;
      return {
        ...attrs,
        pagination: this.serverPaging ? false : attrs.pagination
      };
    },
    currentPage: {
      get() {
        if (this.isMounted) {
          if (this.serverPaging) return this.paging.page;
          return this.$refs.table.currentx;
        }
        return 0;
      },
      set(current) {
        if (this.serverPaging) this.paging.page = current;
      }
    },
    pagingInfo() {
      if (this.serverPaging) {
        const paging = this.data.paging;
        const total = paging.total;
        const start = paging.page * paging.size - (paging.size - 1);
        const end = paging.page * paging.size < paging.total ? paging.page * paging.size : paging.total;
        return `${start} - ${end} of ${total}`;
      }
      const start = this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1);
      const end = this.items.length - this.currentPage * this.itemsPerPage > 0 ? this.currentPage * this.itemsPerPage : this.items.length;
      const total = this.queriedItems;
      return `${start} - ${end} of ${total}`;
    },
    listeners() {
      const $ctrl = this;
      return {
        search: debounce(function(value) {
          console.log('listener');
          $ctrl.$emit('filter', {
            q: value,
            page: 1,
            size: $ctrl.itemsPerPage
          });
        }, 350),
        ...this.$listeners
      };
    }
  },
  methods: {
    handleSearch(value) {
      console.log(value);
      return;
    },
    getOrderStatusColor(status) {
      if (status === 'on_hold') return 'warning';
      if (status === 'delivered') return 'success';
      if (status === 'canceled') return 'danger';
      return 'primary';
    },
    getPopularityColor(num) {
      if (num > 90) return 'success';
      if (num > 70) return 'primary';
      if (num >= 50) return 'warning';
      if (num < 50) return 'danger';
      return 'primary';
    },
    emitSelected(value) {
      if (this.config.selectable) this.selected = value;
    }
  },
  watch: {
    'data.paging'(paging) {
      this.paging = new Paging(paging);
    },
    currentPage(value, old) {
      if (old > 0)
        this.$emit('filter', {
          ...this.paging,
          size: this.itemsPerPage
        });
    },
    itemsPerPage(size) {
      if (this.currentPage !== 1) return (this.currentPage = 1);
      this.$emit('filter', {
        ...this.paging,
        size
      });
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="scss">
.vx-dl-sm .vx-data-list .vs-con-table .vs-table {
  border-spacing: 0 0.3rem;
}
.vx-data-list {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      // padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }

  tbody {
    &.not-selectable {
      tr.selected {
        cursor: unset !important;
      }
    }
  }
}
</style>
