const mutations = {
  SET_AUTH (state, payload) {
    state.payload = payload;
    if (payload && payload.state) {
      localStorage.setItem('state', payload.state);
    } else {
      localStorage.removeItem('state');
    }
  },
  SET_TENANTS ( state, payload ) {
    state.tenants = payload;
  },
  SET_TENANT (state, payload) {
    localStorage.removeItem('tid');
    if (typeof payload === 'object') return state.tenant = payload;
    const tenants = state.tenants || [];
    state.tenant = tenants.find(item => item.id === payload);
  }
}

export default mutations

