<template>
  <vs-table :data="data">
    <template slot="thead">
      <vs-th v-for="key in keys" :key="key">{{key}}</vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
        <vs-td v-for="key in keys" :key="key"><div class="truncate max-w-32">{{ tr[key] }}</div></vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
export default {
  name: "CookiesTable",
  data() {
    return {
      data: [
        {
          domain: ".1-day.co.nz",
          secure: false,
          httpOnly: false,
          path: "/",
          expiry: 1595983149
        },
        {
          domain: ".1-day.co.nz",
          secure: false,
          name: "_hjid",
          path: "/"
        },
        {
          domain: ".1-day.co.nz",
          secure: true,
          name: "__cfduid",
          value: "d73b7f599051d4f39745d26e971e6324d1595982146",
          httpOnly: true,
          path: "/",
          expiry: 1598574146.422779
        },
        {
          domain: ".1-day.co.nz",
          secure: false,
          name: "_gat_UA-1243473-5",
        },
        {
          domain: ".1-day.co.nz",
          secure: false,
          name: "_fbp",
          value: "fb.2.1595982148948.869783288",
          httpOnly: false,
          path: "/",
          expiry: 1603758149
        },
        {
          domain: "www.1-day.co.nz",
          secure: true,
          name: "JSESSIONID",
          value: "sM74YtZsXWvvRY8XTlwh9vn3.1daynode5",
          httpOnly: true,
          path: "/"
        },
        {
          domain: ".1-day.co.nz",
          secure: false,
          name: "_gcl_au",
          value: "1.1.2119862699.1595982148",
          httpOnly: false,
          path: "/",
          expiry: 1603758148
        },
        {
          domain: ".1-day.co.nz",
          secure: false,
          name: "__zlcmid",
          value: "zQjJoazg6clpsb",
          httpOnly: false,
          path: "/",
          expiry: 1627518150
        },
        {
          domain: ".1-day.co.nz",
          secure: false,
          name: "_ga",
          value: "GA1.3.643641033"
        }
      ]
    };
  },
  computed: {
    keys() {
      return this.data.reduce((a, b) => [ ...new Set([ ...(Array.isArray(a) ? a : Object.keys(a)), ...Object.keys(b) ]) ]);
    },
    cookies() {
      return []
    }
  }
};
</script>