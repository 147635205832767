import Vue from 'vue';




const mutations = {
  SET_LIST (state, payload) {
    state.list = payload;
    const { items } = state.list;
    if (!state.hasCaptures && items && items.length > 0) state.hasCaptures = true;
  },

  SET_ITEM (state, payload) {
    //console.log('SET_ITEM', payload );
    Vue.set(state, 'item', payload);
    if (payload && 
      (payload.screencasts && payload.screencasts.length > 0) || 
      (payload.log && payload.log.length > 0) 
      ) {
      state.hasTestResults = true;
    }
    state.loading = false;
  },
  SET_COMPARING (state, payload) {
    state.comparing = payload;
  },
  /**
   * @description This method will be able to add or delete a tag (no-edit)
   * @param {*} state The captures module state
   * @param {*} payload The tag item
   * @param {Number} [payload.id=?] a payload with { id } will delete from the tags otherwise it'll add a new one
   * @param {String} [payload.text] The tag string
   */
  UPDATE_TAGS (state, payload) {
    const tags = state.item.tags;
    const tagFound = tags.find(tag => tag.id === payload.id);
    if (tagFound) return tags.splice(tags.indexOf(tagFound), 1);
    tags.push(payload);
  },
  SET_HOSTS (state, payload) {
    state.hosts = payload;
  },
  SET_TAGS (state, payload) {
    state.tags = payload;
  },

  REQUEST_PARAMS (state, payload) {
    state.requestParams = payload;
  },

  START_TEST (state) {
    //state.testRunning = true;
    Vue.set(state, 'testCompileErrors', null);
    state.testRunning = true
    state.hasTestResults = false;
    state.item = null;
  },
  STOP_TEST (state) {
    state.testRunning = false;
  },
  TEST_FINISHED (state, payload) {
    Vue.set(state, 'testCompileErrors', payload.errors);
    state.testRunning = false;
  },

  SET_CAPTURES_SUMMARY (state, payload) {
    state.summary = payload;
  },
  SET_LOADING (state, payload) {
    state.loading = payload
  },




}

export default mutations

