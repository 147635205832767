<template>
  <div class="vx-field relative">
    <pre class="text-danger text-xs mt-1" v-show="$attrs.name">Error: Please change the 'name' attribute to 'as'.</pre>
    <label v-show="excludeLabel && type !== 'switch'" class="vs-input--label">{{ $attrs.label }}</label>
    <vs-input
      v-if="type === 'input'"
      :class="$vnode.data.staticClass"
      :name="name"
      :data-vv-as="nameAs"
      :autocomplete="name"
      v-model="model"
      v-bind="computedAttrs"
      v-on="$listeners"
      :danger="error ? true : false"
    />

    <vs-textarea
      v-if="type === 'textarea'"
      v-bind="computedAttrs"
      :class="$vnode.data.staticClass"
      :name="name"
      :data-vv-as="nameAs"
      :autocomplete="name"
      v-model="model"
      v-on="$listeners"
      :danger="error ? true : false"
    />

    <div
      v-if="model && type === 'select' && $attrs.config && $attrs.config.clearable"
      class="x-global con-chips--remove-all mr-5 h-auto z-10 top-0 mt-12"
      @click="$emit('input', null)"
    >
      <i class="vs-icon notranslate icon-scale material-icons null -mt-1">close</i>
    </div>
    <vs-select
      v-if="type === 'select' && !options.groupby"
      v-bind="computedAttrs"
      :class="$vnode.data.staticClass"
      :name="name"
      :data-vv-as="nameAs"
      v-model="model"
      :ref="name"
      v-init="$randomisedVsSelectAutofill(this.$refs[name])"
      v-on="$listeners"
      :danger="error ? true : false"
    >
      <vs-select-item
        :key="index"
        :value="item[options.value || 'value']"
        :text="item[options.text || 'text']"
        v-for="(item, index) in options.items"
      />
      <vs-select-item v-if="options.otherFunction" :value="otherValue" text="Other" />
    </vs-select>

    <vs-select
      v-if="type === 'select' && options.groupby"
      v-bind="computedAttrs"
      :class="$vnode.data.staticClass"
      :name="name"
      :data-vv-as="nameAs"
      v-model="model"
      :ref="name"
      v-init="$randomisedVsSelectAutofill(this.$refs[name])"
      v-on="$listeners"
      :danger="error ? true : false"
    >
      <div v-for="(item, index) in optionsGrouped" :key="index" class="vx-select--group">
        <vs-select-group :title="item.label || item.title" v-if="item.items">
          <vs-select-item :key="n" :value="item[options.value || 'value']" :text="item[options.text || 'text']" v-for="(item, n) in item.items" />
        </vs-select-group>
      </div>
      <vs-select-item v-show="options.otherFunction" :value="otherValue" text="Other" />
    </vs-select>

    <div v-if="type === 'datetime'">
      <label v-show="$attrs.label" class="vs-input--label">{{ $attrs.label }}</label>
      <div
        class="relative vx-field__datetime"
        :class="{
          'vx-field__datetime--icon': $attrs.icon,
          'vx-field__datetime--clearable': model && $attrs.config && $attrs.config.clearable
        }"
      >
        <vs-icon class="icon-label" v-if="$attrs.icon" icon-pack="feather" :icon="$attrs.icon"></vs-icon>
        <flat-pickr
          v-bind="computedAttrs"
          :class="$vnode.data.staticClass"
          :name="name"
          :data-vv-as="nameAs"
          :autocomplete="name"
          v-model="model"
          :config="{
            enableTime: isDateRange ? false : true,
            dateFormat: isDateRange ? 'd-m-Y' : 'd-m-Y H:i',
            minDate: isDateRange ? null : $moment().toDate(),
            ...($attrs.config || {}),
            ...($attrs.config && $attrs.config.disableWeekends
              ? {
                  disable: [disableWeekends]
                }
              : {})
          }"
          v-on="$listeners"
        />
        <div class="vx-field__append">
          <slot name="append"></slot>
          <vs-icon
            v-if="model && $attrs.config && $attrs.config.clearable"
            icon-pack="feather"
            icon="icon-x"
            title="Clear"
            @click="$emit('input', null)"
          ></vs-icon>
        </div>
      </div>
    </div>

    <div v-if="type === 'group'">
      <vx-input-group :class="$vnode.data.staticClass">
        <vs-input :name="name" :data-vv-as="nameAs" :autocomplete="name" v-model="model" v-bind="computedAttrs" v-on="$listeners" />

        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
      </vx-input-group>
    </div>

    <div
      v-if="type === 'switch'"
      :class="{
        'flex items-center justify-between': $attrs.inline
      }"
    >
      <label v-show="$attrs.label" class="vs-input--label">{{ $attrs.label }}</label>
      <vs-switch
        :name="name"
        icon-pack="feather"
        :vs-icon-on="(switchConfig.on || {}).icon"
        :vs-icon-off="(switchConfig.off || {}).icon"
        v-model="switchModel"
        :data-vv-as="nameAs"
        :autocomplete="name"
        v-bind="computedAttrs"
        class="mt-1"
        :class="{ 'vs-switch--custom-value': switchConfig.on.value || switchConfig.off.value }"
        v-on="$listeners"
        @click.prevent="switchInput"
      >
        <span slot="on" v-if="(switchConfig.on || {}).text">{{ (switchConfig.on || {}).text }}</span>
        <span slot="off" v-if="(switchConfig.off || {}).text">{{ (switchConfig.off || {}).text }}</span>
      </vs-switch>
    </div>

    <div v-if="type === 'v-select'">
      <label v-show="$attrs.label" class="vs-input--label">{{ $attrs.label }}</label>
      <div class="vs-con-input">
        <v-select
          v-bind="$attrs"
          :class="$vnode.data.staticClass"
          :data-vv-as="nameAs"
          v-model="model"
          :ref="name"
          v-on="$listeners"
          :label="options.text || 'label'"
          :options="options.items || []"
        />
      </div>
    </div>

    <span class="block text-danger text-xs mt-1 ml-1" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
/* eslint no-unused-vars: 0 */
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  name: 'VxField',
  inheritAttrs: false,
  components: {
    flatPickr,
    vSelect
    // DateRangePicker
  },
  props: {
    value: {
      type: [String, Object, Array, Number, Boolean],
      default: ''
    },
    options: {
      type: Object,
      default() {
        return {
          value: 'value',
          text: 'text',
          items: []
        };
      }
    }
  },
  data() {
    return {
      name: '',
      model: this.value
    };
  },
  computed: {
    nameAs() {
      return this.$attrs.label || this.$attrs['label-placeholder'] || this.$attrs.as || 'field';
    },
    type() {
      return this.$attrs.type;
    },
    error() {
      const items = this.errors.items || [];
      for (let i = 0; i < items.length; i++) {
        const error = items[i];
        if (error.field === this.name || error.field === this.nameAs) {
          return error.msg.replace(this.name, this.nameAs.toLowerCase());
        }
      }
      return false;
    },
    optionsGrouped() {
      const options = this.options;
      if (options.groupby) {
        return (options.items || []).reduce((array, o) => {
          const title = o[options.groupby];
          const option = {
            title,
            items: [o]
          };

          const applyGrouplabel = option => {
            const groupLabel = options.groupLabel;
            const isFunction = typeof groupLabel === 'function';
            if (option.title === title) {
              option.label = !isFunction ? groupLabel : groupLabel(option);
            }
          };

          let isGroupRegistered = false;
          for (let i = 0; i < array.length; i++) {
            const _option = array[i];
            if (_option.title === title) {
              isGroupRegistered = true;
              _option.items.push(o);
            }

            if (options.groupLabel) applyGrouplabel(_option);
          }

          if (!isGroupRegistered) {
            array.push(option);
            applyGrouplabel(option);
          }

          return array;
        }, []);
      }
      return [];
    },
    otherValue() {
      const value = JSON.parse(JSON.stringify(this.value));
      const options = this.options;
      try {
        const existed = (options.items || []).reduce((bool, item) => {
          if (item[options.value || value] === value) bool = true;
          return bool;
        }, false);
        return existed ? 'other' : value;
      } catch (e) {
        return 'other';
      }
    },
    isDateRange() {
      const attrs = this.$attrs;
      return attrs.config && attrs.config.mode === 'range';
    },
    disableWeekends() {
      return date => {
        // return true to disable
        const satIndex = 6;
        const sunIndex = 0;
        const dayIndex = this.$moment(date).days();
        const isPastDay = this.$moment().isAfter(date);
        return dayIndex === satIndex || dayIndex === sunIndex || isPastDay;
      };
    },
    excludeLabel() {
      return (this.type === 'group' || (this.$attrs.config || {}).excludeLabel) && this.type !== 'datetime';
    },
    computedAttrs() {
      const $attrs = this.$attrs;
      return {
        ...$attrs,
        ...(this.excludeLabel
          ? {
              label: undefined,
              'label-placeholder': undefined
            }
          : {})
      };
    },
    switchConfig() {
      try {
        return this.$attrs.config || {};
      } catch (error) {
        return {};
      }
    },
    switchModel: {
      get() {
        const hasCustomValue = this.switchConfig.on.value || this.switchConfig.off.value;
        if (!hasCustomValue) return this.model;

        const on = this.switchConfig.on;
        const off = this.switchConfig.off;
        const value = this.model;

        if (value === on.value) return true;
        if (value === off.value) return false;
        return this.model;
      },
      set(value) {
        const hasCustomValue = this.switchConfig.on.value || this.switchConfig.off.value;
        if (!hasCustomValue) return this.$emit('input', value);
        const on = this.switchConfig.on;
        const off = this.switchConfig.off;

        this.$emit('input', value ? on.value : off.value);
      }
    }
  },
  methods: {
    switchInput(e) {
      this.switchModel = !this.switchModel;
    }
  },
  created() {
    this.name = this.$randomId(this.nameAs.toLowerCase().replace(/\s/g, '_'));
  },
  watch: {
    model: {
      deep: true,
      handler(newValue) {
        const config = this.$attrs.config;
        if (typeof newValue === 'string' && config && config.trimWhiteSpace) {
          const items = (newValue || '').split(/(\r\n|\n|\r)/gm);
          const textLines = items.filter(str => str && str.length > 0 && str !== '\n');
          if (textLines && textLines.length > 0) {
            const lastItemStr = textLines.slice(-1)[0];
            const lastChar = lastItemStr.substr(-1);
            if (!new RegExp('^[^\\s]+$').test(lastChar)) {
              this.model = textLines.join('\n');
              return;
            }
          }

          if (items && items.length >= 3) {
            const lastItem = items.slice(-1)[0];
            const secondLast = items[items.length - 2];
            const lastTrimmedItem = (textLines.slice(-1) || [''])[0] || '';
            if (!new RegExp('^[^\\s]+$').test(lastItem) && secondLast === '\n' && !!lastTrimmedItem) {
              textLines.push('');
            }
          }
          this.model = textLines.map(str => str.trim()).join('\n');
        }
      }
    },
    value: {
      deep: true,
      handler(newValue) {
        this.model = newValue;
        try {
          if (this.options) {
            const otherFunction = this.options.otherFunction;
            if (this.$attrs.type === 'select' && otherFunction && typeof otherFunction === 'function') {
              if (this.otherValue === this.model) otherFunction(this.$refs[this.name]);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.vs-icon[title='Clear'] {
  position: absolute;
  top: 1.4rem;
  right: 0.75rem;
  cursor: pointer;
  z-index: 1;
}
</style>
