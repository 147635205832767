<template>
  <div class="vx-field vx-select-template" :class="{ 'vx-select-template--empty': !(value || {}).id }">
    <label class="vs-input--label" v-if="$attrs.label"> {{ $attrs.label }} </label>
    <v-select
      append-to-body
      ref="vSelect"
      label="text"
      :filterable="false"
      :options="[
        ...options,
        {
          text: 'New test case'
        }
      ]"
      @search="onSearch"
      :multiple="$attrs.multiple || false"
      v-model="model"
      v-on="$listeners"
    >
      <template v-slot:option="option">
        <div class="text-xs py-2">
          <div class="flex" :class="{ 'mb-2': option.schedule }">
            <div class="font-semibold max-w-64 truncate text-sm pr-2">{{ option.text }}</div>
            <span v-if="option.template" class="ml-auto font-mono text-xs">
              <small class="uppercase">{{ option.template.fileFormat }}</small> |
              <small class="uppercase">{{ option.template.captureEntireHeight ? 'Fullscreen' : 'Windowed' }}</small>
            </span>
          </div>
          <div v-if="option.schedule" class="flex font-mono text-xs">
            <small>{{ option.schedule | humanDescription }}</small>
            <span class="ml-auto">
              <small class="">{{ option.timeZone }}</small>
            </span>
          </div>
        </div>
      </template>
    </v-select>
    <div class="con-text-validation span-text-validation vs-input--text-validation-span" v-if="$attrs['description-text']">
      <span class="span-text-validation">{{ $attrs['description-text'] }}</span>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/plugins/helper';
export default {
  props: ['value'],
  data() {
    return {
      options: [],
      model: this.value
    };
  },
  methods: {
    onSearch(search, loading) {
      //if (search.length > 3) 
      {
        loading(true);
        this.search(search, loading, this);
      }
    },
    search: debounce(async (search, loading, vm) => {
      /* eslint no-unused-vars: 0 */
      const { type, $store, list } = vm;
      console.log ('search',search);
      const result = await $store.dispatch('monitors/list', {
        params: {
          q: search
        },
        axiosConfig: {
          disablePreload: true
        }
      });

      if (result) {
        console.log('result is ', result);
        vm.options = result.items.map(item => ({
          ...item,
          text: item.name + " : " + item.url.host.domain || 'No title'
        }));
      }

      loading(false);
    }, 500),
    firstLoadTests: debounce(async (vm) => {
      /* eslint no-unused-vars: 0 */
      const { type, $store, list } = vm;
      const result = await $store.dispatch('monitors/list', { });
      if (result) {
        vm.options = result.items.map(item => ({
          ...item,
          text: item.name || 'No title'
        }));
      }
    })
  },
  mounted() {
    this.firstLoadTests(this);
    window.vSelect = this.$refs.vSelect;
    console.log('mounted',this);
  }
};
</script>
