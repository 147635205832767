<template>
  <div class="vx-collapsable-list">
    <vs-collapse type="margin" class="p-0">
      <vs-collapse-item ref="collapseItem" v-for="(item, i) in list" :key="i" :sst="true" @fetch="({ done }) => emitCollapse(done, item, i)">
        <slot slot="header" name="header" :collapse="collapse" :item="item" :index="i"></slot>
        <slot name="content" :index="i" :item="item" :data="contents"></slot>
      </vs-collapse-item>
    </vs-collapse>
    <div class="vx-paging" v-if="paging">
      <vs-pagination :total="paging.pages" v-model="currentPage"></vs-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      contents: {}
    };
  },
  computed: {
    list() {
      if (Array.isArray(this.data)) return this.data;
      return this.data.items || [];
    },
    paging() {
      try {
        if (Array.isArray(this.data)) return null;
        const paging = this.data.paging;
        if (!paging) return null;
        if (paging.total > paging.size) return paging;
        return null;
      } catch (error) {
        return null;
      }
    }
  },
  methods: {
    async collapse({ key, i, fetch }) {
      console.log('async collapse');
      const component = this.$refs.collapseItem[i];
      console.log(this.contents);
      if (!this.contents[key]) {
        component.toggleContent();
        const result = await fetch(key);
        this.setContents(key, result);
        this.$nextTick(() => component.toggleContent());
      }
    },
    setContents(key, data) {
      this.$set(this.contents, key, data);
    },
    emitCollapse(done, item, index) {
      this.$emit('toggle-item', {
        item,
        index,
        done: (key, data) => {
          this.setContents(key, data);
          this.$nextTick(done);
        }
      });
    }
  },
  watch: {
    currentPage(value, old) {
      if (old > 0)
        this.$emit('filter', {
          ...this.paging,
          page: value
        });
    }
  }
};
</script>
