import Vue from 'vue'
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';
import store from '../store/store';
import apiConfig from '@/config/api';

window.WebsocketBaseUrl = apiConfig.websocketBaseUrl;

const options = { path: '/socket.io/' }; //Options object to pass into SocketIO
var io = SocketIO(apiConfig.websocketBaseUrl, options);

/*
io.prependAny((eventName, ...args) => {
    console.log(eventName);
    console.log(args);
});
*/

Vue.use(new VueSocketIO({
    debug: true,
    connection: io, //options object is Optional
    vuex: {
      store,
      actionPrefix: "SOCKET_ACTION_",
      mutationPrefix: "SOCKET_MUTATION_"
    }
  })
);