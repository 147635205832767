/* eslint no-unused-vars: 0 */
require('moment');
import moment from 'moment-timezone';

const getters = {
  getPlanById: (state) => (id) => {
    const plans = state.plans.items || [];
    try {
      return plans.find(item => item.id === parseInt(id));
    } catch (e) {
      return undefined;
    }
  },
  displayName (state, getters, rootState, rootGetters) {
    const contact = state.me.contact;
    if (contact.firstName && contact.lastName) return `${contact.firstName} ${contact.lastName}`;
    if (contact.firstName) return `${contact.firstName}`;
    return `${contact.email}`;
  },
  photoUrl (state, getters, rootState, rootGetters) {
    return rootGetters['auth/photUrl'];
  },
  userId (state) {
    const { me } = state;
    return me.contact.authId;
  },
  userTimezone (state) {
    const { me } = state;
    return me && me.contact && me.contact.timeZone || moment.tz.guess();
  },
  tenant (state) {
    return state.tenant;
  }
}

export default getters
