/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

export default {
  namespaced: true,
  getters,
  mutations,
  state,
  actions
}
