// "id": 1,
// "name": "Desktop",
// "fileFormat": "png",
// "captureEntireHeight": true,
// "delayMilliSeconds": 1000,
// "userAgent": "",
// "viewPorts": []

import Viewport from '@/entity/Viewport';
export default class Template {
  constructor (o = {}) {
    const Template = (o || {});
    this.id = Template.id || undefined;
    this.name = Template.name || '';
    this.fileFormat = Template.fileFormat || 'jpg';
    this.captureEntireHeight = Template.captureEntireHeight || true;
    this.delayMilliSeconds = Template.delayMilliSeconds || '';
    this.userAgent = Template.userAgent || '';
    
    /** Hide for now, might be needed in the future */
    // this.captureHtml = Template.captureHtml || false;
    // this.captureHar = Template.captureHar || false;
    // this.captureWarc = Template.captureWarc || false;

    const hasViewports = (Template.viewPorts && Template.viewPorts.length > 0);
    let viewports = new Array();
    if (hasViewports) {
      for (const v of Template.viewPorts) {
        viewports.push(new Viewport(v))
      }
    } else {
      viewports.push(new Viewport());
    }
    this.viewPorts = viewports;
  }
}