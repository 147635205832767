<template>
  <div class="vx-tab-content" v-show="$parent.activeTab === _uid">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VxTab',
  data() {
    return {}
  },
  mounted() {}
}
</script>