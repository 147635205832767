const baseUrl = 'https://api.pagewitness.com'
//const websocketBaseUrl = 'https://pagewitness-fargate-alb-dev-1744381743.us-east-1.elb.amazonaws.com'
const websocketBaseUrl = 'https://local.pagewitness.com:8080'
export default {
  baseUrl,
  websocketBaseUrl,
  entity: {
    captures: 'captures',
    templates: 'templates',
    monitors: 'monitors',
    host: 'captures',
    emails: 'emails',
    comments: 'comments'
  },
  permissions: {
    management: {
      list: {
        tenants: 'management:list:tenants'
      }
    },
    captures: {
      list: 'captures:list'
    },
    monitors: {
      list: 'monitors:list',
      bulkimport: 'monitors:bulkimport'
    },
    templates: {
      list: 'templates:list'
    },
    pagestatus: {
      list: 'pagestatus:list'
    },
    emails: {
      received: {
        list: 'emails:received:list'
      }
    },
    comments: {
      add: 'comments:add'
    }
  }
}