<template>
  <div class="heatmap__wrap">
    <div class="vx-row w-full">
      <div class="vx-col" :class="vertical ? 'w-1/2' : 'w-full'">
        <div class="max-w-xs mx-auto">
          <div class="vx-row text-sm justify-center" :class="vertical ? 'flex-col' : 'mb-2'">
            <div
              v-if="!compare"
              class="vx-col w-1/3 flex flex-col items-center"
              :class="{
                'mb-5 w-full items-end': vertical
              }"
            >
              <p class="font-semibold mb-3">Status</p>
              <p class="clearfix mx-auto"
                ><vs-chip :color="data.status | colorStatus">{{ data.status }}</vs-chip></p
              >
            </div>
            <div class="vx-col w-1/3" :class="{ 'mb-5 w-full': vertical }">
              <p class="font-semibold mb-3">Captures</p>
              <p>{{ totalCaptures }}</p>
            </div>
            <div class="vx-col w-1/3" :class="{ 'mb-5 w-full': vertical }">
              <p class="font-semibold mb-3">Changes</p>
              <p>{{ totalChanges }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col" :class="vertical ? 'w-1/2' : 'w-full'">
        <ul class="heatmap" :class="vertical ? 'heatmap--vertical' : 'inline-grid'">
          <li class="heatmap__item" v-for="(item, index) in dataList" :key="index" :data-time="item.date">
            <vs-tooltip
              :title="item.date | moment('date')"
              :text="`Captures: ${item.count}\nChanges: ${item.changes}\nChanged by: ${item.minChangePercent}%`"
            >
              <a
                href="#"
                v-bind:style="{
                  backgroundColor: heatColorArray[item.count - 1]
                }"
                v-if="item.count > 0"
                @click.prevent="compareCheck(item)"
                :title="item.date"
              ></a>
              <div v-else :title="item.date"></div>
              <span></span>
            </vs-tooltip>
          </li>
        </ul>
      </div>
      <div class="vx-col w-full px-12 mt-8" v-show="!hideFilter">
        <p class="text-xs font-semibold">FILTER BY CHANGE PERCENTAGE</p>
        <vs-slider text-fixed="%" v-model="minChangePercent" />
      </div>
    </div>
    <vs-prompt class="compare-select-prompt" :buttonsHidden="true" :active.sync="activePrompt" :title="compare ? 'Compare' : 'Captures'">
      <p v-if="compare"
        >Compare <span class="font-semibold">"{{ data.title }}"</span> captured at {{ selectedDay.date | moment }} to:</p
      >
      <p v-else>Select a time to see more details about that capture</p>
      <vs-list>
        <vs-list-item v-for="(captureItem, i) in selectedDay.captures" :key="i">
          <span v-if="captureItem.id == $route.params.id || captureItem.id == $route.params.captureId">
            <vs-tooltip text="Please select other capture date/time. You cannot compare similar data.">
              {{ captureItem.dateCaptured | moment }} -
              <span class="text-xs">{{
                captureItem.detectedChangePercent > 0 ? `${captureItem.detectedChangePercent}% changed` : 'No changes'
              }}</span>
              <span class="text-xs italic"> (selected)</span>
            </vs-tooltip>
          </span>
          <a v-else href="#" @click.prevent="goCompare(captureItem)">
            {{ captureItem.dateCaptured | moment }} -
            <span class="text-xs">{{ captureItem.detectedChangePercent > 0 ? `${captureItem.detectedChangePercent}% changed` : 'No changes' }}</span>
          </a>
        </vs-list-item>
      </vs-list>
    </vs-prompt>
  </div>
</template>

<script>
import CaptureChange from '@/entity/CaptureChange';
export default {
  props: {
    range: {
      type: Object,
      required: false
    },
    compare: {
      type: Boolean,
      required: false,
      default: true
    },
    data: {
      type: Object,
      required: true
    },
    events: {
      type: Array,
      required: false,
      default: () => new Array()
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false
    },
    hideFilter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      minChangePercent: 0,
      selectedCapture: {},
      activePrompt: false,
      daylist: {
        items: new Array()
      },
      selectedDay: new CaptureChange(),
      heatColorArray: ['#FF9F43', '#EA5455', '#182165', '#333333', '#333333', '#333333', '#333333']
    };
  },
  methods: {
    goCompare(item) {
      if (!this.compare) {
        this.$emit('close');
        return this.$router.push(`/captures/${item.id}`);
      }
      this.activePrompt = false;
      this.$emit('close');

      const captureId = this.data.id;
      const routeId = Number(this.$route.params.id);
      const compareId = Number(this.$route.params.captureId);
      if (this.$route.name === 'capture-compare') {
        let params = new Object();
        if (routeId === captureId) params = { id: item.id, captureId: compareId };
        if (compareId === captureId) params = { id: routeId, captureId: item.id };
        return this.$router.push(`/captures/${params.id}/compare/${params.captureId}/callback`);
      }
      this.$router.push(`/captures/${captureId}/compare/${item.id}`);
    },
    compareCheck(day) {
      if (day.captures.length === 1) {
        if (day.captures[0].id === this.data.id) {
          return this.$vs.notify({
            title: 'Warning',
            text: 'Please select other capture date/time. You cannot compare similar data.',
            color: 'warning',
            time: 10000
          });
        }
        this.$emit('close');
        return this.$router.push(
          this.compare ? `/captures/${this.$route.params.id}/compare/${day.captures[0].id}` : `/captures/${day.captures[0].id}`
        );
      }
      this.activePrompt = true;
      this.selectedDay = new CaptureChange(day);
    },
    async fetchChanges() {
      const params = {
        from: this.$moment(this.range.from).format('YYYY-MM-DD'),
        to: this.$moment(this.range.to).format('YYYY-MM-DD')
      };
      this.daylist = await this.$store.dispatch('captures/changes', {
        id: this.data.url.id,
        params
      });
    }
  },
  computed: {
    totalCaptures() {
      try {
        return (this.daylist.items || []).map(o => o.count).reduce((a, b) => parseInt(a || 0) + parseInt(b || 0));
      } catch (error) {
        return 0;
      }
    },
    totalChanges() {
      try {
        return (this.daylist.items || []).map(o => o.changes).reduce((a, b) => parseInt(a || 0) + parseInt(b || 0));
      } catch (error) {
        return 0;
      }
    },
    dataList() {
      return ((this.daylist || {}).items || []).filter(item => item.minChangePercent >= this.minChangePercent);
    }
  },
  async created() {
    if (this.range) return await this.fetchChanges();

    const params = {
      from: this.$moment()
        .subtract(9, 'months')
        .format('YYYY-MM-DD'),
      to: this.$moment().format('YYYY-MM-DD')
    };
    this.daylist = await this.$store.dispatch('captures/changes', {
      id: this.data.url.id,
      params
    });
  },
  mounted() {}
};
</script>
