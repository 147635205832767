<template>
  <div class="form-template-info">
    <!-- Name -->
    <vx-field
      type="input"
      class="w-full"
      label="Title"
      v-model="form.title"
      description-text="The title of the website will be used for this capture if you don't enter one"
    />

    <!-- URL -->
    <vx-field
      type="input"
      class="w-full mt-5"
      label="URL"
      v-model="form.url"
      v-validate="'required'"
      description-text="Full address of the web page you'd like to capture."
    />

    <!-- Templates -->
    <VxSelectTemplate class="mt-5" v-model="form.template" name="template" v-validate="'template'" />

    <vs-checkbox class="mt-5" v-model="advance">Advance options</vs-checkbox>
    <div v-if="advance">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <vx-field
            type="switch"
            size="large"
            class="mt-5 w-full"
            label="Capture HTML"
            v-model="form.captureHtml"
            :config="{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }"
          />
        </div>
        <div class="vx-col w-1/2">
          <vx-field
            type="switch"
            size="large"
            class="mt-5 w-full"
            label="Capture HAR"
            v-model="form.captureHar"
            :config="{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }"
          />
        </div>
        <div class="vx-col w-1/2">
          <vx-field
            type="switch"
            size="large"
            class="mt-5 w-full"
            label="Capture Web Archive"
            v-model="form.captureWarc"
            :config="{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }"
          />
        </div>
        <div class="vx-col w-1/2">
          <vx-field
            type="switch"
            size="large"
            class="mt-5 w-full"
            label="Set Cookies"
            v-model="form.cookiesEnabled"
            :config="{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }"
          />
        </div>
        <div v-if="form.cookiesEnabled" class="vx-col w-full">
          <vx-field type="textarea" class="mt-5 mb-1 w-full font-mono" label="Request Cookies (Enter in cookies.txt format)" v-model="form.cookies" />
          <div class="con-text-validation span-text-validation vs-input--text-validation-span">
            <span class="span-text-validation"
              >The example <pre class="inline">cookies.txt</pre> file defines a cookie for the domain <pre class="inline">www.example.com</pre> with
              no expiry date and a <pre class="inline">name=value</pre> setting of <pre class="inline">id=1234</pre></span
            >
          </div>
          <pre class="text-sm">
# Domain     Tailmatch  Path  Secure Expires Name  Value
www.example.com  TRUE        /    FALSE  0       id    1234
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Capture from '@/entity/Capture';
import VxSelectTemplate from '@/components/VxSelectTemplate';
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      advance: false,
      form: new Capture(this.data)
    };
  },
  computed: {},
  methods: {
    async submit() {
      const result = await this.$store.dispatch('captures/create', this.form);
      if (result && result.id) {
        this.$router.push(`/captures/${result.id}?inprogress=true`);
        return result;
      }
      return false;
    }
  },
  components: {
    VxSelectTemplate
  },
  watch: {
    data(data) {
      this.form = new Capture(data);
    }
  }
};
</script>
