<template>
  <div class="vx-con--tabs">
    <div class="vx-con-tabs-nav flex sm:justify-between sm:items-center mb-3">
      <ul class="flex">
        <li v-for="tab in items" :key="tab.key" class="relative">
          <vs-button
            @click="activeTab = tab.key"
            type="line"
            icon-pack="feather"
            :icon="tab.icon"
            :size="size"
            :disabled="tab.disabled"
            :class="{ 'vs-button-line--active': activeTab === tab.key }">
            {{ tab.title }}
          </vs-button>
          <span
            v-if="tab.badgeText !== null && tab.badgeText !== undefined && tab.badgeText !== ''"
            class="dot-count vs-avatar--count badgeNumber"
            style="background: rgb(140, 23, 164);">{{ tab.badgeText }}</span>
        </li>
      </ul>
      <slot name="actions" />
    </div>
    <div class="vx-con--tabs-block">
      <slot v-if="!(config && config.items)" />
      <div v-else class="vx-tab__content" v-for="tab in items" :key="tab.key" v-show="activeTab === tab.key">
        <component :is="tab.component" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VxTabs',
  props: {
    config: Object
  },
  data() {
    return {
      activeTab: null
    }
  },
  computed: {
    // childAttrs() {
    //   return this.$children.map()
    // },
    items() {
      if (!this.isMounted) return [];
      try {
        
        return this.config.items.map(item => ({
          ...item,
          'key': this.$randomId('tab')
        }))
      } catch (error) {
        const items = this.$children;
        return items.filter(item => {
          return item.$vnode.componentOptions.tag === 'VxTab'
        }).map(item => {
          return {
            ...item.$attrs,
            'key': item['_uid']
          }
        })
      }
    },
    size() {
      try {
        return this.config.settings.size
      } catch (error) {
        return 'default'
      }
    }
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = this.items[index].key
    }
  },
  created() { },
  mounted() {
    this.$nextTick(() => {
      if (this.items.length > 0) this.activeTab = this.items[0].key;
    });
  }
}
</script>

<style lang="scss" scoped>
.vx-con--tabs {
  .vs-button {
    &.small {
      font-size: .875em;
      &:not(.includeIconOnly) {
        padding: 0.5rem 0.75rem;
      }
    }
  }
}
</style>