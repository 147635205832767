import Tenant from '@/entity/Tenant';
export default class Contact {
  constructor(o) {
    const Contact = o || {};
    this.id = Contact.id || undefined;
    this.firstName = Contact.firstName || '';
    this.lastName = Contact.lastName || '';
    this.email = Contact.email || '';
    this.mobile = Contact.mobile || '';
    this.phone = Contact.phone || '';
    this.title = Contact.title || '';
    this.authId = Contact.authId || '';
    this.timeZone = Contact.timeZone || '';
    this.tenants = Contact.tenants
      ? (Contact.tenants).map(item => new Tenant(item))
      : undefined;
  }
}
