/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

/* eslint no-unused-vars: 0 */

import Vue from 'vue';
import Router from 'vue-router';

// Vuex Store
import store from './store/store';
import apiConfig from '@/config/api';
const { permissions } = apiConfig;
const { captures, monitors, templates, pagestatus } = permissions;
import { protoOf, uniq } from '@/plugins/helper';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'dashboard',
          component: () => import('./views/dashboard/Index.vue'),
          meta: {
            auth: true,
            pageTitle: 'Dashboard',
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              }
            ],
          }
        },
        {
          path: '/captures',
          component: () => import('./views/captures/Index.vue'),
          meta: {
            inherit: true,
            auth: true,
            permission: captures.list,
            pageTitle: 'Captures',
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
            ],
            actions: {
              items: [{
                icon: 'PlusIcon',
                text: 'New capture',
                // url: '/monitoring/create'
                handler: function (component) {
                  try {
                    component.$vxSlideIn.open({
                      classNames: 'vs-content-sidebar--capture',
                      data: {},
                      config: {
                        component: 'CaptureForm',
                        title: 'New Capture'
                      }
                    });
                  } catch (e) { return e }
                },
              }]
            },
            subTitle (component) {
              let empty = false;
              let subtitle = ''
              try {
                empty = !component.hasCaptures;
              } catch (error) {
                empty = false;
              } finally {
                const hasQuery = Boolean(router.currentRoute.query.datecaptured);
                subtitle = empty && !hasQuery ? 'Create your first capture now!' : 'Browse and search in all your captures.'
              }

              return subtitle;
            }
          },
          children: [{
            path: '',
            name: 'captures',
            component: () => import('./views/captures/List.vue'),
            query: {
              datecaptured: undefined
            },
            meta: {
              breadcrumb: [
                {
                  title: 'Captures',
                  active: true
                }
              ]
            }
          }, {
            path: '/captures/domains',
            name: 'captures-domains',
            component: () => import('./views/captures/Domains.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Domains',
                  active: true
                }
              ]
            }
          }, {
            path: '/captures/tags',
            name: 'captures-tags',
            component: () => import('./views/captures/Tags.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Tags',
                  active: true
                }
              ]
            }
          },]
        },
        {
          path: '/captures',
          component: () => import('./views/captures/View.vue'),
          meta: {
            auth: true
          },
          children: [{
            path: '/captures/:id(\\d+)',
            name: 'capture-view',
            component: () => import('./views/captures/single/Index.vue'),
            meta: {
              breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'Captures',
                url: '/captures'
              },
              {
                title: 'View',
                active: true
              },
              ],
              actions: {
                type: 'button',
                items: [{
                  icon: 'MonitorIcon',
                  text: 'View monitoring',
                  disabled: function (component) {
                    try {
                      return !component.$store.getters['captures/hasMonitor'];
                    } catch (error) {
                      return true;
                    }
                  },
                  handler: function (component) {
                    component.$router.push(`/monitoring/${component.item.monitor.id}`)
                  },
                }]
              },
              pageTitle: ($ctrl) => {
                try {
                  return $ctrl.captureTitle || 'Capture View'
                } catch (error) {
                  return 'Capture View'
                }
              },
              entityType: 'capture',
            }
          }, {
            path: '/captures/:id(\\d+)/compare/:captureId',
            component: () => import('./views/captures/compare/Index.vue'),
            children: [{
              path: '',
              component: () => import('./views/captures/compare/CaptureCompare.vue'),
              name: 'capture-compare',
              params: {
                id: undefined,
                captureId: undefined,
                callback: false,
              },
              meta: {
                breadcrumb: [{
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Captures',
                  url: '/captures'
                },
                {
                  title: 'Comparison',
                  active: true
                },
                ],
                actions: {
                  type: 'button',
                  items: [{
                    icon: 'RefreshCwIcon',
                    text: 'Refresh',
                    attrs: {
                      type: 'border',
                      size: 'small'
                    },
                    handler: function (component, reload) {
                      try {
                        reload();
                      } catch (e) { return e }
                    },
                  }]
                },
                pageTitle: 'Capture comparison',
                entityType: 'capture',
              },
            }, {
              path: '/captures/:id(\\d+)/compare/:captureId/callback',
              name: 'capture-compare-callback',
              component: () => import('./views/captures/compare/CaptureCompareCallback.vue'),
              meta: {
                breadcrumb: [{
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Captures',
                  url: '/captures'
                },
                {
                  title: 'Comparison',
                  active: true
                },
                ],
                actions: {
                  type: 'button',
                  items: [{
                    icon: 'RefreshCwIcon',
                    text: 'Refresh',
                    attrs: {
                      type: 'border',
                      size: 'small'
                    },
                    handler: function (component, reload) {
                      try {
                        reload();
                      } catch (e) { return e }
                    },
                  }]
                },
                pageTitle: 'Capture comparison',
                entityType: 'capture',
              }
            }]
          }]
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('./views/account/Index.vue'),
          meta: {
            auth: true,
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Account',
                active: true
              }
            ],
            pageTitle: 'Account'
          }
        },
        {
          path: '/upgrade',
          component: () => import('./views/pages/upgrade/Index.vue'),
          children: [{
            path: '',
            name: 'upgrade',
            component: () => import('./views/pages/upgrade/List.vue'),
            meta: {
              auth: true,
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Upgrade',
                  active: true
                }
              ],
              pageTitle: 'Choose your plan'
            },
          }, {
            path: '/upgrade/thankyou',
            name: 'upgrade-thankyou',
            component: () => import('./views/pages/upgrade/Thankyou.vue'),
            meta: {
              auth: true,
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Upgrade',
                  url: '/upgrade'
                },
                {
                  title: route => {
                    const getters = router.$store.getters;
                    const currentUpgrade = getters['accounts/getPlanById'](route.query.upgradeId);
                    try {
                      return currentUpgrade.name
                    } catch (error) {
                      return undefined
                    }
                  },
                  active: true
                }
              ],
              pageTitle: 'You\'re all set!'
            }
          }, {
            path: '/upgrade/:id(\\d+)',
            name: 'upgrade-plan',
            component: () => import('./views/pages/upgrade/Form.vue'),
            meta: {
              auth: true,
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Upgrade',
                  url: '/upgrade'
                },
                {
                  title: route => {
                    const getters = router.$store.getters;
                    const currentUpgrade = getters['accounts/getPlanById'](route.params.id);
                    try {
                      return currentUpgrade.name
                    } catch (error) {
                      return undefined
                    }
                  },
                  active: true
                }
              ],
              pageTitle: 'Choose your plan'
            }
          }]
        },
        {
          path: '/templates',
          name: 'templates',
          component: () => import('./views/templates/Index.vue'),
          meta: {
            auth: true,
            permission: templates.list,
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Templates',
                active: true
              }
            ],
            /**
             * Interface for actions that will be rendered on the right-hand page heading
             * @interface 
             */
            actions: {
              /**
               * @description [type] - can be button(default) or dropdown
               */
              type: 'button',
              items: [{
                icon: 'PlusIcon',
                /**
                 * @description [text] - can simply contain string or function(component) that must return string/html
                 */
                text: 'new template',
                /**
                 * @description [handler] - function(component) that's flexible enough to do anything we need. When url is supplied, this will be void. 
                 */
                handler: function (component) {
                  console.log(component)
                  component.$vxSlideIn.open({
                    data: {},
                    config: {
                      component: 'TemplateForm',
                      title: 'New Template'
                    }
                  })
                },
                /**
                 * @description [url] - route to go to, this is priortised before handler property. Can also handle a function with component argumanet or simply url string.
                 */
                url: null
              }]
            },
            pageTitle: 'Templates',
            subTitle: 'Use a list of presets for quicker rendering.'
          }
        },
        {
          path: '/monitoring',
          component: () => import('./views/monitoring/Index.vue'),
          meta: {
            inherit: true,
            pageTitle: 'Monitoring',
            auth: true
          },
          children: [{
            path: '',
            name: 'monitorings',
            component: () => import('./views/monitoring/List.vue'),
            query: {
              datecaptured: undefined
            },
            meta: {
              breadcrumb: [
                {
                  title: 'monitorings',
                  active: true
                }
              ]
            }
          }, {
            path: '/monitoring/domains',
            name: 'monitoring-domains',
            component: () => import('./views/monitoring/Domains.vue'),
            meta: {
              permission: monitors.list,
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'Domains',
                  active: true
                }
              ],
              actions: {
                items: [{
                  icon: 'PlusIcon',
                  text: 'New monitoring',
                  url: '/monitoring/create'
                }]
              },
              pageTitle: 'Domains',
              subTitle: 'Define periodical captures for an URL and get alerted if change occurs on the page.'
            },
          }, {
            path: '/monitoring/tests',
            name: 'monitoring-tests',
            component: () => import('./views/monitoring/TestSuites.vue'),
            meta: {
              permission: monitors.list,
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'Test Suites',
                  active: true
                }
              ],
              actions: {
                items: [{
                  icon: 'PlusIcon',
                  text: 'New Test Suite',
                  handler: function (component) {
                    try {
                      const { $refs } = component;
                      console.log("==component===", component);
                      // $refs.view.openNewTestSuiteModal();
                      // $ctrl.$router.push(`/monitoring/edit/${$ctrl.$route.params.id}`);
                      component.$vxSlideIn.open({
                        data: {},
                        config: {
                          component: 'NewTestSuiteForm',
                          title: 'New Test Suite'
                        }
                      });


                    } catch (e) { return e }
                  },
                }]
              },
              pageTitle: 'Test Suites',
              subTitle: 'Laborum provident id dolorem reprehenderit? Repellendus, magni! Nostrum, id ab.'
            },
          },
          {
            path: '/monitoring/create',
            name: 'monitoring-create',
            component: () => import('./views/monitoring/Create.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'New monitoring',
                  active: true
                }
              ],
              pageTitle: 'New monitoring',
              subTitle: 'Define periodical captures for an URL and get alerted if change occurs on the page.'
            }
          }, {
            path: '/monitoring/:id(\\d+)',
            name: 'monitoring-view',
            component: () => import('./views/monitoring/View.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'View',
                  active: true
                }
              ],
              actions: {
                items: [{
                  icon: 'RefreshCwIcon',
                  text: 'Refresh',
                  attrs: {
                    type: 'border',
                    size: 'small'
                  },
                  handler: function (component, reload) {
                    try {
                      reload();
                    } catch (e) { return e }
                  },
                }, {
                  icon: 'EditIcon',
                  text: 'Edit',
                  attrs: {
                    size: 'small'
                  },
                  // url: '/monitoring/create'
                  handler: function (component) {
                    try {
                      const $ctrl = component;
                      $ctrl.$router.push(`/monitoring/edit/${$ctrl.$route.params.id}`);
                    } catch (e) { return e }
                  },
                }, {
                  icon: 'CameraIcon',
                  text: 'Capture Now',
                  attrs: {
                    color: 'success',
                    size: 'small'
                  },
                  handler: async function (vm) {
                    try {
                      const capture = await vm.$store.dispatch('monitors/capture', vm.$route.params.id);
                      if (capture && capture.id) {
                        vm.$router.push(`/captures/${capture.id}?inprogress=true`);
                        return capture;
                      }
                    } catch (e) { return e }
                  },
                }, {
                  icon: (vm) => {
                    try {
                      const item = vm.$store.state.monitors.item;
                      return item.status === 'active' ? 'PauseIcon' : 'PlayIcon';
                    } catch (error) {
                      return undefined;
                    }
                  },
                  text: (vm) => {
                    try {
                      const item = vm.$store.state.monitors.item;
                      return item.status === 'active' ? 'Pause' : 'Unpause';
                    } catch (error) {
                      return 'Unpause/Pause';
                    }
                  },
                  attrs: {
                    color: 'warning',
                    size: 'small'
                  },
                  // url: '/monitoring/create'
                  handler: async function (vm) {
                    try {
                      const item = vm.$store.state.monitors.item;
                      await vm.$store.dispatch(`monitors/status`, item);
                    } catch (e) { return e }
                  },
                }, {
                  icon: 'TrashIcon',
                  text: 'Delete',
                  attrs: {
                    type: 'border',
                    color: 'danger',
                    size: 'small'
                  },
                  // url: '/monitoring/create'
                  handler: async function (vm) {
                    try {
                      await vm.$store.dispatch('monitors/delete', vm.$route.params.id);
                      vm.$router.push('/monitoring');
                    } catch (e) { return e }
                  },
                }]
              },
              pageTitle: ($ctrl) => {
                try {
                  console.log($ctrl)
                  return $ctrl.monitorTitle || 'View'
                } catch (error) {
                  return 'View'
                }
              },
            }
          }, {
            path: '/monitoring/edit/:id(\\d+)',
            name: 'monitoring-edit',
            component: () => import('./views/monitoring/Edit.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'Edit',
                  active: true
                }
              ],
              actions: {
                items: [{
                  icon: 'XIcon',
                  text: 'Cancel',
                  // url: '/monitoring/create'
                  handler: function (component) {
                    try {
                      const $ctrl = component;
                      $ctrl.$router.push(`/monitoring/${$ctrl.$route.params.id}`);
                    } catch (e) { return e }
                  },
                }]
              },
              pageTitle: ($ctrl) => {
                try {
                  console.log($ctrl)
                  return $ctrl.monitorTitle || 'View'
                } catch (error) {
                  return 'View'
                }
              },
            }
          }, {
            path: '/monitoring/tests/:id(\\d+)',
            name: 'test-view',
            component: () => import('./views/monitoring/TestCase.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'Test Suites',
                  url: '/monitoring/tests'
                },
                {
                  title: 'View',
                  active: true
                }
              ],
              pageTitle: ($ctrl) => {
                try {
                  return $ctrl.testTitle || 'View'
                } catch (error) {
                  return 'View'
                }
              },
            }
          }, {
            path: '/monitoring/create',
            name: 'monitoring-create',
            component: () => import('./views/monitoring/Create.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'New monitoring',
                  active: true
                }
              ],
              pageTitle: 'New monitoring',
              subTitle: 'Define periodical captures for an URL and get alerted if change occurs on the page.'
            }
          }, {
            path: '/monitoring/:id(\\d+)',
            name: 'monitoring-view',
            component: () => import('./views/monitoring/View.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'View',
                  active: true
                }
              ],
              actions: {
                items: [{
                  icon: 'RefreshCwIcon',
                  text: 'Refresh',
                  attrs: {
                    type: 'border',
                    size: 'small'
                  },
                  handler: function (component, reload) {
                    try {
                      reload();
                    } catch (e) { return e }
                  },
                }, {
                  icon: 'EditIcon',
                  text: 'Edit',
                  attrs: {
                    size: 'small'
                  },
                  // url: '/monitoring/create'
                  handler: function (component) {
                    try {
                      const $ctrl = component;
                      $ctrl.$router.push(`/monitoring/edit/${$ctrl.$route.params.id}`);
                    } catch (e) { return e }
                  },
                }, {
                  icon: 'CameraIcon',
                  text: 'Capture Now',
                  attrs: {
                    color: 'success',
                    size: 'small'
                  },
                  handler: async function (vm) {
                    try {
                      const capture = await vm.$store.dispatch('monitors/capture', vm.$route.params.id);
                      if (capture && capture.id) {
                        vm.$router.push(`/captures/${capture.id}?inprogress=true`);
                        return capture;
                      }
                    } catch (e) { return e }
                  },
                }, {
                  icon: (vm) => {
                    try {
                      const item = vm.$store.state.monitors.item;
                      return item.status === 'active' ? 'PauseIcon' : 'PlayIcon';
                    } catch (error) {
                      return undefined;
                    }
                  },
                  text: (vm) => {
                    try {
                      const item = vm.$store.state.monitors.item;
                      return item.status === 'active' ? 'Pause' : 'Unpause';
                    } catch (error) {
                      return 'Unpause/Pause';
                    }
                  },
                  attrs: {
                    color: 'warning',
                    size: 'small'
                  },
                  // url: '/monitoring/create'
                  handler: async function (vm) {
                    try {
                      const item = vm.$store.state.monitors.item;
                      await vm.$store.dispatch(`monitors/status`, item);
                    } catch (e) { return e }
                  },
                }, {
                  icon: 'TrashIcon',
                  text: 'Delete',
                  attrs: {
                    type: 'border',
                    color: 'danger',
                    size: 'small'
                  },
                  // url: '/monitoring/create'
                  handler: async function (vm) {
                    try {
                      await vm.$store.dispatch('monitors/delete', vm.$route.params.id);
                      vm.$router.push('/monitoring');
                    } catch (e) { return e }
                  },
                }]
              },
              pageTitle: ($ctrl) => {
                try {
                  console.log($ctrl)
                  return $ctrl.monitorTitle || 'View'
                } catch (error) {
                  return 'View'
                }
              },
            }
          }, {
            path: '/monitoring/edit/:id(\\d+)',
            name: 'monitoring-edit',
            component: () => import('./views/monitoring/Edit.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'Edit',
                  active: true
                }
              ],
              actions: {
                items: [{
                  icon: 'XIcon',
                  text: 'Cancel',
                  // url: '/monitoring/create'
                  handler: function (component) {
                    try {
                      const $ctrl = component;
                      $ctrl.$router.push(`/monitoring/${$ctrl.$route.params.id}`);
                    } catch (e) { return e }
                  },
                }]
              },
              pageTitle: ($ctrl) => {
                try {
                  console.log($ctrl)
                  return $ctrl.monitorTitle || 'View'
                } catch (error) {
                  return 'View'
                }
              },
            }
          }, {
            path: '/monitoring/tests/:id(\\d+)',
            name: 'test-view',
            component: () => import('./views/monitoring/TestCase.vue'),
            meta: {
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Monitoring',
                  url: '/monitoring'
                },
                {
                  title: 'Test Suites',
                  url: '/monitoring/tests'
                },
                {
                  title: 'View',
                  active: true
                }
              ],
              pageTitle: ($ctrl) => {
                try {
                  return $ctrl.testTitle || 'View'
                } catch (error) {
                  return 'View'
                }
              },
            }
          }
          ]
        }, {
          path: '/summary',
          component: () => import('./views/summary/Index.vue'),
          meta: {
            auth: true
          },
          children: [{
            path: '',
            name: 'summary',
            component: () => import('./views/summary/List.vue'),
            meta: {
              permission: pagestatus.list,
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Page Status',
                  active: true
                }
              ],
              pageTitle: 'Page Status'
            }
          }]
        },
        {
          path: '/management',
          component: () => import('./views/management/Index.vue'),
          meta: {
            auth: true
          },
          children: [{
            path: '',
            redirect: '/management/tenants'
          }, {
            path: '/management/tenants',
            name: 'management-tenants',
            component: () => import('./views/management/Tenants.vue'),
            meta: {
              permission: permissions.management.list.tenants,
              breadcrumb: [
                {
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Management',
                  active: true
                },
                {
                  title: 'Tenants',
                  active: true
                }
              ],
              pageTitle: 'Tenants'
            }
          }]
        },
        {
          path: '/emails',
          component: () => import('./views/emails/Index.vue'),
          meta: {
            permission: permissions.emails.received.list,
            inherit: true,
            auth: true,
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Received Emails',
                active: true
              }
            ],
            pageTitle: 'Emails'
          },
          children: [{
            path: '',
            name: 'emails',
            component: () => import('./views/emails/List.vue'),
            meta: {
            }
          }]
        },
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '',
          component: () => import('@/views/pages/auth/Index.vue'),
          children: [{
            path: '/logout',
            name: 'logout',
            component: () => import('@/views/pages/auth/Logout.vue'),
          }, {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/auth/Login.vue'),
          }, {
            path: '/forgotpassword',
            name: 'forgotpassword',
            component: () => import('@/views/pages/auth/ForgotPassword.vue'),
          }, {
            path: '/signup',
            name: 'signup',
            component: () => import('@/views/pages/auth/Signup.vue'),
          }, {
            path: '/signup/verify',
            name: 'signup-verify',
            component: () => import('@/views/pages/auth/VerifyEmail.vue'),
            meta: {
              auth: true
            }
          }, {
            path: '/signup/complete',
            name: 'signup-complete',
            component: () => import('@/views/pages/auth/Complete.vue'),
            meta: {
              auth: true
            }
          }]
        },
        {
          path: '/callback',
          name: 'callback',
          component: () => import('@/Callback.vue')
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/test-editor',
          name: 'test-editor',
          component: () => import('@/views/pages/TestEditor.vue')
        },
        {
          path: '/unauthorised',
          name: 'unauthorised',
          component: () => import('@/views/pages/Unauthorised.vue')
        },
        {
          path: '/**/not-found',
          name: 'not-found',
          component: () => import('@/views/pages/NotFound.vue'),
          props: true
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
});

router.beforeEach((to, from, next) => {
  // Inherit meta
  const exactTo = to.matched.find(record => record.name === to.name) || to;
  const { parent } = exactTo || {};
  if (parent && parent.meta && parent.meta.inherit) {
    Object.keys(parent.meta).forEach(metaKey => {

      const parentMetaType = protoOf(parent.meta[metaKey])
      const isSameType = parentMetaType === protoOf(exactTo.meta[metaKey]);
      const spreadable = isSameType && (parentMetaType === 'object' || parentMetaType === 'array');
      if (spreadable) {
        to.meta[metaKey] = parentMetaType === 'array'
          ? uniq([
            ...parent.meta[metaKey],
            ...exactTo.meta[metaKey],
          ]) : {
            ...parent.meta[metaKey],
            ...exactTo.meta[metaKey],
          }
      } else {
        to.meta[metaKey] = parent.meta[metaKey]
      }
    })
  }

  store.dispatch('auth/resolve', to).then(async route => next(route));
});

router.afterEach(async (to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }

  const Stickedtooltips = document.querySelectorAll(".vs-tooltip");
  for (const tooltip of Stickedtooltips) { tooltip.remove(); }

  // const Stickedpopups = document.querySelectorAll(".con-vs-popup");
  // for (const tooltip of Stickedpopups) { tooltip.remove(); }
});

export default router;
