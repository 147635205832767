const getters = {
  isLoggedin(state) {
    return Object.keys(state.payload || {}).length > 0;
  },
  tenantSelected(state) {
    return Boolean(state.tenant)
  },
  isVerifiedEmail(state) {
    try {
      return state.payload.idTokenPayload.email_verified;
    } catch (error) {
      return true;
    }
  },
  photUrl(state) {
    try {
      return (state.payload || {}).idTokenPayload.picture
    } catch (error) {
      return false
    }
  },
}

export default getters
