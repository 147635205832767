import Capture from './Capture';
export default class CaptureChange {
  constructor(o) {
    const CaptureChange = (o || {});
    this.date = CaptureChange.date || '';
    this.count = CaptureChange .count || 0;
    this.changes = CaptureChange .changes || 0;
    this.minChangePercent = CaptureChange .minChangePercent || 0;
    this.captures = (CaptureChange.captures || []).map(item => new Capture(item));
  }
}
