<template>
  <vs-tooltip :text="percentage">
    <vs-progress
      :percent="value"
      :color="getPopularityColor(value)"
      class="shadow-md"
      style="width: 80px;"
    />
    <span></span>
  </vs-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  computed: {
    value () {
      try {
        const $attrs = this.$attrs;
        return Number($attrs.data[$attrs.config.field]);
      } catch (error) {
        console.error('Something wrong with attribute {data} or {config}');
        return 0;
      }
    },
    getPopularityColor: () => (num) => {
      if (num > 90)  return 'success'
      if (num > 70)  return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50)  return 'danger'
      return 'primary'
    },
    percentage() {
      return `${this.value}%`
    }
  }
}
</script>