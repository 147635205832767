<template>
  <div class="vx-field vx-select-template" :class="{ 'vx-select-template--empty': !(value || {}).id }">
    <label class="vs-input--label">Template</label>
    <v-select
      :options="templates"
      label="name"
      v-model="model"
      v-on="$listeners"
      :class="{
        'v-select--danger': error
      }"
      :clearable="false"
      :disabled="$attrs.disabled"
    >
      <template v-slot:option="option">
        <div class="flex text-xs py-2">
          <img :src="`/assets/images/svg/viewport_${getViewportKey(option)}.svg`" class="max-w-8" alt="" />
          <div class="pl-3">
            <div class="flex">
              <div class="font-semibold">{{ option.name }}</div>
              <span class="text-grey text-xs ml-1 flex" v-if="(option.viewPorts || []).length > 0">
                (<span class="max-w-32 truncate">
                  <span v-for="(v, i) in option.viewPorts" :key="i">
                    <span v-if="i > 0"> | </span><small>{{ `${v.width}x${v.height}` }}</small>
                  </span> </span
                >)
              </span>
            </div>
            <div>
              <span class="text-xs"
                ><small class="font-mono uppercase">{{ option.fileFormat }}</small></span
              >
              |
              <span class="text-xs"
                ><small class="font-mono uppercase">{{ option.captureEntireHeight ? 'Fullscreen' : 'Windowed' }}</small></span
              >
            </div>
          </div>
        </div>
      </template>
    </v-select>
    <div class="con-text-validation span-text-validation vs-input--text-validation-span">
      <span class="span-text-validation">Template you'd like use when capturing the web page</span>
    </div>
    <span class="block text-danger text-xs mt-1 ml-1" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Template from '@/entity/Template';
import viewport from '@/entity/Viewport';
const Viewport = new viewport();
import { Validator } from 'vee-validate';

Validator.extend('template', {
  getMessage: field => 'The ' + field + ' field is required.',
  validate: value => !!(value || {}).id
});

export default {
  inheritAttrs: false,
  props: {
    value: {
      required: true
    }
  },
  methods: {
    async fetchTemplates() {
      await this.$store.dispatch('templates/list');
    },
    getViewportKey(template) {
      if (template.viewPorts && template.viewPorts.length > 1) return (this.viewport = 'custom');
      try {
        const selectedViewport = template.viewPorts[0];
        return Viewport.getSelectedViewportKey(selectedViewport);
      } catch (error) {
        return 'desktop';
      }
    }
  },
  computed: {
    ...mapState({
      templates: state => {
        return (state.templates.list.items || []).filter(o => !!o.name);
      }
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', new Template(value));
      }
    },
    error() {
      const items = this.errors.items || [];
      for (let i = 0; i < items.length; i++) {
        const error = items[i];
        if (error.field === this.$attrs.name) {
          return error.msg;
        }
      }
      return false;
    }
  },
  async created() {
    const templates = this.templates;
    if (templates && templates.length === 0) {
      await this.fetchTemplates();
    }
  }
};
</script>

<style lang="scss">
.vx-select-template--empty {
  .vs__clear {
    display: none;
  }
}
</style>
