import Template from '@/entity/Template';

export default class Capture {
  constructor(o = {}) {
    const Capture = (o || {});
    this.id = Capture.id || undefined;
    this.key = Capture.key || undefined;
    this.title = Capture.title || undefined;
    this.monitor = Capture.monitor || undefined;
    this.tenant = Capture.tenant || undefined;
    this.detectedChangePercent = Capture.detectedChangePercent || 0;
    this.dateCaptured = Capture.dateCaptured || undefined;
    this.status = Capture.status || undefined;
    this.warcPath = Capture.warcPath || undefined;
    this.harPath = Capture.harPath || undefined;
    this.htmlPath = Capture.htmlPath || undefined;
    this.captureHtml = Capture.captureHtml || false;
    this.captureHar = Capture.captureHar || false;
    this.captureWarc = Capture.captureWarc || false;
    this.captureScreencast = Capture.captureScreencast || undefined;
    this.url = Capture.url || undefined;
    this.screenshots = Capture.screenshots || undefined;
    this.screencasts = Capture.screencasts || undefined;
    this.html = Capture.html || undefined;
    this.entity = Capture.entity || undefined;
    this.script = Capture.script || undefined;
    this.tagItems = Capture.tagItems || undefined;
    this.passed = Capture.passed || undefined;
    this.cookiesEnabled = Capture.cookiesEnabled || false;
    this.cookies = Capture.cookies || undefined;


    this.template = new Template(Capture.template || undefined);
  }
}