/* eslint no-unused-vars: 0 */
import store from '@/store/store';

class PermissionService {
  has (permission) {
    try {
      const userPermissions = (store.state.auth.payload || {}).permissions || [];
      //console.log('userPermissions : ', userPermissions);
      return userPermissions.includes(permission)
    } catch (error) {
      return false;
    }
  }

  check (request) {
    try {
      const userPermissions = (store.state.auth.payload || {}).permissions || [];
      if (request.permission) {
        if (userPermissions.includes(request.permission)) return false;
        return true;
      }
      return false
    } catch (error) {
      return false
    }
  }
}

export default new PermissionService();
