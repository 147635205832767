/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
/* eslint no-unused-vars: 0 */
import api from '@/config/api';
import axios from '@/axios';
import router from '@/router';

import { decode } from '@/plugins/helper';
import apiConfig from '@/config/api';

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  TOGGLE_REDUCE_BUTTON({ commit }, val) {
    commit('TOGGLE_REDUCE_BUTTON', val);
  },

  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN({ commit }, val) {
    commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', val);
  },

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE({ commit }, val) {
    commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', val);
  },

  
  // Vertical NavMenu
  UPDATE_WINDOW_SCROLL_Y({ commit }, val) {
    commit('UPDATE_WINDOW_SCROLL_Y', val);
  },

  UPDATE_WINDOW_WIDTH({ commit }, val) {
    commit('UPDATE_WINDOW_WIDTH', val);
  },

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY');
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload);
  },

  // /////////////////////////////////////////////
  // COMMON
  // /////////////////////////////////////////////

  async downloadFile(context, url) {
    // var xhr = new XMLHttpRequest();
    // xhr.onload = function() {
    //   var reader = new FileReader();
    //   reader.onloadend = function() {
    //     // dowload reader.result
    //   };
    //   reader.readAsDataURL(xhr.response);
    // };
    // xhr.open('GET', url);
    // xhr.responseType = 'blob';
    // xhr.send();
    
    const filename = (url || '').toLowerCase().includes('.pdf') ? 'file.pdf' : 'file.png';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); //or any other extension
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.remove();
  },

  /* eslint no-unsafe-finally: 0 */
  async tags({ commit, dispatch }, { id, data = {} } = {}) {
    let entity;
    try {
      entity = router.currentRoute.meta.entityType 
    } catch (error) {
      throw new Error('meta entityType not found, please specify it in the router');
    } finally {
      const url = `${api.baseUrl}/tags/${entity}/${id}`;
      if (data.id) return await axios.delete(`${url}/${data.id}`).then(async ({ data }) => data); // delete
      return await axios.post(url, data).then(async ({ data }) => data);
    }
  },
  
  saveUrlParams({ dispatch }, url) {
    const path = url.substr(apiConfig.baseUrl.length + 1);
    const key = path.substr(0, path.indexOf('?'));
    const params  = decode(url);
    const storeModule = apiConfig.entity[key];
    if (storeModule) dispatch(`${storeModule}/requestParams`, params, { root: true });
  }
};

export default actions;
