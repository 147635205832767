export default class Paging {
  constructor(o = {}) {
    const me = o || {};
    this.total = me.total || 0;
    this.page = me.page || 1;
    this.size = me.size || 10;
    this.pages = me.pages || 1;

    this.query = Paging.query;
  }

  static query (Paging) {
    return {
      page: Paging.page,
      size: Paging.size
    }
  }
}