import auth0 from 'auth0-js';
import store from '@/store/store';
import config from '@/config/auth0';
import jwtDecode from 'jwt-decode';

const redirectUri = window.location.origin + process.env.BASE_URL + 'callback';
const databaseConnection = 'Username-Password-Authentication';
const webAuth = new auth0.WebAuth({
  domain: config.domain,
  clientID: config.clientID,
  responseType: 'token id_token',
  audience: config.audience,
  scope: 'openid profile email',
  redirectUri
});

const AuthService = class AuthService {
  accessToken = '';

  signup ({ email, password }) {
    return new Promise((resolve, reject) => {
      webAuth.signup({
        connection: databaseConnection,
        email,
        password
      }, function (err, authResult) {
        if (err) return reject(err);
        if (authResult) {
          //me.login({ email, password });
          resolve(authResult)
        }
      });
    })
  }

  login ({ email, password }) {
    return new Promise((resolve, reject) => {
      webAuth.login({
        realm: databaseConnection,
        email,
        password
      }, function (err) {
        if (err) reject(err.original)
      });
    })
  }

  logout () {
    webAuth.logout({
      returnTo: window.location.origin + process.env.BASE_URL + 'login'
    });
  }

  handleAuthentication (state) {
    const me = this;
    return new Promise((resolve, reject) => {
      webAuth.checkSession({
        state: state || localStorage.getItem('state') || ''
      }, async (err, authResult) => {
        console.log({ err, authResult })
        if (authResult && authResult.accessToken && authResult.idToken) {
          me.accessToken = `${authResult.tokenType} ${authResult.accessToken}`;

          const { error } = await store.dispatch('auth/set', {
            ...authResult,
            ...jwtDecode(authResult.accessToken)
          });

          if (error) {
            me.logout();
            return reject(error)
          }
          resolve(authResult);
        } else if (err) {
          reject(err)
          await store.dispatch('auth/set', null);
        }
      });
    });
  }

  changePassword (email) {
    return new Promise((resolve, reject) => {
      webAuth.changePassword({
        connection: databaseConnection,
        email
      }, async (err, message) => {
        if (message) {
          resolve(message);
        } else if (err) {
          reject(err);
        }
      })
    })
  }

  isAuthenticated () {
    // console.log({
    //   class: this,
    //   state: localStorage.getItem('state') || ''
    // })
    const me = this;
    return new Promise(resolve => {
      webAuth.checkSession({
        state: localStorage.getItem('state') || '',
      }, async function (err, authResult) {
        console.log({ err, authResult })
        if (authResult && authResult.accessToken && authResult.idToken) {
          me.accessToken = `${authResult.tokenType} ${authResult.accessToken}`;

          store.dispatch('auth/SET_AUTH', {
            ...authResult,
            ...jwtDecode(authResult.accessToken)
          })
          await store.dispatch('accounts/resolve')
          resolve(authResult);
        } else if (err) {
          resolve(false)
          await store.dispatch('auth/set', null);
        }

        // webAuth.client.userInfo(authResult.accessToken, function(err, user) {
        //   // Now you have the user's information
        // });
      });
    })
  }

  getToken () {
    try {
      if (this.accessToken) return this.accessToken;
      const auth = store.state.auth.payload;
      return `${auth.tokenType} ${auth.accessToken}`;
    } catch (e) {
      return null;
    }
  }
}

export default new AuthService();

