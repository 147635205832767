<!-- =========================================================================================
    File Name: VxBreadcrumb.vue
    Description: Breadcrumb component
    Component Name: VxBreadcrumb
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="vx-breadcrumb" :class="$vnode.data.staticClass">
    <ul class="flex flex-wrap items-center">
      <li class="inline-flex items-end">
        <router-link to="/">
          <feather-icon
            icon="HomeIcon"
            svgClasses="h-5 w-5 mb-1 stroke-current text-primary"
          />
        </router-link>
        <span class="breadcrumb-separator mx-2"
          ><feather-icon
            :icon="isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'"
            svgClasses="w-4 h-4"
        /></span>
      </li>
      <li
        v-for="(link, index) in breadcrumbs"
        :key="index"
        class="inline-flex items-center"
      >
        <router-link
          :to="typeof link.url !== 'function' ? link.url : link.url(route)"
          v-if="link.url"
          >{{
            typeof link.title !== 'function' ? link.title : link.title(route)
          }}</router-link
        >
        <span class="text-primary cursor-default" v-else>{{
          typeof link.title !== 'function' ? link.title : link.title(route)
        }}</span>
        <span v-if="index !== (breadcrumbs.length - 1) || index === breadcrumbs.length - 1 && lastBreadcrumb" class="breadcrumb-separator mx-2 flex items-start"
          ><feather-icon
            :icon="isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'"
            svgClasses="w-4 h-4"
        /></span>
      </li>
      <li class="inline-flex" v-if="lastBreadcrumb">
        <span class="cursor-default">{{ lastBreadcrumb }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'vx-breadcrumb',
  props: ['route', 'isRTL'],
  computed: {
    breadcrumbs() {
      try {
        return this.route.meta.breadcrumb.slice(1, -1);
      } catch (error) {
        return [];
      }
    },
    lastBreadcrumb() {
      try {
				const last = this.route.meta.breadcrumb.slice(-1)[0] || {};
				if (typeof last.title === 'function') {
					return last.title(this.route) || undefined
				}
				return last.title || undefined;
      } catch (error) {
        return undefined;
      }
    }
  }
};
</script>
