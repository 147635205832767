/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

// Vuex Store
import store from './store/store'

import './websockets/websockets'

// Snippets
import Snippets from './plugins/snippets.js';
Vue.use(Snippets);

import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)

// Auth0
import auth0 from './auth0';
Vue.prototype.$auth0 = auth0;

// mock
import './fake-db/index.js'

// axios
import axios from "./axios.js"
Vue.prototype.$http = axios
axios.$store = store;

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'
router.$store = store;


// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// Vue.use(VuePlyr, {
//   plyr: {
//     fullscreen: { enabled: false },
//     settings: ['speed'],
//     controls: ['play-large', 'play', 'progress', 'current-time', 'settings'],
//     speed: {
//       selected: 0.1,
//       options: [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] }
//   },
//   emit: ['ended']
// })

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
