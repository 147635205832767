// "id": 1,
// "width": 1024,
// "height": 300

export default class Viewport {
  constructor (o = {}) {
    const Viewport = (o || {});
    this.id = Viewport.id || undefined;
    this.width = Viewport.width || this.items.desktop.width;
    this.height = Viewport.height || this.items.desktop.height;
  }

  get items() {
    return {
      desktop: new Viewport({
        width: 1440,
        height: 960
      }),
      tablet: new Viewport({
        width: 768,
        height: 1024
      }),
      mobile: new Viewport({
        width: 375,
        height: 667
      })
    }
  }

  getSelectedViewportKey (v) {
    const items = this.items;
    for (const key in items) {
      if (items.hasOwnProperty(key)) {
        const item = items[key];
        if (item.width === v.width && item.height === v.height) return key;
      }
    }
    return 'desktop';
  }
}