import api from '@/config/api';
import axios from '@/axios';
import { encode } from '@/plugins/helper';
import Paging from '@/entity/Paging';

const actions = {
  async resolve ( { dispatch, state } ) {
    await Promise.all([
      ...((!state.me) ? ([dispatch('me')]) : []),
      ...((!state.tenant) ? ([dispatch('tenant')]) : [])
    ])
    return true
  },

  async me ({ commit }, data) {
    const url = `${api.baseUrl}/me`;
    return axios({
      url,
      method: data ? 'post' : 'get',
      ...(data ? { data } : {})
    }).then(({ data }) => {
      commit('SET_ME', data);
      return data;
    });
  },
  
  async timezones ({ commit }) {
    const url = `${api.baseUrl}/timezones`;
    return await axios.get(url)
      .then(({ data }) => {
        commit('SET_TIMEZONES', data);
        return data;
      });
  },

  async tenant ({ commit }, data) {
    const url = `${api.baseUrl}/tenant`;
    return axios({
      url,
      method: data ? 'post' : 'get',
      ...(data ? { data } : {})
    }).then(({ data }) => {
      commit('SET_TENANT', data);
      commit('auth/SET_TENANT', data, { root: true })
      return data;
    });
  },

  async plans ({ commit }, data) {
    const query = { ...(Paging.query({ size: 99999, page: 1 })) };
    const url = `${api.baseUrl}/plans?${encode(query)}`;
    return axios({
      url,
      method: data ? 'post' : 'get',
      ...(data ? { data } : {})
    }).then(({ data }) => {
      commit('SET_PLANS', data);
      return data;
    });
  },
  
  async team ({ commit, dispatch   }, data) {
    const $data = data;
    const url = `${api.baseUrl}/team/members`;
    return axios({
      url,
      method: $data ? 'post' : 'get',
      ...($data ? { data: $data } : {})
    }).then(({ data }) => {
      if (!$data) {
        commit('SET_TEAM', data)
      } else {
        dispatch('team')
      }
      return data;
    });
  },
  
  async deleteMemberById ({ dispatch }, { id } = {}) {
    const url = `${api.baseUrl}/team/members/${id}`;
    return axios({
      url,
      method: 'delete'
    }).then(async ({ data }) => {
      await dispatch('team');
      return data;
    });
  }
}

export default actions
