/* eslint no-unused-vars: 0 */
/* eslint no-unreachable: 0 */
import auth0 from './auth0';
import axios from 'axios';
import store from './store/store';
import Vue from 'vue';
const app = new Vue();
import apiConfig from '@/config/api';
// const baseURL = "";

import permissions from './permissions';

const instance = axios.create({
  // baseURL: baseURL,
  // You can add your headers here
  headers: {
    'Access-Control-Allow-Origin': '*',
  }
});

instance.interceptors.request.use(config => {
  if (!config.disablePreload) app.$vs.loading();
  config.headers['Authorization'] = auth0.getToken();
  const url = new URL(config.url)
  const hasPagingParameters = url.search.includes('page=') && url.search.includes('size=')
  if (hasPagingParameters) store.dispatch('saveUrlParams', config.url);

  return {
    ...config,
    cancelToken: new axios.CancelToken((cancel) => {
      if (permissions.check(config)) {
        return cancel(`You have no permission to make this request (${config.method.toUpperCase()}:${config.url})`);
      }
    })
  }
}, function (error) {
  app.$vs.loading.close();
  console.log('Request ERROR:', error);
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  const clearNotify = () => {
    const notify = document.querySelector('.vs-notifications.activeNoti');
    if (notify) notify.click();
  }
  app.$vs.loading.close();
  const responseData = (response.data || {});
  const message = response.message || responseData.message;
  try {
    const color = response.status === 200
      ? responseData.success === false
        ? 'danger'
        : 'success'
      : 'warning';
    if (message) {
      clearNotify();
      app.$vs.notify({
        title: color.charAt(0).toUpperCase() + color.slice(1),
        text: message,
        color,
        time: 10000
      })
    }
  } catch (e) {
    clearNotify();
    app.$vs.notify({
      title: 'Server message',
      text: e,
      color: 'warning',
      time: 10000
    });
  }

  return response;
}, async function (error) {
  const notify = document.querySelector('.vs-notifications.activeNoti');
  if (notify) notify.click();
  const response = error.response;
  const responseData = ((response || {}).data || {});
  app.$vs.loading.close();
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  app.$vs.notify({
    color: 'danger',
    title: 'Error',
    text: responseData.message || responseData.error || error.message,
    time: 10000
  })
  if (window.location.hostname === 'localhost') {
    // https://jzwtq9qyu4.execute-api.us-east-1.amazonaws.com/dev/tenant
    const path = error.config.url.substr(apiConfig.baseUrl.length).replace('?', '');
    const { method } = error.config;
    const data = await import(`./mock${path + (method === 'post' ? '.post' : '')}.json`).then(m => m.default);
    return { data };
  }
  if (response && response.status === 403) auth0.logout();
  return Promise.reject(error);
});

export default instance;