/* eslint no-unused-vars: 0 */
import api from '@/config/api';
import axios from '@/axios';
import { encode, urlPath } from '@/plugins/helper';
import Paging from '@/entity/Paging';

const actions = {

  async list ({ commit }, { params = {}, paging = {}, dontCommit = false, axiosConfig = {} } = {}) {
    const query = {
      ...params,
      ...(Object.keys(paging).length > 0 ? Paging.query(paging) : {}),
    }
    const url = `${api.baseUrl}/monitors?${encode(query)}`;
    return await axios.get(url, axiosConfig)
    .then(({ data }) => {
      if (!dontCommit) {
        console.log("SET_LIST data ", data);
        commit('SET_LIST', data);
      }
      return data;
    });
  },
  
  async item ({ commit }, { id } = {}) {
    const url = `${api.baseUrl}/monitors/${id}`;
    return await axios.get(url)
      .then(({ data }) => {
        commit('SET_ITEM', data);
        return data;
      });
  },

  async update ({ commit, dispatch }, data = {}) {
    const url = `${api.baseUrl}/monitors/${data.id ? data.id : ''}`;
    return await axios.post(url, (!data.id ? data : {
      name: data.name,
      schedule: data.schedule,
      captureHar: data.captureHar,
      captureHtml: data.captureHtml,
      captureWarc: data.captureWarc,
      timeZone: data.timeZone,
      script: data.script,
      captureScreencast: data.captureScreencast
    }))
    .then(async ({ data }) => {
      return data;
    });
  },
  
  async alert({ commit }, { monitor, alert } = {}) {
    if (!monitor || !alert) throw('Please check monitoring { monitor } or email { alert }');
    const url = `${api.baseUrl}/monitors/${monitor.id}`;
    
    const item = JSON.parse(JSON.stringify(monitor));
    const rules = item.alertRules;
    let alertIndex = monitor.alertRules.indexOf(alert);
    
    const updateAlert = (index, activate) => rules.splice(index, 1, {
      ...alert,
      contacts: alert.contacts && alert.contacts.length > 0 ? alert.contacts : undefined,
      enabled: activate ? true : !alert.enabled
    })
    
    if (alertIndex > -1) updateAlert(alertIndex);
    if (alertIndex === -1 && alert.id) {
      const alertItem = rules.find(item => item.id === alert.id);
      if (alertItem) {
        alertIndex = rules.indexOf(alertItem);
        updateAlert(alertIndex, true);
      }
    }
    
    return await axios.post(url, {
      alertRules: alertIndex > -1
        ? rules
        : [
          ...monitor.alertRules, {
            ...alert,
            enabled: true
          }
        ]
    })
    .then(async ({ data }) => {
      commit('SET_ITEM', data);
      return data;
    });
  },
  
  async deleteAlert ({ commit, state }, id = null) {
    if (!id) return null;
    const { item } = state;
    const { alertRules } = item;
    
    const url = `${api.baseUrl}/monitors/${item.id}`;
    const rulesCopy = alertRules.slice();
    const alertItem = rulesCopy.find(item => item.id === id);
    rulesCopy.splice(rulesCopy.indexOf(alertItem), 1);
    return await axios.post(url, {
      alertRules: rulesCopy
    })
    .then(async ({ data }) => {
      commit('SET_ITEM', data);
      return data;
    });
  },
  
  async delete({ commit }, id = null) {
    if (!id) return null;
    const url = `${api.baseUrl}/monitors/${id}`;
    return await axios.delete(url)
      .then(async ({ data }) => {
        console.log(data)
        return data;
      });
  },
  
  async status({ commit }, item) {
    if (!item) return null;
    const action = item.status === 'active' ? 'pause' : 'activate';
    const url = `${api.baseUrl}/monitors/${item.id}/${action}`;
    return await axios.post(url)
      .then(async ({ data }) => {
        if (data.success) commit(`STATUS_${action.toUpperCase()}`);
        return data;
      });
  },
  
  async capture({ commit, dispatch }, id = null) {
    if (!id) return null;
    const url = `${api.baseUrl}/monitors/${id}/capture`;
    return await axios.post(url)
      .then(async ({ data }) => {
        return data.captures[0]
      });
  },

  async tests ({ commit }, { params = {}, paging = {}, dontCommit = false } = {}) {
    const query = {
      ...params,
      ...(Object.keys(paging).length > 0 ? Paging.query(paging) : {}),
    }
    const url = `${api.baseUrl}/test-suites?${encode(query)}`;
    return await axios.get(url)
    .then(({ data }) => {
      if (!dontCommit) commit('SET_TESTS', data);
      return data;
    });
  },

  async testItem ({ commit }, { id } = {}) {
    const url = `${api.baseUrl}/test-suites/${id}`;
    return await axios.get(url)
      .then(({ data }) => {
        commit('SET_TEST_ITEM', data);
        return data;
      });
  },

  async updateTestItem ({ commit, dispatch }, { data = {} } = {}) {
    const url = `${api.baseUrl}/test-suites/${data.id || ''}`;
    return await axios.post(url, data, {
      disablePreload: true
    })
    .then(async ({ data }) => {
      console.log('SET_TEST_ITEM ',  data);
      commit('SET_TEST_ITEM', data);
      return data;
    });
  },
  
  requestParams({ commit }, params) {
    commit('REQUEST_PARAMS', params);
  }
}

export default actions
