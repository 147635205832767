var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-template-info"},[_c('vx-field',{staticClass:"w-full",attrs:{"type":"input","label":"Title","description-text":"The title of the website will be used for this capture if you don't enter one"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('vx-field',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full mt-5",attrs:{"type":"input","label":"URL","description-text":"Full address of the web page you'd like to capture."},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}}),_c('VxSelectTemplate',{directives:[{name:"validate",rawName:"v-validate",value:('template'),expression:"'template'"}],staticClass:"mt-5",attrs:{"name":"template"},model:{value:(_vm.form.template),callback:function ($$v) {_vm.$set(_vm.form, "template", $$v)},expression:"form.template"}}),_c('vs-checkbox',{staticClass:"mt-5",model:{value:(_vm.advance),callback:function ($$v) {_vm.advance=$$v},expression:"advance"}},[_vm._v("Advance options")]),(_vm.advance)?_c('div',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-1/2"},[_c('vx-field',{staticClass:"mt-5 w-full",attrs:{"type":"switch","size":"large","label":"Capture HTML","config":{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }},model:{value:(_vm.form.captureHtml),callback:function ($$v) {_vm.$set(_vm.form, "captureHtml", $$v)},expression:"form.captureHtml"}})],1),_c('div',{staticClass:"vx-col w-1/2"},[_c('vx-field',{staticClass:"mt-5 w-full",attrs:{"type":"switch","size":"large","label":"Capture HAR","config":{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }},model:{value:(_vm.form.captureHar),callback:function ($$v) {_vm.$set(_vm.form, "captureHar", $$v)},expression:"form.captureHar"}})],1),_c('div',{staticClass:"vx-col w-1/2"},[_c('vx-field',{staticClass:"mt-5 w-full",attrs:{"type":"switch","size":"large","label":"Capture Web Archive","config":{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }},model:{value:(_vm.form.captureWarc),callback:function ($$v) {_vm.$set(_vm.form, "captureWarc", $$v)},expression:"form.captureWarc"}})],1),_c('div',{staticClass:"vx-col w-1/2"},[_c('vx-field',{staticClass:"mt-5 w-full",attrs:{"type":"switch","size":"large","label":"Set Cookies","config":{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }},model:{value:(_vm.form.cookiesEnabled),callback:function ($$v) {_vm.$set(_vm.form, "cookiesEnabled", $$v)},expression:"form.cookiesEnabled"}})],1),(_vm.form.cookiesEnabled)?_c('div',{staticClass:"vx-col w-full"},[_c('vx-field',{staticClass:"mt-5 mb-1 w-full font-mono",attrs:{"type":"textarea","label":"Request Cookies (Enter in cookies.txt format)"},model:{value:(_vm.form.cookies),callback:function ($$v) {_vm.$set(_vm.form, "cookies", $$v)},expression:"form.cookies"}}),_vm._m(0),_c('pre',{staticClass:"text-sm"},[_vm._v("# Domain     Tailmatch  Path  Secure Expires Name  Value\nwww.example.com  TRUE        /    FALSE  0       id    1234\n          ")])],1):_vm._e()])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"con-text-validation span-text-validation vs-input--text-validation-span"},[_c('span',{staticClass:"span-text-validation"},[_vm._v("The example "),_c('pre',{staticClass:"inline"},[_vm._v("cookies.txt")]),_vm._v(" file defines a cookie for the domain "),_c('pre',{staticClass:"inline"},[_vm._v("www.example.com")]),_vm._v(" with no expiry date and a "),_c('pre',{staticClass:"inline"},[_vm._v("name=value")]),_vm._v(" setting of "),_c('pre',{staticClass:"inline"},[_vm._v("id=1234")])])])}]

export { render, staticRenderFns }