<template>
  <div class="form-template-info">
    <!-- Name -->
    <vx-field type="input" class="w-full" label="Name" v-model="form.name" v-validate="'required'" />

    <div class="vx-row -mx-2">
      <div class="vx-col px-2 w-1/2">
        <!-- Full Page -->
        <vx-field
          type="switch"
          size="large"
          class="mt-5 w-full"
          label="Fullpage"
          v-model="form.captureEntireHeight"
          v-validate="'required'"
          :config="{
            on: {
              icon: 'icon-check-circle',
              text: 'Yes'
            },
            off: {
              icon: 'icon-slash',
              text: 'No'
            }
          }"
        />
      </div>
      <div class="vx-col px-2 w-1/2">
        <vx-field
          type="switch"
          size="large"
          class="mt-5 w-full"
          label="File format"
          v-model="form.fileFormat"
          v-validate="'required'"
          :config="{
            on: {
              text: 'PNG',
              value: 'png'
            },
            off: {
              text: 'JPG',
              value: 'jpg'
            }
          }"
        />
      </div>
    </div>

    <div class="mt-5">
      <label class="vs-input--label">Viewport:</label>
      <p class="text-xs text-grey pl-1 mb-1"><small>Choose a device preset or a custom size.</small></p>
    </div>
    <div class="flex vx-radios">
      <div class="flex-1 vx-radio text-xs text-center" :class="{ 'vx-radio--active': viewport === 'desktop' }" @click="changeViewport('desktop')">
        <img src="@/assets/images/svg/viewport_desktop.svg" class="max-w-12" alt="" />
        <p class="font-semibold">Desktop</p>
        <p><small>1440x960</small></p>
      </div>
      <div class="flex-1 vx-radio text-xs text-center" :class="{ 'vx-radio--active': viewport === 'tablet' }" @click="changeViewport('tablet')">
        <img src="@/assets/images/svg/viewport_tablet.svg" class="max-w-12" alt="" />
        <p class="font-semibold">Tablet</p>
        <p><small>768x1024</small></p>
      </div>
      <div class="flex-1 vx-radio text-xs text-center" :class="{ 'vx-radio--active': viewport === 'mobile' }" @click="changeViewport('mobile')">
        <img src="@/assets/images/svg/viewport_mobile.svg" class="max-w-12" alt="" />
        <p class="font-semibold">Mobile</p>
        <p><small>375x667</small></p>
      </div>
      <div class="flex-1 vx-radio text-xs text-center" :class="{ 'vx-radio--active': viewport === 'custom' }" @click="changeViewport('custom')">
        <img src="@/assets/images/svg/viewport_custom.svg" class="max-w-12" alt="" />
        <p class="font-semibold">Custom</p>
      </div>
    </div>

    <!-- Viewport Size -->
    <div v-show="viewport === 'custom'">
      <div class="vx-row -mx-2 mt-5">
        <div class="vx-col flex-1 px-2">
          <label class="vs-input--label">Width</label>
        </div>
        <div class="vx-col flex-1 px-2">
          <label class="vs-input--label">Height</label>
        </div>
        <div class="vx-col w-8 px-2"></div>
      </div>
      <div class="vx-row -mx-2 mb-3" v-for="(v, i) in form.viewPorts" :key="i">
        <div class="vx-col flex-1 px-2">
          <vx-field type="input" class="w-full" as="Viewport width" v-model="v.width" v-validate="'required|numeric'" />
        </div>
        <div class="vx-col flex-1 px-2">
          <vx-field type="input" class="w-full" as="Viewport height" v-model="v.height" v-validate="'required|numeric'" />
        </div>
        <div class="vx-col w-14 px-2">
          <vs-button
            @click="i === form.viewPorts.length - 1 ? addViewport() : deleteViewport(i)"
            icon-pack="feather"
            :icon="i === form.viewPorts.length - 1 ? 'icon-plus' : 'icon-trash-2'"
            :type="i === form.viewPorts.length - 1 ? 'filled' : 'flat'"
            :color="i === form.viewPorts.length - 1 ? 'success' : 'danger'"
          ></vs-button>
        </div>
      </div>
    </div>

    <vs-checkbox class="mt-5" v-model="advance">Advance options</vs-checkbox>
    <div v-if="advance">
      <vx-field type="input" class="mt-5 w-full" label="User agent" v-model="form.userAgent" />

      <vx-field
        type="input"
        class="mt-3 w-full"
        label="Delay"
        v-model="form.delayMilliSeconds"
        v-validate="{
          numeric: true
        }"
      />

      <div class="vx-row" v-if="false"
        ><!-- Hide for now, might be needed in the future -->
        <div class="vx-col w-1/2">
          <vx-field
            type="switch"
            size="large"
            class="mt-5 w-full"
            label="Capture HTML"
            v-model="form.captureHtml"
            :config="{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }"
          />
        </div>
        <div class="vx-col w-1/2">
          <vx-field
            type="switch"
            size="large"
            class="mt-5 w-full"
            label="Capture HAR"
            v-model="form.captureHar"
            :config="{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }"
          />
        </div>
        <div class="vx-col w-1/2">
          <vx-field
            type="switch"
            size="large"
            class="mt-5 w-full"
            label="Capture Web Archive"
            v-model="form.captureWarc"
            :config="{
              on: {
                icon: 'icon-check-circle',
                text: 'Yes'
              },
              off: {
                icon: 'icon-slash',
                text: 'No'
              }
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Template from '@/entity/Template';
import Viewport from '@/entity/Viewport';
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      viewportService: new Viewport(),
      viewport: 'desktop',
      form: new Template(this.data),
      advance: false
    };
  },
  methods: {
    async submit() {
      // console.log(this.form)
      const result = await this.$store.dispatch('templates/update', { data: this.form });
      return result && result.id ? result : false;
    },
    changeViewport(value) {
      this.viewport = value;
      if (this.viewportService.items[value]) this.form.viewPorts = [this.viewportService.items[value]];
    },
    addViewport() {
      this.form.viewPorts.push(new Viewport());
    },
    deleteViewport(i) {
      this.form.viewPorts.splice(i, 1);
    }
  },
  computed: {},
  created() {
    this.form = new Template(this.data);
    if (this.form.viewPorts && this.form.viewPorts.length > 1) return (this.viewport = 'custom');
    try {
      const selectedViewport = this.form.viewPorts[0];
      this.viewport = this.viewportService.getSelectedViewportKey(selectedViewport);
    } catch (error) {
      this.viewport = 'desktop';
    }
  },
  mounted() {}
};
</script>
