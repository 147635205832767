const mutations = {
  SET_LIST (state, payload) {
    state.list = payload;
  },
  SET_ITEM (state, payload) {
    state.item = payload;
  },
  REQUEST_PARAMS (state, payload) {
    state.requestParams = payload;
  },
}

export default mutations

