<template>
  <div class="vx-editable" :class="{ 'vx-editable--active': active, 'vx-editable--inline': inline }">
    <div class="vx-editable__value vs-inputx vs-input--input">
      <span @click="openEditable" class="truncate">{{ labelAs || placeholder }}</span>
      <feather-icon icon="Edit3Icon" svgClasses="h-4 w-4 stroke-current" class="ml-2" @click.stop="openEditable" />
    </div>
    <div class="vx-editable__field">
      <vs-input v-if="!type || type === 'text'" ref="vxInput" v-model="model" class="w-full" @keydown="saveOnEnter" /> 
      <VxSelectMonitor v-if="type === 'monitor'" ref="vxSelectMonitor" v-model="model" />
      <div class="vx-editable__controls flex">
        <vs-button
          color="success"
          type="filled"
          icon="icon-check"
          icon-pack="feather"
          size="small"
          @click="applyChanges"
        ></vs-button>
        <vs-button
          color="#aaa"
          type="filled"
          icon="icon-x"
          icon-pack="feather"
          size="small"
          class="ml-1"
          @click="cancelChanges"
        ></vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import VxSelectMonitor from '@/components/VxSelectMonitor';
export default {
  components: { VxSelectMonitor },
  props: {
    value: {
      type: [Object, String]
    },
    inline: {
      required: false,
      default: false
    },
    label: {
      type: String
    },
    type: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'N/a'
    }
  },
  data() {
    return {
      active: false,
      model: this.value
    };
  },
  computed: {
    labelAs () {
      const { value, label } = this
      if (typeof value === 'string') return value;
      if (!label) return `{ label } attribute not found`;
      return (value || {})[label] || 'N/a';
    }
  },
  methods: {
    applyChanges() {
      this.$emit('input', this.model);
      this.active = false;
    },
    cancelChanges() {
      this.active = false;
    },
    openEditable() {
      this.$eventBus.$emit('OPENING_EDITABLE');
      this.active = true;
    },
    saveOnEnter(e) {
      if (e.which === 13) this.applyChanges()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$eventBus.$on('OPENING_EDITABLE', () => {
        if (this.active) this.applyChanges()
      });
    })
  },
  watch: {
    active(active) {
      if (active) {
        this.model = this.value;
        this.$nextTick(() => {
          window.$refs = this.$refs;
          console.log(this.$refs)
          const { vxInput, vSelect } = this.$refs;
          if (vxInput) vxInput.$refs.vsinput.focus();
          if (vSelect) {
            setTimeout(() => {
              vSelect.searchEl.focus();
            }, 250)
          }
        })
      }
    }
  }
};
</script>
