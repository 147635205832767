<template>
  <div class="form-template-info">
    <!-- Name -->
    <vx-field
      type="input"
      class="w-full"
      label="Title"
      v-model="form.name"
      description-text="The reference name of the test suite"
      v-validate="'required'"
    />

    <!-- Monitor -->
    <VxSelectMonitor
      label="Select test cases"
      class="mt-5"
      v-model="form.items"
      name="test"
      :multiple="true"
      description-text="Begin typing to find test cases you want to run"
    />
  </div>
</template>

<script>
import VxSelectMonitor from '@/components/VxSelectMonitor';
import TestSuite from '@/entity/TestSuite';
export default {
  components: { VxSelectMonitor },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: this.data || {
        name: '',
        items: []
      }
    };
  },
  computed: {},
  methods: {
    async submit() {
      const { form, confirm, proceed } = this;
      if (form.items.length === 0) return confirm();
      return proceed();
    },
    confirm() {
      const { proceed } = this;
      return new Promise(resolve => {
        this.$vs.dialog({
          type: 'confirm',
          title: 'Confirm',
          text: 'Are you sure you want to proceed creating the test suite without test cases?',
          acceptText: 'Yes, Continue!',
          accept: async () => resolve(await proceed()),
          cancel: () => resolve(false)
        });
      });
    },
    async proceed() {
      const { form, $store } = this;
      const result = await $store.dispatch('monitors/updateTestItem', {
        data:
          form.items.length === 0
            ? form
            : {
                name: form.name,
                items: [
                  {
                    name: `${form.items.length > 1 ? 'Tests' : 'Test'} for ${form.name}`,
                    items: form.items.map(item => ({
                      // name: `Test #${i + 1}`,
                      test: item
                    }))
                  }
                ]
              }
      });
      if (result && result.id) {
        this.$router.push(`/monitoring/tests/${result.id}`);
        return result;
      }
      return false;
    }
  },
  watch: {
    data(data) {
      this.form = new TestSuite(data);
    }
  }
};
</script>
